import React from 'react';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle } from '@fortawesome/pro-light-svg-icons';
import { faUnlockAlt } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox, Modal } from '@eltoro-ui/components';

import cross from 'assets/Images/cross.png';

import { OrderlineActionsEnum } from 'enums';

import './OrderlineActionModal.scss';

const actionsContent = {
  [OrderlineActionsEnum.PAUSE]: {
    icon: <FontAwesomeIcon icon={faPauseCircle} style={{ fontSize: 24, color: '#FF2D38' }} />,
    title: 'Pause Orderline?',
    description: 'Pausing will stop your ad from running until you reactivate it.',
    confirmText: OrderlineActionsEnum.PAUSE,
    confirmBtnType: 'danger',
  },
  [OrderlineActionsEnum.RESUME]: {
    icon: <FontAwesomeIcon icon={faUnlockAlt} style={{ fontSize: 24, color: '#FFAB03' }} />,
    title: 'Resume Orderline?',
    description: 'Your orderline will be activated and start running shortly.',
    confirmText: OrderlineActionsEnum.RESUME,
    confirmBtnType: 'primary',
  },
} as const;

interface OrderlineActionModalProps {
  action: OrderlineActionsEnum.PAUSE | OrderlineActionsEnum.RESUME;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  loading: boolean;
}

export default function OrderlineActionModal({
  action,
  onCancel,
  onConfirm,
  loading,
}: OrderlineActionModalProps) {
  const content = actionsContent[action];

  return (
    <Modal
      closable={false}
      className={classNames('orderline-action-modal', `orderline-${action.toLowerCase()}-modal`)}
    >
      <img
        src={cross}
        onClick={onCancel}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <FlexBox flexDirection="column" alignItems="center" gap="2rem">
        <FlexBox flexDirection="column" alignItems="center" gap="1rem">
          <FlexBox
            justifyContent="center"
            alignItems="center"
            UNSAFE_className={classNames('action-icon-wrapper', content.confirmBtnType)}
          >
            {content.icon}
          </FlexBox>
          <FlexBox flexDirection="column" alignItems="center" gap="0.5rem">
            <span className="orderline-action-title">{content.title}</span>
            <span className="orderline-action-description">{content.description}</span>
          </FlexBox>
        </FlexBox>
        <FlexBox alignItems="center" justifyContent="center" gap="1rem">
          <Button size="l" weight="bold" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            kind={content.confirmBtnType}
            size="l"
            weight="bold"
            onClick={onConfirm}
            loading={loading}
          >
            {content.confirmText}
          </Button>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
}

import React from 'react';

import CustomIcons from 'assets/icons';

export enum EPerformanceStat {
  IMPRESSIONS = 'Impressions',
  CLICKS = 'Clicks',
  CTR = 'CTR',
}

export const performanceStatIcon = {
  [EPerformanceStat.IMPRESSIONS]: <CustomIcons name="impressions_stat" fontSize={56} />,
  [EPerformanceStat.CLICKS]: <CustomIcons name="clicks_stat" fontSize={56} />,
  [EPerformanceStat.CTR]: <CustomIcons name="ctr_stat" fontSize={56} />,
};

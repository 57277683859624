import React, { useCallback, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import toast from 'react-hot-toast';
import { pdf } from '@react-pdf/renderer';
import moment from 'moment';

import { FlexBox } from '@eltoro-ui/components';
import { Loading } from 'Components';

import OrderlinePDF from 'Pages/OrderlineDetails/components/OrderlinePDF';
import OrderlineHeader from 'Pages/OrderlineDetails/components/OrderlineHeader';
import PerformanceMetrics from 'Pages/OrderlineDetails/components/PerformanceMetrics';
import OrderlineCreatives from 'Pages/OrderlineDetails/components/OrderlineCreatives';
import AttachedAudiences from 'Pages/OrderlineDetails/components/AttachedAudiences';

import { getOrderlineDetails } from 'Requests/Request_Methods/orderlineMethods';

import { OrderlineStatusEnum } from 'enums';
import type { TOrderlineDetails } from 'types';
import { formatFromUTC } from 'Utils/helpers';

import './OrderlineDetails.scss';

const metricsComponentAboveOrderStatuses = [
  OrderlineStatusEnum.IN_REVIEW,
  OrderlineStatusEnum.REJECTED,
  OrderlineStatusEnum.APPROVED,
];

export interface OrderlineDetailsParams {
  orderlineId: string;
}

export default function OrderlineDetails({
  match: {
    params: { orderlineId },
  },
}: RouteComponentProps<OrderlineDetailsParams>) {
  const [orderline, setOrderline] = useState<TOrderlineDetails | null>(null);
  const [orderlineDetailsLoading, setOrderlineDetailsLoading] = useState(false);

  const getOrderline = async () => {
    try {
      setOrderlineDetailsLoading(true);
      const { data } = await getOrderlineDetails(orderlineId);
      if (data) setOrderline(data);
    } catch (e) {
      if (e.detail) toast.error(e.detail);
    } finally {
      setOrderlineDetailsLoading(false);
    }
  };

  useEffect(() => {
    getOrderline();
  }, [orderlineId]);

  const onSaveEditCreatives = () => {
    getOrderline();
  };

  const generatePDF = useCallback(async () => {
    if (orderline) {
      const blob = await pdf(<OrderlinePDF orderline={orderline} />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${orderline.name}.pdf`;
      link.click();
    }
  }, [orderline]);

  if (orderlineDetailsLoading || !orderline)
    return (
      <div style={{ height: 'calc(100vh - 132px)', display: 'flex' }}>
        <Loading />
      </div>
    );

  const updateOrderlineData = () => {
    getOrderline();
  };
  return (
    <div id="orderline-details">
      <FlexBox flexDirection="column" gap="32px" UNSAFE_className="orderline-details">
        <OrderlineHeader
          name={orderline.name}
          status={orderline.status}
          start_date={moment(formatFromUTC(orderline.start_date)).format('MM/DD/YYYY hh:mm:ss')}
          end_date={moment(formatFromUTC(orderline.end_date)).format('MM/DD/YYYY hh:mm:ss')}
          total_days={orderline.total_days}
          setOrderline={setOrderline}
          getOrderline={getOrderline}
          generatePDF={generatePDF}
          total_impressions={orderline.total_impressions}
          served_impressions={orderline.served_impressions}
          served_days={orderline.served_days}
          type={orderline.type}
          targets={orderline.targets}
          updateOrderlineData={updateOrderlineData}
          is_edited={orderline.is_edited}
          orderlineId={orderlineId}
        />
        {!metricsComponentAboveOrderStatuses.includes(orderline.status) && (
          <PerformanceMetrics
            ctr={orderline.ctr}
            clicks={orderline.clicks}
            total_impressions={orderline.total_impressions}
            served_impressions={orderline.served_impressions}
            budget={orderline.budget}
            budget_spent={orderline.budget_spent}
            status={orderline.status}
          />
        )}
        <OrderlineCreatives
          rejection_note={orderline.rejection_note}
          creatives={orderline.creatives}
          clickThroughUrl={orderline.clickThroughUrl}
          type={orderline.type}
          orderlineId={orderlineId}
          onSaveEditCreatives={onSaveEditCreatives}
          status={orderline.status}
        />
        <AttachedAudiences
          targets={orderline.targets}
          status={orderline.status}
          orderlineId={orderlineId}
          type={orderline.type}
          total_impressions={orderline.total_impressions}
          total_days={orderline.total_days}
          served_days={orderline.served_days}
          budget={orderline.budget}
          served_impressions={orderline.served_impressions}
          updateOrderlineData={updateOrderlineData}
        />
        {metricsComponentAboveOrderStatuses.includes(orderline.status) && (
          <PerformanceMetrics
            ctr={orderline.ctr}
            clicks={orderline.clicks}
            total_impressions={orderline.total_impressions}
            served_impressions={orderline.served_impressions}
            budget={orderline.budget}
            budget_spent={orderline.budget_spent}
            status={orderline.status}
          />
        )}
      </FlexBox>
    </div>
  );
}

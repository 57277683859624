import React from 'react';
import type { FC } from 'react';

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import moment from 'moment';

import OrderlineHeader from 'Pages/OrderlineDetails/components/OrderlinePDF/components/OrderlineHeader';
import PerformanceMetrics from 'Pages/OrderlineDetails/components/OrderlinePDF/components/PerformanceMetrics';
import Creatives from 'Pages/OrderlineDetails/components/OrderlinePDF/components/Creatives';
import AttachedAudiences from 'Pages/OrderlineDetails/components/OrderlinePDF/components/AttachedAudiences';

import type { TOrderlineDetails } from 'types';

const styles = StyleSheet.create({
  page: {
    fontSize: '14px',
    backgroundColor: '#F8F8F8',
    padding: '17px 20px',
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  downloaded_at: {
    marginBottom: '10px',
  },
  downloaded_at_text: {
    fontSize: '12px',
    color: '#989898',
  },
});

type OrderlinePDFProps = { orderline: TOrderlineDetails };

const OrderlinePDF: FC<OrderlinePDFProps> = ({ orderline }) => {
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.header}>
          <View style={styles.downloaded_at}>
            <Text style={styles.downloaded_at_text}>
              PDF downloaded at {moment().format('MM/DD/YYYY, hh:mm A')}
            </Text>
          </View>
        </View>
        <OrderlineHeader
          name={orderline.name}
          start_date={orderline.start_date}
          end_date={orderline.end_date}
          status={orderline.status}
        />
        <PerformanceMetrics
          ctr={orderline.ctr}
          clicks={orderline.clicks}
          total_impressions={orderline.total_impressions}
          served_impressions={orderline.served_impressions}
          budget={orderline.budget}
          budget_spent={orderline.budget_spent}
        />
        <Creatives creatives={orderline.creatives} />
        <AttachedAudiences targets={orderline.targets} />
      </Page>
    </Document>
  );
};
export default OrderlinePDF;

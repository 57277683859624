import React, { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';

import Cropper from 'react-easy-crop';
import type { CropperProps } from 'react-easy-crop/Cropper';

import { Slider } from 'primereact/slider';
import type { SliderChangeEvent } from 'primereact/slider';

import { Button, FlexBox, Modal } from '@eltoro-ui/components';

import { useCropImageModalContext } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/CreativesGeneratorUploader/contexts/CropImageModalContext';

import { getCroppedImg } from 'Helpers';

import './CropImageModal.scss';

interface CropImageModalProps {
  file: File;
  onCropFile: (file: File) => void;
}

export default function CropImageModal({ file, onCropFile }: CropImageModalProps) {
  const {
    crop: [crop, setCrop],
    zoom: [zoom, setZoom],
    croppedAreaPixels: [croppedAreaPixels, setCroppedAreaPixels],
  } = useCropImageModalContext();

  const [loading, setLoading] = useState(false);

  const imageUrl = useMemo(() => URL.createObjectURL(file), [file]);

  const onCropComplete: CropperProps['onCropComplete'] = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const croppedBlob: any = await getCroppedImg(imageUrl, croppedAreaPixels);

      const cropped_file = new File([croppedBlob], `${file.name}-cropped`, {
        type: file.type,
        lastModified: new Date().getTime(),
      });
      onCropFile(cropped_file);
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(
    () => () => {
      setLoading(false);
    },
    []
  );

  return (
    <Modal className="crop-image-modal">
      <FlexBox flexDirection="column" alignItems="center" gap="2.5rem">
        <FlexBox flexDirection="column" alignItems="center" gap="0.75rem">
          <span className="crop-image-modal-title">Highlight Key Details</span>
          <span className="crop-image-modal-description">
            Some banners may be slightly cropped to highlight key details. Ensure the most important
            part of your listing is within the visible area.
          </span>
        </FlexBox>
        <FlexBox flexDirection="column" gap="2rem">
          <div className="image-cropper-wrapper">
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              minZoom={1}
              maxZoom={3}
              aspect={320 / 90}
              objectFit="contain"
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              classes={{ containerClassName: 'image-cropper-container' }}
            />
          </div>
          <FlexBox flexDirection="column" alignItems="center" gap="2rem">
            <Slider
              className="zoom-slider"
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e: SliderChangeEvent) => setZoom(e.value as number)}
            />
            <Button kind="primary" size="l" weight="bold" onClick={onSave} loading={loading}>
              Save
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
}

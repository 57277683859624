import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { toast } from 'react-hot-toast';

import { Button, PageNav, PageRoutes } from '@eltoro-ui/components';
import { Loading, Layout } from 'Components';
import { ResetPassword } from 'Pages/ResetPassword';
import {
  TabWrapper,
  BillingSection,
  SubscriptionSection,
  PersonalInfoSection,
  Transaction,
  Feedback,
} from 'Pages/MyProfile/components';
import NotificationSetting from 'Pages/MyProfile/components/NotificationSetting/NotificationSetting';
// import CancelSubscription from 'Pages/MyProfile/components/CancelSubscription';
import TermsPolicies from 'Pages/MyProfile/components/TermsPolicies';
import Integrations from 'Pages/MyProfile/components/Integrations';

import type { TRootState } from 'types';

import { createChargebeeUser, getMlS } from 'Requests';

import './MyProfilePage.scss';
import DeactivateAccount from './components/DeactivateAccount';
import DeactivateInfo from './components/DeactivateAccount/components/DeactivateInfo';

export const MyProfilePage: FC = () => {
  const { flagsReady } = useFlagsStatus();
  const canvaFlag = useFlag('canva-integration');

  const [profilePages, setprofilePages] = useState<any>([]);
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  function setupUserProfile() {
    if (userData && userData?.is_compelete !== 1) {
      const schema = {
        id: userData?.id,
        mls_registered_name: userData?.mls_registered_name,
        email: userData?.email,
      };
      createChargebeeUser(schema).catch(err => {
        toast.error(err.detail);
      });
    }
  }

  const canvaIntegrationItem = useMemo(() => {
    if (!flagsReady) return [];
    if (canvaFlag)
      return [
        {
          path: '/profile/integrations',
          title: 'Integrations',
          exactPath: false,
          component: () => (
            <TabWrapper>
              <Integrations />
            </TabWrapper>
          ),
        },
      ];
    return [];
  }, [flagsReady, canvaFlag]);

  useEffect(() => {
    setprofilePages([
      {
        path: '/profile',
        title: 'General Info',
        exactPath: true,
        component: () => (
          <TabWrapper>
            {!userData ? <Loading /> : <PersonalInfoSection user={userData} />}
          </TabWrapper>
        ),
      },
      {
        path: '/profile/billing',
        title: 'Billing Info',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <SubscriptionSection />
            <Transaction />
            <BillingSection />
            {/* <CancelSubscription user={userData} /> */}
          </TabWrapper>
        ),
      },

      {
        path: '/profile/notifications',
        title: 'Feedback',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <Feedback />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/notification-settings',
        title: 'Notification Settings',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <NotificationSetting />
          </TabWrapper>
        ),
      },
      {
        path: '/profile/terms-policies',
        title: 'Terms and Policies',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <TermsPolicies />
          </TabWrapper>
        ),
      },
      ...canvaIntegrationItem,
    ]);
  }, [userData, canvaIntegrationItem]);

  const profilePageRoutes = useMemo(() => {
    return [
      ...profilePages,
      {
        path: '/profile/password-reset',
        title: 'Reset Password',
        exactPath: false,
        component: () => (
          <TabWrapper>
            <ResetPassword />
          </TabWrapper>
        ),
      },
    ];
  }, [profilePages]);

  return (
    <Layout>
      <div className="MyProfilePage">
        <DeactivateInfo />
        <BrowserRouter>
          <nav className="MyProfilePage__nav">
            <h1 className="MyProfilePage__header">Account Settings</h1>
            <PageNav pages={profilePages} userData={userData} />
          </nav>

          <main className="MyProfilePage__main">
            <PageRoutes pages={profilePageRoutes} />
            <DeactivateAccount user={userData} />
          </main>
        </BrowserRouter>
      </div>
    </Layout>
  );
};

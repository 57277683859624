import React from 'react';
import type { ReactNode } from 'react';

import classNames from 'classnames';
import toast from 'react-hot-toast';

import Tooltip from 'rc-tooltip';

import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

import { useCropImageModalContext } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/CreativesGeneratorUploader/contexts/CropImageModalContext';

import { FlexBox } from '@eltoro-ui/components';

import CustomIcons from 'assets/icons';

import warning_icon from 'assets/Images/warning_icon.svg';

import { ECreativeUploadTitles } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/enum';

import './CreativesGeneratorUploader.scss';

const tooltipContent = {
  [ECreativeUploadTitles.AGENT_PHOTO]: (
    <span>
      Please upload an agent photo between <br /> 320 × 320px and 800 × 800px <br /> (recommended
      square format).
    </span>
  ),
  [ECreativeUploadTitles.LISTING_PHOTO]: (
    <span>
      Please upload an listing photo between <br /> 320 × 320px and 800 × 800px <br /> (recommended
      square format).
    </span>
  ),
  [ECreativeUploadTitles.AGENCY_LOGO]: (
    <span>
      Please upload agency logo between <br /> 100 × 100 px and 300 × 300 px <br /> (recommended
      square format).
    </span>
  ),
};

const uploadDimensions = {
  [ECreativeUploadTitles.AGENT_PHOTO]: {
    width: [320, 800],
    height: [320, 800],
  },
  [ECreativeUploadTitles.LISTING_PHOTO]: {
    width: [320, 800],
    height: [320, 800],
  },
  [ECreativeUploadTitles.AGENCY_LOGO]: {
    width: [100, 300],
    height: [100, 300],
  },
};

const dimensionWarnings = {
  [ECreativeUploadTitles.AGENT_PHOTO]:
    'Oops! Your image size must be between 320 × 320 px and 800 × 800 px. Please upload a correctly sized image.',
  [ECreativeUploadTitles.LISTING_PHOTO]:
    'Oops! Your image size must be between 320 × 320 px and 800 × 800 px. Please upload a correctly sized image.',
  [ECreativeUploadTitles.AGENCY_LOGO]:
    'Oops! Your logo size must be between 100 × 100 px and 300 × 300 px. Please upload a correctly sized image.',
};

type ValidatedFile = {
  file: File & { file_specs: string | null; id?: string };
  isValid: boolean;
};

export const validateBanner = async (
  file: File,
  uploaderTitle: ECreativeUploadTitles
): Promise<ValidatedFile> => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.readAsDataURL(file);
    reader.onload = event => {
      const image = new Image();
      image.src = event?.target?.result as string;
      image.onload = () => {
        const [minWidth, maxWidth] = uploadDimensions[uploaderTitle].width;
        const [minHeight, maxHeight] = uploadDimensions[uploaderTitle].height;

        const isWidthInValidRange = minWidth <= image.width && image.width <= maxWidth;
        const isHeightInValidRange = minHeight <= image.height && image.height <= maxHeight;

        if (isWidthInValidRange && isHeightInValidRange) {
          return resolve({
            file: Object.assign(file, {
              file_specs: `${image.width}x${image.height}`,
              id: file.name,
            }),
            isValid: true,
          });
        }

        toast.error(dimensionWarnings[uploaderTitle]);

        return resolve({
          file: Object.assign(file, {
            file_specs: `${image.width}x${image.height}`,
          }),
          isValid: false,
        });
      };
      image.onerror = () => {
        toast.error(`Failed to read image "${file.name}"`);
        return resolve({
          file: Object.assign(file, {
            file_specs: `${image.width}x${image.height}`,
          }),
          isValid: false,
        });
      };
    };
    reader.onerror = () => {
      toast.error(`Failed to read file "${file.name}"`);
      return resolve({
        file: Object.assign(file, {
          file_specs: null,
        }),
        isValid: false,
      });
    };
  });
};
interface CreativesGeneratorUploaderProps {
  title: ECreativeUploadTitles;
  hasCropper?: boolean;
  file: File | null;
  onUpload: (file: File) => void;
  openCropModal?: () => void;
  children: ReactNode;
}

export default function CreativesGeneratorUploader({
  children,
  hasCropper = false,
  openCropModal,
  title,
  file,
  onUpload,
}: CreativesGeneratorUploaderProps) {
  const { resetStates } = useCropImageModalContext();

  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    fileRejections.forEach(file => {
      const TypeError = file.errors.filter(eType => eType.code === 'file-invalid-type');
      if (TypeError?.[0].code === 'file-invalid-type') {
        toast.error(`File format is not supported. (${file.file.name})`, {
          icon: <img src={warning_icon} alt="warn" />,
        });
      }
    });

    if (fileRejections.length) return;

    const [file] = acceptedFiles;

    const { isValid } = await validateBanner(file, title);

    if (!isValid) return;

    if (hasCropper) resetStates();
    onUpload(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.gif', '.png', '.jpg'],
    },
    multiple: false,
  });

  const onReCropImage = () => openCropModal?.();

  const rootProps = getRootProps();

  const uploaderMaskRootProps = !hasCropper || (hasCropper && !file) ? rootProps : {};
  const uploadIconRootProps = hasCropper && file ? rootProps : {};

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      gap="0.25rem"
      UNSAFE_className="creative-uploader"
    >
      <FlexBox
        alignItems="center"
        gap="0.25rem"
        UNSAFE_className="creative-uploader-title-container"
      >
        <span className="creative-uploader-title">{title}</span>
        <Tooltip
          placement="top"
          trigger="hover"
          overlayClassName={classNames('audience-tooltip', 'creative-generator-uploader-info')}
          showArrow
          align={{
            offset: [-15, -3],
            targetOffset: [-6, 0],
          }}
          overlay={tooltipContent[title]}
          getTooltipContainer={() => document.body}
        >
          <span style={{ cursor: 'pointer', display: 'flex' }}>
            <FontAwesomeIcon icon={faExclamationCircle} color="#757575" style={{ fontSize: 20 }} />
          </span>
        </Tooltip>
      </FlexBox>
      <div className={classNames('upload-photo', { uploaded: !!file })} {...uploaderMaskRootProps}>
        <div className="uploader-mask">
          <FlexBox alignItems="center" justifyContent="center" gap="0.75rem">
            {hasCropper && file && (
              <button className="re-crop-image" onClick={onReCropImage}>
                <CustomIcons name="crop" color="#FEFEFE" fontSize={24} />
              </button>
            )}
            <div className="uploader-root" {...uploadIconRootProps}>
              <CustomIcons name={file ? 'replace' : 'upload'} color="#FEFEFE" fontSize={24} />
            </div>
          </FlexBox>
        </div>
        <input {...getInputProps()} />
        {file ? (
          <img src={URL.createObjectURL(file)} alt="uploaded-photo" className="uploaded-file" />
        ) : (
          children
        )}
      </div>
    </FlexBox>
  );
}

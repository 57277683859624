import React, { useState, Dispatch, SetStateAction, useEffect, memo } from 'react';
import { FlexBox, Text, Button } from '@eltoro-ui/components';
import Tooltip from 'rc-tooltip';
import { CreativesMutableType } from 'Components/SummaryCart/SummaryEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment/moment';
import { AudienceType } from 'types';
import { MIN_IMPRESSIONS_COUNT, DEFAULT_MIN_PROSPECTS_COUNT } from 'Utils/constants';
import Options from './Options';
import './AdvancedOptions.scss';

interface Props {
  setIsValid: Dispatch<
    SetStateAction<{
      cost: boolean;
      flightDates: boolean;
      audiences: boolean;
    }>
  >;
  creativesMutableData: CreativesMutableType;
  setCreativesMutableData: Dispatch<SetStateAction<CreativesMutableType>>;
  handleUpdateAudienceExclusion: (checked: boolean, id: number) => void;
  handleUpdateChangedAudiences: (id: number, prospCount: number, aud?: AudienceType) => void;
}

const AdvancedOptions = ({
  creativesMutableData,
  setCreativesMutableData,
  handleUpdateAudienceExclusion,
  handleUpdateChangedAudiences,
  setIsValid,
}: Props) => {
  const [isOpen, setOpen] = useState(false);

  const {
    startDate,
    endDate,
    days,
    currentDays,
    minDays,
    bannerCost,
    videoCost,
    prospectsCount,
    bannerFreq,
    videoFreq,
    campaign,
    minProspCount,
    excludedAudiences,
    currentProspectsCount,
    changedAudiences,
  } = creativesMutableData || {};

  useEffect(() => {
    let freq: number;

    if (videoCost && bannerCost) {
      freq = Math.min(bannerFreq, videoFreq);
    } else if (videoCost && !bannerCost) {
      freq = videoFreq;
    } else if (!videoCost && bannerCost) {
      freq = bannerFreq;
    } else {
      freq = 1;
    }

    const minProsp = Math.ceil(MIN_IMPRESSIONS_COUNT / (days * freq));

    setCreativesMutableData(prev => ({
      ...prev,
      minProspCount:
        minProsp < DEFAULT_MIN_PROSPECTS_COUNT ? DEFAULT_MIN_PROSPECTS_COUNT : minProsp,
    }));
  }, [days, bannerFreq, videoFreq, bannerCost, videoCost]);

  const onStartDateChange = (newDate: Date) => {
    setCreativesMutableData(prev => {
      const duration = moment(newDate).add({ days: 1 }).diff(moment(newDate), 'days');
      return {
        ...prev,
        startDate: newDate,
        endDate: newDate,
        currentDays: duration,
        days: duration >= prev.minDays ? duration : prev.days,
      };
    });
  };

  const onEndDateChange = (newDate: Date) => {
    setCreativesMutableData(prev => {
      const duration = moment(newDate).add({ days: 1 }).diff(moment(prev.startDate), 'days');

      return {
        ...prev,
        endDate: newDate,
        currentDays: duration,
        days: duration >= prev.minDays ? duration : prev.days,
      };
    });
  };

  const handleUpdateProspects = (updatedProspects: number, maxProspects: number) => {
    setCreativesMutableData(prev => ({
      ...prev,
      currentProspectsCount: updatedProspects,
      prospectsCount:
        updatedProspects >= prev.minProspCount && updatedProspects <= maxProspects
          ? updatedProspects
          : prev.prospectsCount,
    }));
  };

  return (
    <FlexBox
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      UNSAFE_style={{ width: '100%' }}
    >
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        UNSAFE_className="adv_options_heading"
      >
        <FlexBox flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <Text
            on="white"
            weight="bold"
            textAlign="left"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 14, marginBottom: '5px' }}
          >
            Still not satisfied?
          </Text>
          <Text on="white" textAlign="left" UNSAFE_style={{ color: '#757575', fontSize: 14 }}>
            Try adjusting your audiences or flight dates.{' '}
            <Tooltip
              placement="top"
              trigger="hover"
              showArrow
              overlay={
                <div
                  style={{
                    width: '255px',
                  }}
                >
                  Based on the audience, creative types, and flight dates you selected, Beewo
                  provides a range of budget by adjusting the frequency of the ad. For further
                  budget reduction you can try reducing your flight dates or audience size.
                </div>
              }
              getTooltipContainer={() => document.body}
            >
              <span>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </span>
            </Tooltip>
          </Text>
        </FlexBox>
        <Button
          kind="default"
          UNSAFE_className="adv_options_toggler"
          onClick={() => setOpen(ps => !ps)}
        >
          {isOpen ? (
            <>
              <FontAwesomeIcon icon={faEyeSlash} /> Hide Advanced Options
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faEye} /> Show Advanced Options
            </>
          )}
        </Button>
      </FlexBox>
      {isOpen && (
        <FlexBox
          UNSAFE_className="options_wrapper"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="1.5rem"
          UNSAFE_style={{ width: '100%' }}
        >
          <Options
            startDate={startDate}
            endDate={endDate}
            currentProspectsCount={currentProspectsCount}
            currentDays={currentDays}
            days={+days.toFixed(1)}
            minDays={minDays}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            prospectsCount={prospectsCount}
            audiencesList={campaign?.audience}
            minProspCount={minProspCount}
            setIsValid={setIsValid}
            handleUpdateProspects={handleUpdateProspects}
            handleUpdateAudienceExclusion={handleUpdateAudienceExclusion}
            handleUpdateChangedAudiences={handleUpdateChangedAudiences}
            excludedAudiences={excludedAudiences}
            changedAudiences={changedAudiences}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default memo(AdvancedOptions);

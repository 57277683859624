import React, { useEffect, useState } from 'react';

import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';
import cross from 'assets/Images/cross.png';
import addcardbill from 'assets/Images/addcardbill.png';
import plusIcon from 'assets/Images/addcard.png';

import { FlexBox, Text, Button, Modal } from '@eltoro-ui/components';
import { RadioButtonCustom } from '@eltoro-ui/components/RadioButtonCustom';

import AddCard from 'Pages/MyProfile/components/BillingSection/components/AddCard/AddCard';

import { brand } from 'Utils/helpers';

import { getPaymentCards } from 'Requests';

type ResultProps = {
  cost: number;
  closeModal: () => void;
  hasError?: boolean;
  canConfirmEditDate?: boolean;
  canConfirmEditAudience?: boolean;
  confirmEdit: (seletedCardId: any) => void;
  submitLoading: boolean;
};
const Result = ({
  cost,
  closeModal,
  hasError,
  canConfirmEditDate,
  canConfirmEditAudience,
  confirmEdit,
  submitLoading,
}: ResultProps) => {
  const [isSelectCard, setIsSelectCard] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [seletedCardId, setSeletedCardId] = useState('');
  const [card, setCard] = useState<any>([]);
  const getCardDetail = () => {
    getPaymentCards().then(res => {
      const data = res.data?.details;

      setCard(data);

      setSeletedCardId(res?.data?.details[0].id || '');
    });
  };

  useEffect(() => {
    getCardDetail();
  }, []);

  const handleConfirm = () => {
    if (+cost.toFixed(2) > 0) {
      setIsSelectCard(true);
    } else if (canConfirmEditAudience || canConfirmEditDate) {
      confirmEdit(seletedCardId);
    } else {
      closeModal();
    }
  };
  const handleConfirmPayment = () => {
    if (canConfirmEditAudience || canConfirmEditDate) {
      confirmEdit(seletedCardId);
    }
    setIsSelectCard(false);
  };

  return (
    <FlexBox justifyContent="space-between" alignItems="center" UNSAFE_className="footer">
      <Text
        on="white"
        weight="bold"
        textAlign="left"
        UNSAFE_style={{ color: '#2B1F0A', fontSize: 14 }}
      >
        Total Spend
      </Text>
      <FlexBox justifyContent="space-between" alignItems="center" gap="32px">
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{ color: '#2B1F0A', fontSize: 24 }}
        >
          ${cost.toFixed(2)}
        </Text>
        <Button
          kind="primary"
          size="xl"
          disabled={submitLoading || hasError}
          loading={submitLoading}
          onClick={handleConfirm}
          UNSAFE_style={{ fontWeight: 'bold', fontSize: '1rem' }}
        >
          {+cost.toFixed(2) > 0 ? 'Confirm and Checkout' : 'Confirm'}
        </Button>
      </FlexBox>
      {isSelectCard && (
        <Modal style={{ position: 'relative' }} offClick={() => setIsSelectCard(false)}>
          <div className="SummaryCart__AddCardModal">
            <img className="SummaryCart__leftcardicon" src={leftGroup} alt="leftcardicon" />
            <img className="SummaryCart__righcardicon" src={rightGroup} alt="righcardicon" />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className="BillingSection__cross"
              src={cross}
              alt="cross"
              onClick={() => setIsSelectCard(false)}
            />

            <div className="SummaryCart__allCardlist">
              <Text on="white" UNSAFE_className="SummaryCart__addCardHeading">
                {/* @ts-ignore */}
                {!card.length ? 'Add Card' : 'Select Card'}
              </Text>
              {!card.length ? (
                <div className="BillingSection__main">
                  <img
                    className="BillingSection__billingIcon"
                    src={addcardbill}
                    alt="addcardbill"
                  />
                  <Button
                    type="button"
                    kind="primary"
                    size="m"
                    weight="bold"
                    UNSAFE_className="BillingSection__billingButton"
                    onClick={() => {
                      setIsAddCard(true);
                    }}
                  >
                    Add Card
                  </Button>
                </div>
              ) : (
                <>
                  <div className="SummaryCart__allCardlist_heading">
                    <div className="SummaryCart__heading_section">
                      <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                        Card Detail
                      </Text>
                    </div>

                    <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                      Expiry Date
                    </Text>
                  </div>
                  <div className="SummaryCart__card_list Modal__scrollable-content">
                    {card?.map((item: any) => (
                      <div
                        key={item.id}
                        className={
                          seletedCardId === item.id
                            ? 'SummaryCart__detailsCard_id'
                            : 'SummaryCart__detailsCard'
                        }
                      >
                        <div className="SummaryCart__billing_card">
                          <RadioButtonCustom
                            isCircular
                            checked={seletedCardId === item.id}
                            onChange={checked => setSeletedCardId(item.id)}
                          />
                          <img
                            className="SummaryCart__billing_card_brand-icon"
                            src={brand(item?.card.brand)}
                            alt={item?.card?.brand}
                          />
                          <div className="SummaryCart__billing_card_left">
                            <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                              {item?.card?.masked_number}
                            </Text>
                            <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                              {`${item?.card?.first_name} ${item?.card?.last_name}`}
                            </Text>
                          </div>
                        </div>
                        <div className="">
                          <Text on="white" UNSAFE_className="SummaryCart__heading_text_detail">
                            {`${item?.card?.expiry_month}/${item?.card?.expiry_year}`}
                          </Text>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="SummaryCart__addCard">
                    <button
                      className="SummaryCart__headingButton"
                      type="button"
                      onClick={() => {
                        setIsAddCard(true);
                        setIsSelectCard(false);
                      }}
                    >
                      <img src={plusIcon} alt="uploadicon" style={{ marginRight: '10px' }} /> Add
                      Card
                    </button>
                  </div>
                  <Button
                    kind="primary"
                    width="30%"
                    weight="bold"
                    loading={submitLoading}
                    disabled={submitLoading}
                    UNSAFE_style={{
                      margin: '1.5rem auto 0 auto',
                    }}
                    UNSAFE_className="summary_card_done"
                    onClick={handleConfirmPayment}
                  >
                    Done
                  </Button>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
      {isAddCard && (
        <Modal style={{ position: 'relative' }}>
          <div className="BillingSection__AddCardModal">
            <img
              style={{
                position: 'absolute',
                bottom: '0',
                right: '0px',
                width: '29%',
              }}
              src={rightGroup}
              alt="confirmleftcomb"
            />

            <button
              onClick={() => {
                setIsAddCard(false);
                setIsSelectCard(true);
              }}
            >
              <img className="BillingSection__cross" src={cross} alt="cross" />
            </button>
            {/* @ts-ignore */}
            <div className="SummaryCart__campaginCard">
              <p className="addCardHeading " style={{ marginBottom: '10px' }}>
                Add Card
              </p>

              <AddCard
                /* @ts-ignore */
                getCardDetail={getCardDetail}
                setIsAddCard={setIsAddCard}
                setIsSelectCard={setIsSelectCard}
              />
            </div>
            <img
              style={{
                position: 'absolute',
                top: '-20px',
                left: '8px',
                width: '28%',
              }}
              src={leftGroup}
              alt="lconfirmleftcomb"
            />
          </div>
        </Modal>
      )}
    </FlexBox>
  );
};

export default Result;

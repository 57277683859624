import React, { useMemo, useState } from 'react';

import { FlexBox, GridBox } from '@eltoro-ui/components';

import OrderlineDetailsContainer from 'Pages/OrderlineDetails/components/OrderlineDetailsContainer';
import CreativeCard from 'Pages/OrderlineDetails/components/OrderlineCreatives/components/CreativeCard';
import OrderlineCretiveEditModal from 'Pages/OrderlineDetails/components/OrderlineCreatives/components/OrderlineCretiveEditModal';

import type { TOrderlineCreative, TOrderlineDetails } from 'types';

interface OrderlineCreativesProps
  extends Pick<
    TOrderlineDetails,
    'clickThroughUrl' | 'creatives' | 'rejection_note' | 'type' | 'status'
  > {
  orderlineId: string;
  onSaveEditCreatives: () => void;
}

export default function OrderlineCreatives({
  rejection_note,
  creatives,
  clickThroughUrl,
  type,
  orderlineId,
  onSaveEditCreatives,
  status,
}: OrderlineCreativesProps) {
  const [openEditCretieveModal, setOpenEditCreativeModal] = useState(false);
  const handleOpenEditModal = (open: boolean) => {
    setOpenEditCreativeModal(open);
  };

  const highestPerforming = useMemo(
    () =>
      creatives.reduce<TOrderlineCreative>((previousValue, currentValue) => {
        return currentValue.ctr > previousValue.ctr ? currentValue : previousValue;
      }, creatives[0]),
    [creatives]
  );

  return (
    <OrderlineDetailsContainer
      title="Creatives"
      rejectionNote={rejection_note}
      handleOpenEditModal={handleOpenEditModal}
      status={status}
    >
      <FlexBox flexDirection="column" gap="1rem">
        <span className="orderline-details-flight_dates">
          Clickthrough URL: <b>{clickThroughUrl}</b>
        </span>
        <GridBox gap="1.5rem" gridTemplateColumns="repeat(3, 1fr)">
          {creatives.map(creative => (
            <CreativeCard
              key={creative.creative_uuid}
              creative={creative}
              highestPerforming={creative.creative_uuid === highestPerforming.creative_uuid}
            />
          ))}
        </GridBox>
      </FlexBox>
      {openEditCretieveModal && (
        <OrderlineCretiveEditModal
          onCancel={() => handleOpenEditModal(false)}
          onSave={onSaveEditCreatives}
          creatives={creatives}
          clickThroughUrl={clickThroughUrl}
          type={type}
          orderlineId={orderlineId}
        />
      )}
    </OrderlineDetailsContainer>
  );
}

import React from 'react';

import classNames from 'classnames';

import { InputText } from 'primereact/inputtext';
import type { InputTextProps } from 'primereact/inputtext';

import { FlexBox } from '@eltoro-ui/components';

import './TextInput.scss';

interface TextInputProps {
  name: string;
  label: string;
  placeholder: string;
  error?: string;
  optional?: boolean;
  required?: boolean;
  value: string;
  onChange?: InputTextProps['onChange'];
}

export default function TextInput({
  name,
  label,
  error,
  onChange,
  optional,
  required,
  value,
  placeholder,
}: TextInputProps) {
  return (
    <FlexBox UNSAFE_className="text-input-container" flexDirection="column" gap="8px">
      <FlexBox alignItems="center" gap="3px">
        <label htmlFor={name}>
          {label}
          {required && <span style={{ color: '#BD0000' }}>*</span>}
        </label>
        {optional && <span className="optional-info">(optional)</span>}
      </FlexBox>
      <FlexBox UNSAFE_className="input-wrapper" flexDirection="column" gap="1px">
        <InputText
          id={name}
          className={classNames('creative-field-input', error && 'p-invalid')}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {error && (
          <span className={classNames('creative-field-input-error', 'p-error')}>{error}</span>
        )}
      </FlexBox>
    </FlexBox>
  );
}

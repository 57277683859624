import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import Hotjar from '@hotjar/browser';
import ReactGA from 'react-ga4';

export type PropsWithChildren = {
  children: ReactNode;
};

type SidebarContextType = {
  campaignsDropdown: boolean;
  settingsDropdown: boolean;
  toggleDropdown: (value?: 'campaigns' | 'settings') => void;
};

const defaultValues: SidebarContextType = {
  campaignsDropdown: false,
  settingsDropdown: false,
  toggleDropdown: () => undefined,
};

const campaignsRoutes = [
  '/campaign-dashboard',
  '/orderlines?filter=campaigns',
  '/saved-audiences',
  '/creatives-library',
  '/create-campaign',
  '/orderlines',
  '/all-campaigns',
];

const settingsRoutes = ['/profile'];

const SidebarContext = createContext<SidebarContextType>(defaultValues);

export const useSidebarContext = () => useContext(SidebarContext);

const SidebarContextProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  const [campaignsDropdown, setCampaignsDropdown] = useState<boolean>(false);
  const [settingsDropdown, setSettingsDropdown] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('beewo_token');
    if (!token) {
      setSettingsDropdown(false);
      setCampaignsDropdown(false);
    }
  });

  useEffect(() => {
    if (Hotjar.isReady()) Hotjar.stateChange(location.pathname + location.search);
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (campaignsRoutes.includes(location.pathname)) toggleDropdown('campaigns');
    if (settingsRoutes.includes(location.pathname)) toggleDropdown('settings');
  }, [location.pathname]);

  const toggleDropdown = (value?: 'campaigns' | 'settings') => {
    if (value === 'campaigns') {
      setCampaignsDropdown(true);
      setSettingsDropdown(false);
    }
    if (value === 'settings') {
      setSettingsDropdown(true);
      setCampaignsDropdown(false);
    }

    if (!value) {
      setSettingsDropdown(false);
      setCampaignsDropdown(false);
    }
  };

  return (
    <SidebarContext.Provider value={{ campaignsDropdown, settingsDropdown, toggleDropdown }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;

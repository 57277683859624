import React, { useState, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import { AudienceType } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import ProspectsHeader from './ProspectsHeader';
import AudiencesList from './AudiencesList';

interface Props {
  prospectsCount: number;
  currentProspectsCount: number;
  minProspCount: number;
  listData?: AudienceType[];
  handleUpdateProspects: (updatedProspects: number, maxProspectsCount: number) => void;
  excludedAudiences: number[];
  changedAudiences: AudienceType[];
  handleUpdateAudienceExclusion: (checked: boolean, id: number) => void;
  handleUpdateChangedAudiences: (id: number, prospCount: number, aud?: AudienceType) => void;
  setIsValid: Dispatch<
    SetStateAction<{
      cost: boolean;
      flightDates: boolean;
      audiences: boolean;
    }>
  >;
}

type AudType = {
  id: number;
  prospCount: number;
  maxProsp: number;
  name: string;
  isActive: boolean;
};

const Audiences = ({
  prospectsCount,
  currentProspectsCount,
  setIsValid,
  listData,
  minProspCount,
  excludedAudiences,
  changedAudiences,
  handleUpdateProspects,
  handleUpdateAudienceExclusion,
  handleUpdateChangedAudiences,
}: Props) => {
  const [currentProspects, setCurrentProspects] = useState(currentProspectsCount);
  const [audiences, setAudiences] = useState<AudType[]>(
    listData?.map(aud => {
      const isActive = !excludedAudiences.includes(aud.id);
      let prospCount = aud.prospects_counts;

      const alreadyChangedAud = changedAudiences.find(item => item.id === aud.id);

      if (alreadyChangedAud) {
        prospCount = alreadyChangedAud.prospects_counts;
      }

      return {
        id: aud.id,
        prospCount,
        maxProsp: aud.prospects_counts,
        name: aud.name,
        isActive,
      };
    }) ?? []
  );

  useEffect(() => {
    const prospCount = audiences?.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.isActive ? currentValue.prospCount : 0);
    }, 0);
    setCurrentProspects(prospCount as number);
  }, [audiences]);

  const hasError = useMemo(() => {
    return currentProspects < minProspCount;
  }, [currentProspects, minProspCount]);

  useEffect(() => {
    setIsValid(prev => ({
      ...prev,
      audiences:
        !hasError &&
        !!audiences?.every(aud => aud.prospCount >= 1 && aud.prospCount <= aud.maxProsp),
    }));
  }, [hasError, audiences]);

  useEffect(() => {
    handleUpdateProspects(
      currentProspects,
      audiences.reduce((acc, aud) => (aud.isActive ? acc + aud.maxProsp : acc), 0)
    );
  }, [currentProspects, audiences]);

  const handleUpdateExclusion = (state: boolean, audienceId?: number) => {
    const audArr = [...audiences];
    const foundAud = audArr.find(aud => aud.id === audienceId);

    if (foundAud) {
      foundAud.isActive = state;
      setAudiences(audArr);
    }
    handleUpdateAudienceExclusion(state, audienceId as number);
  };

  const handleProspectCountUpdate = (updatedCount: number, audienceId?: number) => {
    const audArr = [...audiences];
    const foundAud = audArr.find(aud => aud.id === audienceId);

    if (foundAud) {
      foundAud.prospCount = updatedCount;
      setAudiences(audArr);
    }
    const aud = listData?.find(item => item.id === audienceId);
    handleUpdateChangedAudiences(audienceId as number, updatedCount, aud);
  };

  return (
    <FlexBox
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      UNSAFE_style={{ width: '100%', marginTop: '2rem' }}
    >
      <ProspectsHeader currentProspects={prospectsCount} minProspectsCount={minProspCount} />
      <AudiencesList
        listData={audiences}
        handleUpdateExclusion={handleUpdateExclusion}
        handleProspectCountUpdate={handleProspectCountUpdate}
      />
      {hasError && (
        <Text
          on="white"
          textAlign="left"
          UNSAFE_style={{ color: '#FF2D38', fontSize: 14, marginTop: '8px' }}
        >
          <FontAwesomeIcon icon={faExclamationCircle} /> Total min cannot be less than{' '}
          {minProspCount}
        </Text>
      )}
    </FlexBox>
  );
};

export default Audiences;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import toast from 'react-hot-toast';

import { Bar } from 'react-chartjs-2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Button, Modal, Table, Text } from '@eltoro-ui/components';

import { usePermissionContext } from 'contexts/PermissionContext';

import { Loading, Section, Spinner } from 'Components';

import { currentCampaignName } from 'Redux/actions';

import { getCampaigns } from 'Requests';
import { getCompaignPerformance } from 'Requests/Request_Methods/campaignMethods';

import nodata from 'assets/Images/nodata.png';

import { multiOverlappingOptions } from 'Pages/CampaignDashboard/barOptions';

import './CampaignPerformance.scss';

export const CampaignPerformance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [loader, setloader] = useState(true);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [unselect, setunselect] = useState();
  const [campaignName, setcampaignName] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState<any[]>([]);
  const [pages, setPages] = useState<any>();
  const [LoadingList, setLoadingList] = useState(false);
  const [barData, setbarData] = useState({});
  const [submitList, setsubmitList] = useState(false);
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoadingList(true);
    if (currentPage === 1) {
      setloader(true);
    }
    getCampaignsList();
  }, [currentPage]);
  useEffect(() => {
    dispatch(currentCampaignName(campaignName));
  }, [campaignName]);
  const getCampaignsList = () => {
    getCampaigns(currentPage, 20, null, null, null, false).then((res: any) => {
      setCampaigns([...campaigns, ...res.data.campaigns]);

      if (res.data.campaigns[0]) {
        if (currentPage === 1) {
          campaignPerformance(res.data.campaigns[0]);
          setSelectedCampaign([res.data.campaigns[0]]);
          setcampaignName(res.data?.campaigns[0]?.name);
        } else {
          setloader(false);
        }
        setLoadingList(false);
        setPages({
          current_page: res?.data?.current_page,
          total_count: res?.data?.total_campaigns,
          total_pages: res?.data?.total_pages,
          last_page: res?.data?.current_page === res?.data?.total_pages,
        });
      } else {
        // toast.error('Campaign not found')
        setloader(false);
        setLoadingList(false);
      }
    });
  };

  const campaignPerformance = (selectedCamp: undefined | any) => {
    setsubmitList(true);
    getCompaignPerformance(selectedCamp.id)
      .then(res => {
        if (currentPage === 1) {
          setloader(false);
        }
        setsubmitList(false);
        setcampaignName(selectedCamp.name);
        if (Array.isArray(res.data) && res.data.length) {
          const data = {
            labels: res.data.map(
              dates =>
                `${moment(dates.start_date).format('MM/DD/YYYY')} - ${moment(dates.end_date).format(
                  'MM/DD/YYYY'
                )}`
            ),
            datasets: [
              {
                label: 'Clicks',
                data: res.data.map(d => d.clicks),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                // parsing: {
                //   yAxisKey: 'clicks',
                // },
              },
              {
                label: 'Impressions',
                data: res.data.map(d => d.impressions),
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                // parsing: {
                //   yAxisKey: 'impressions',
                // },
              },
              {
                label: 'Ratio',
                data: res.data.map(d => d.ratio),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                // parsing: {
                //   yAxisKey: 'ratio',
                // },
              },
            ],
          };
          setbarData(data);
          setIsModalOpen(false);
        }
      })
      .catch(() => toast.error('Something went wrong!'));
  };

  const listInnerRef = useRef<HTMLInputElement | null>(null);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (!pages.last_page) {
          setTimeout(() => {
            setCurrentPage(currentPage + 1);
          }, 300);
        }
      }
    }
  };

  return (
    <>
      {loader && (
        <div className="CampaignPerformance__loader_campain">
          <Loading />
        </div>
      )}
      {!loader && campaigns.length > 0 && (
        <Section
          title="Campaign Performance"
          UNSAFE_className_text="CampaignPerformance__title"
          sub_title={campaignName}
          actions={
            <Button
              kind="default"
              size="l"
              onClick={() => setIsModalOpen(true)}
              UNSAFE_className="CampaignPerformance__select_campaign"
              disabled={
                campaignName === '' || userIsDeactivatedWithActiveOrderlines || userIsFailed
              }
            >
              Select Campaign
            </Button>
          }
        >
          {isModalOpen && (
            <Modal offClick={() => setIsModalOpen(false)}>
              <div className="OnboardingModal-welcome CampaignPerformance__submitModal">
                <div className="SaveAudienceModal__cancel">
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="#6d6d6d"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  />
                </div>
                <div className="CampaignPerformance__content">
                  <Text
                    on="white"
                    size="xxxl"
                    UNSAFE_className="CampaignPerformance__contentModal"
                    textAlign="center"
                  >
                    Select Campaign
                  </Text>

                  <div
                    className="Modal__scrollable-content"
                    onScroll={() => onScroll()}
                    ref={listInnerRef}
                  >
                    <Table
                      noCheckbox
                      rows={campaigns}
                      onSelect={camp => {
                        setSelectedCampaign(camp);
                      }}
                      selectedRows={selectedCampaign}
                      radioButton
                      unSelectAll={unselect}
                      columns={[
                        {
                          path: 'name',
                          label: 'Campaign Name',
                          width: '50%',
                          alignment: 'left',
                        },

                        {
                          path: 'created_at',
                          label: 'Date Captured',
                          alignment: 'left',
                          width: '20%',
                          RowCell: campaign => (
                            <div>
                              {moment(`${campaign.created_at}Z`).format('MM/DD/YYYY')}
                              <br />
                              {moment(`${campaign.created_at}Z`).format('hh:mm A')}
                            </div>
                          ),
                        },
                      ]}
                    />
                    {LoadingList && <Spinner />}
                  </div>

                  <div className="select-modal-footer">
                    <Button
                      kind="primary"
                      size="xl"
                      width="204px"
                      disabled={submitList}
                      weight="bold"
                      onClick={() => {
                        campaignPerformance(selectedCampaign[0]);
                      }}
                      loading={submitList}
                    >
                      {submitList ? '' : 'Done'}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          <div className="CampaignDashboard__campaign-performance">
            <Bar data={barData} options={multiOverlappingOptions} />
          </div>
        </Section>
      )}

      {!loader && campaigns.length < 1 && (
        <Section
          title="Campaign Performance"
          UNSAFE_className_text="CampaignPerformance__title"
          UNSAFE_className_container="no-campaigns"
          spacer={false}
        >
          <div className="CampaignDashboard__campaign-performance">
            <img src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Campaigns to Display
            </Text>
          </div>
        </Section>
      )}
    </>
  );
};

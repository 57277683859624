import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';
import HistoryAlertItem from 'Pages/CampaignDashboard/components/HistoryAlerts/HistoryAlertSection/HistoryAlertItem';
import { THistoryAlert } from 'types';

type HistoryAlertsSectionProps = { alerts: THistoryAlert[] };
const HistoryAlertSection = ({ alerts }: HistoryAlertsSectionProps) => {
  return (
    <FlexBox flexDirection="column" UNSAFE_style={{ width: '100%' }}>
      <Text on="white" UNSAFE_style={{ color: '#757575', paddingBottom: '12px' }} weight={700}>
        History of Alerts
      </Text>
      <FlexBox flexWrap="wrap" gap="16px">
        {alerts.map(alert => (
          <HistoryAlertItem key={alert.listing.id} alert={alert} />
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default HistoryAlertSection;

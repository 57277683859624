import React from 'react';

import { Text, View, StyleSheet, Svg, G, Path, Defs, ClipPath, Rect } from '@react-pdf/renderer';

import { capitalize } from '@eltoro-ui/creative_generator/Helpers';

import { compactNumberConverter } from 'Utils/helpers';

import { TOrderlineDetails } from 'types';

const ClicksSVG = () => (
  <Svg width="29" height="29" viewBox="0 0 29 29">
    <G clip-path="url(#clip0_69_93)">
      <Path
        d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z"
        fill="#F3FDFF"
      />
      <Path
        d="M10.7395 9.97908C11.0194 9.69916 11.0194 9.2618 10.7395 8.99945L9.23499 7.49498C8.95506 7.21505 8.5177 7.21505 8.25535 7.49498C7.97543 7.7749 7.97543 8.21226 8.25535 8.47461L9.75983 9.97908C10.0222 10.259 10.4595 10.259 10.7395 9.97908Z"
        fill="#2CCCEF"
      />
      <Path
        d="M9.11225 14.3351C9.30466 14.3351 9.47968 14.2652 9.60208 14.1252C9.74203 13.9852 9.81196 13.8104 9.81196 13.6353C9.81196 13.2505 9.49714 12.9357 9.11228 12.9357L6.97803 12.9355C6.61056 12.9355 6.27832 13.268 6.27832 13.6352C6.27832 13.8102 6.34825 13.9852 6.47077 14.1251C6.59316 14.265 6.78558 14.3351 6.97803 14.3351H9.11225Z"
        fill="#2CCCEF"
      />
      <Path
        d="M15.0597 9.64668C15.2172 9.5592 15.3397 9.41923 15.3921 9.22681L16.0045 7.18004C16.1094 6.8126 15.8996 6.42786 15.5321 6.30534C15.1647 6.20038 14.7799 6.41028 14.6574 6.77768L14.0451 8.82449C13.9401 9.19192 14.15 9.57666 14.5174 9.69918C14.6923 9.75165 14.8848 9.73416 15.0597 9.64668Z"
        fill="#2CCCEF"
      />
      <Path
        d="M20.9554 16.2069L13.1532 11.7634C12.9958 11.6759 12.8033 11.6935 12.6634 11.7809C12.5234 11.8858 12.4535 12.0608 12.4884 12.2357L14.1152 21.1751C14.1503 21.35 14.2726 21.4899 14.4301 21.5249C14.605 21.5774 14.7799 21.5249 14.8849 21.3849L16.4418 19.6181L18.786 22.8719C18.8559 22.9769 18.961 23.0294 19.0834 23.0469H19.1883C19.2758 23.0469 19.3457 23.0119 19.4158 22.9595L21.0426 21.7699C21.2351 21.63 21.2875 21.35 21.1476 21.1576L18.9436 17.9737L20.9205 17.0115C21.0779 16.9416 21.1654 16.784 21.1654 16.6266C21.1829 16.4516 21.0954 16.2943 20.9554 16.2069Z"
        fill="#2CCCEF"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_69_93">
        <Rect width="29" height="29" fill="white" x="" y="" />
      </ClipPath>
    </Defs>
  </Svg>
);

const ImpressionsSVG = () => (
  <Svg width="29" height="29" viewBox="0 0 29 29">
    <G clip-path="url(#clip0_69_89)">
      <Path
        d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z"
        fill="#FCF4FF"
      />
      <Path
        d="M5.38135 14.581C5.45471 14.4938 5.51831 14.3919 5.60145 14.3192C8.20855 12.1667 10.8156 10.0141 13.4276 7.8615C14.0537 7.34273 14.7287 7.3476 15.3548 7.8615C16.1716 8.53537 16.9836 9.20928 17.7956 9.88316C17.8396 9.92196 17.8836 9.95589 17.957 10.0092C17.9618 9.9268 17.9667 9.87347 17.9667 9.82013C17.9667 9.20444 17.9667 8.58871 17.9667 7.97301C17.9667 7.61909 18.0841 7.50758 18.4412 7.50758C19.082 7.50758 19.7178 7.50758 20.3586 7.50758C20.701 7.50758 20.8233 7.63362 20.8233 7.97301C20.8233 9.38382 20.8233 10.7995 20.8184 12.2103C20.8184 12.3412 20.8575 12.4236 20.9602 12.5012C21.6939 13.0975 22.4227 13.7035 23.1516 14.3047C23.4499 14.5471 23.4646 14.7168 23.22 15.0077C23.0391 15.221 22.8581 15.4343 22.6771 15.6476C22.4765 15.8852 22.2809 15.8997 22.0412 15.7009C19.5662 13.655 17.0863 11.6091 14.6113 9.5632C14.5379 9.50502 14.4694 9.44684 14.3814 9.3741C14.1026 9.60682 13.8238 9.83469 13.545 10.0625C11.2754 11.9388 9.00581 13.815 6.73625 15.6864C6.47701 15.8997 6.29114 15.8852 6.07591 15.6331C5.84604 15.3567 5.61613 15.0707 5.38135 14.7944C5.38135 14.7216 5.38135 14.6537 5.38135 14.581Z"
        fill="#BC2CEF"
      />
      <Path
        d="M15.8247 21.6789C15.8247 20.2681 15.8247 18.867 15.8247 17.4513C14.8661 17.4513 13.922 17.4513 12.9584 17.4513C12.9584 18.8573 12.9584 20.2681 12.9584 21.6935C12.8704 21.6935 12.8068 21.6935 12.7432 21.6935C11.4323 21.6935 10.1166 21.6935 8.80569 21.6935C8.23341 21.6935 7.95459 21.4171 7.95459 20.8499C7.95459 19.1627 7.95459 17.4804 7.95459 15.7933C7.95459 15.6769 7.97905 15.5897 8.0769 15.5072C10.1312 13.8346 12.1856 12.1523 14.24 10.4749C14.2889 10.4361 14.3378 10.3973 14.4014 10.3536C14.8612 10.7318 15.3209 11.1003 15.7758 11.4736C17.4193 12.8165 19.0579 14.1595 20.7014 15.4975C20.7944 15.5751 20.8384 15.6527 20.8384 15.7739C20.8335 17.4901 20.8384 19.2015 20.8335 20.9178C20.8335 21.3832 20.5351 21.6886 20.0655 21.6886C18.696 21.6935 17.3215 21.6886 15.9519 21.6886C15.9079 21.6886 15.8737 21.6838 15.8247 21.6789Z"
        fill="#BC2CEF"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_69_89">
        <Rect width="29" height="29" fill="white" x="" y="" />
      </ClipPath>
    </Defs>
  </Svg>
);

const CtrSVG = () => (
  <Svg width="29" height="29" viewBox="0 0 29 29">
    <G clip-path="url(#clip0_69_80)">
      <Path
        d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z"
        fill="#FFF5FC"
      />
      <Path
        d="M20.8025 15.8328C20.6812 15.7351 20.5262 15.6879 20.3712 15.7048C20.2162 15.7216 20.0746 15.7958 19.9769 15.9171L14.6692 22.4009C14.5681 22.5222 14.5209 22.6772 14.5378 22.8323C14.5546 22.9873 14.6288 23.1322 14.7534 23.2299C14.8579 23.3175 14.9894 23.3681 15.1242 23.3715C15.2994 23.3715 15.4645 23.2939 15.5791 23.1591L20.8868 16.6719C20.9913 16.5506 21.0384 16.3923 21.0249 16.2339C21.0081 16.0788 20.9306 15.9306 20.8025 15.8328Z"
        fill="#EF2CB8"
      />
      <Path
        d="M19.842 19.8363C19.3736 19.8363 18.922 20.0217 18.5917 20.3553C18.2615 20.6856 18.0728 21.1371 18.0728 21.6055C18.0728 22.074 18.2581 22.5255 18.5917 22.8558C18.922 23.186 19.3736 23.3748 19.842 23.3748C20.3104 23.3748 20.762 23.1894 21.0923 22.8558C21.4225 22.5255 21.6112 22.074 21.6112 21.6055C21.6112 21.1371 21.4259 20.6856 21.0923 20.3553C20.762 20.0217 20.3104 19.8363 19.842 19.8363ZM19.842 22.1953C19.687 22.1953 19.5353 22.1346 19.4241 22.0234C19.3129 21.9122 19.2522 21.7639 19.2522 21.6055C19.2522 21.4505 19.3129 21.2989 19.4241 21.1877C19.5353 21.0765 19.6836 21.0158 19.842 21.0158C20.0004 21.0158 20.1486 21.0765 20.2599 21.1877C20.3711 21.2989 20.4317 21.4471 20.4317 21.6055C20.4317 21.7606 20.3711 21.9122 20.2599 22.0234C20.1486 22.1346 20.0004 22.1953 19.842 22.1953Z"
        fill="#EF2CB8"
      />
      <Path
        d="M12.1752 7.18555C12.3302 7.18555 12.4818 7.12489 12.593 7.01367C12.7042 6.90246 12.7649 6.75417 12.7649 6.5958V6.27229C12.7649 6.05996 12.6537 5.86787 12.4684 5.76004C12.283 5.6522 12.0606 5.65558 11.8786 5.76004C11.6966 5.8645 11.582 6.05996 11.582 6.27229V6.5958C11.582 6.75082 11.6427 6.90246 11.7539 7.01367C11.8685 7.12489 12.0201 7.18555 12.1752 7.18555Z"
        fill="#EF2CB8"
      />
      <Path
        d="M14.0221 7.95384C14.1805 7.95384 14.3322 7.88983 14.44 7.77861L14.6691 7.54945C14.8174 7.40116 14.8747 7.18214 14.8208 6.97992C14.7669 6.77772 14.6051 6.61933 14.4029 6.56543C14.2007 6.51149 13.9817 6.57215 13.8334 6.72044L13.6043 6.95633C13.493 7.06754 13.4324 7.21583 13.4324 7.37085C13.4324 7.52586 13.493 7.6775 13.6043 7.78534C13.7155 7.89318 13.8637 7.95384 14.0221 7.95384Z"
        fill="#EF2CB8"
      />
      <Path
        d="M9.91734 7.77534C10.0285 7.88317 10.1802 7.94384 10.3352 7.94384C10.4902 7.94384 10.6419 7.87983 10.7497 7.76523C10.8575 7.65402 10.9182 7.50238 10.9182 7.34736C10.9148 7.19235 10.8542 7.04068 10.7396 6.93284L10.5037 6.70368C10.352 6.55877 10.133 6.50149 9.93079 6.56215C9.7286 6.61943 9.57358 6.7812 9.51968 6.98678C9.46912 7.19235 9.52978 7.40802 9.68142 7.55293L9.91734 7.77534Z"
        fill="#EF2CB8"
      />
      <Path
        d="M18.3355 14.8521C18.103 14.4545 17.7525 14.1377 17.3346 13.9389L14.3455 12.9346C14.2242 12.8942 14.1197 12.8133 14.0456 12.7088C13.9748 12.601 13.9377 12.4763 13.9444 12.3449V10.397C13.9444 9.76347 13.6074 9.18048 13.0615 8.86369C12.5156 8.5469 11.8382 8.5469 11.2923 8.86369C10.7463 9.18048 10.4093 9.76347 10.4093 10.397V14.7982L9.8196 14.5994C9.33434 14.4309 8.7985 14.4848 8.35366 14.7409C7.90882 14.997 7.59879 15.4385 7.50444 15.9406C7.41008 16.4461 7.53813 16.9684 7.8583 17.3695L10.1869 20.3182C10.5206 20.7563 11.0395 21.0124 11.5888 21.0124H14.5376C14.7128 21.0124 14.8779 20.9349 14.9925 20.8001L15.6058 20.0621L15.7373 19.9037C15.7305 19.9037 15.7238 19.9037 15.7204 19.9037C15.0667 19.9037 14.4533 19.6509 13.9916 19.1893C13.53 18.7276 13.2772 18.1142 13.2772 17.4605C13.2772 16.8067 13.53 16.1934 13.9916 15.7317C14.4533 15.27 15.0667 15.0173 15.7204 15.0173C16.3742 15.0173 16.9875 15.27 17.4492 15.7317C17.8098 16.0923 18.0423 16.5438 18.1266 17.0325L18.5175 16.5641C18.6119 16.4528 18.659 16.3079 18.6523 16.163C18.6759 15.7081 18.568 15.2498 18.3355 14.8521Z"
        fill="#EF2CB8"
      />
      <Path
        d="M15.7138 15.6947C15.2454 15.6947 14.7938 15.8801 14.4636 16.2137C14.1333 16.5439 13.9446 16.9955 13.9446 17.4639C13.9446 17.9324 14.1299 18.3839 14.4636 18.7142C14.7938 19.0444 15.2454 19.2331 15.7138 19.2331C16.1822 19.2331 16.6338 19.0478 16.964 18.7142C17.2943 18.3839 17.483 17.9324 17.483 17.4639C17.483 16.9955 17.2977 16.5439 16.964 16.2137C16.6338 15.8834 16.1822 15.6947 15.7138 15.6947ZM15.7138 18.0537C15.5588 18.0537 15.4071 17.993 15.2959 17.8818C15.1847 17.7706 15.1241 17.6223 15.1241 17.4639C15.1241 17.3055 15.1847 17.1573 15.2959 17.046C15.4071 16.9349 15.5554 16.8742 15.7138 16.8742C15.8722 16.8742 16.0205 16.9349 16.1317 17.046C16.2429 17.1573 16.3035 17.3055 16.3035 17.4639C16.3035 17.6223 16.2429 17.7706 16.1317 17.8818C16.0205 17.993 15.8722 18.0537 15.7138 18.0537Z"
        fill="#EF2CB8"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_69_80">
        <Rect width="29" height="29" fill="white" x="" y="" />
      </ClipPath>
    </Defs>
  </Svg>
);

const styles = StyleSheet.create({
  orderline: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '13px 16px',
    borderRadius: '20px',
    flexDirection: 'column',
  },
  orderline_details_header: {
    width: '100%',
    paddingBottom: '8px',
    flexDirection: 'row',
  },
  orderline_details_title: {
    fontSize: '12px',
    color: '#262626',
  },
  // Creatives
  orderline_creatives: {
    flexDirection: 'column',
  },
  orderline_individual_creative: {
    flexDirection: 'column',
    padding: '12px',
    backgroundColor: '#F8F8F8',
    borderRadius: '12px',
    marginBottom: '10px',
  },
  orderline_individual_creative_name_wr: {
    marginBottom: '15px',
    flexDirection: 'row',
  },
  orderline_individual_creative_name: {
    fontSize: '14px',
  },
  orderline_individual_creative_stats: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  orderline_individual_creative_stats_ind: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '15px',
  },
  orderline_individual_creative_stats_ind_icon: {
    width: '20px',
    height: '20px',
    marginRight: '12px',
  },
  orderline_individual_creative_stats_ind_content: {
    flexDirection: 'row',
  },
  orderline_individual_creative_stats_ind_title: {
    color: '#7E7E7E',
    fontSize: '14px',
    fontWeight: 400,
    marginRight: '2px',
  },
  orderline_individual_creative_stats_ind_value: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 400,
  },
});

interface CreativesProps extends Pick<TOrderlineDetails, 'creatives'> {}

export default function Creatives({ creatives }: CreativesProps) {
  return (
    <View style={{ ...styles.orderline, marginTop: '10px' }}>
      <View style={styles.orderline_details_header}>
        <View>
          <Text style={styles.orderline_details_title}>Creatives</Text>
        </View>
      </View>
      <View style={styles.orderline_creatives}>
        {creatives.map(creative => (
          <View key={creative.creative_uuid} style={styles.orderline_individual_creative}>
            <View style={styles.orderline_individual_creative_name_wr}>
              <Text
                style={{
                  ...styles.orderline_individual_creative_name,
                  marginRight: '5px',
                }}
              >
                {creative.name}
              </Text>
              <Text>
                {capitalize(creative.creative_type)} {creative.file_specs}
              </Text>
            </View>
            <View style={styles.orderline_individual_creative_stats}>
              <View style={styles.orderline_individual_creative_stats_ind}>
                <View style={styles.orderline_individual_creative_stats_ind_icon}>
                  <ClicksSVG />
                </View>
                <View style={styles.orderline_individual_creative_stats_ind_content}>
                  <Text style={styles.orderline_individual_creative_stats_ind_title}>Click:</Text>
                  <Text style={styles.orderline_individual_creative_stats_ind_value}>
                    {creative.click}
                  </Text>
                </View>
              </View>
              <View style={styles.orderline_individual_creative_stats_ind}>
                <View style={styles.orderline_individual_creative_stats_ind_icon}>
                  <ImpressionsSVG />
                </View>
                <View style={styles.orderline_individual_creative_stats_ind_content}>
                  <Text style={styles.orderline_individual_creative_stats_ind_title}>
                    Impressions:
                  </Text>
                  <Text style={styles.orderline_individual_creative_stats_ind_value}>
                    {creative.impressions}
                  </Text>
                </View>
              </View>
              <View style={styles.orderline_individual_creative_stats_ind}>
                <View style={styles.orderline_individual_creative_stats_ind_icon}>
                  <CtrSVG />
                </View>
                <View style={styles.orderline_individual_creative_stats_ind_content}>
                  <Text style={styles.orderline_individual_creative_stats_ind_title}>CTR:</Text>
                  <Text style={styles.orderline_individual_creative_stats_ind_value}>
                    {compactNumberConverter(creative.ctr, 'percent')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import type { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import type { ButtonProps } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FlexBox, Text } from '@eltoro-ui/components';
import { THistoryAlert } from 'types';

import './HistoryAlert.scss';

interface AlertOrderlinesProps extends Pick<THistoryAlert, 'orderlines'> {}
const OrderlineItem = ({
  orderline,
}: {
  orderline: {
    id: string;
    name: string;
    start: string;
    budget: number;
  };
}) => {
  return (
    <Link to={`/orderlines/${orderline.id}`} target="_blank" className="alert-redirect">
      <FontAwesomeIcon icon={faExternalLink} color="#007B94" fontSize={20} />
      <Text
        on="white"
        tag="span"
        UNSAFE_style={{ color: '#434343' }}
        weight="bold"
        size="s"
        UNSAFE_className="truncate-text"
      >
        {orderline.name}
      </Text>
      <Text on="white" tag="span" UNSAFE_style={{ color: '#757575' }} size="s">
        ({moment(orderline.start).format('MMM D')})
      </Text>
    </Link>
  );
};
const AlertOrderlines = ({ orderlines }: AlertOrderlinesProps) => {
  const orderlineRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);
  const visibleOrderlines = orderlines.slice(0, 3);
  const remainingOrderlines = orderlines.slice(3);
  const items: MenuItem[] = remainingOrderlines.map(orderline => {
    return {
      icon: <OrderlineItem orderline={orderline} />,
      url: `/orderlines/${orderline.id}`,
      target: '_blank',
    };
  });

  const toggleOrderlineSelect: ButtonProps['onClick'] = event => {
    if (orderlineRef && orderlineRef.current) {
      orderlineRef.current.toggle(event);
    }
  };

  useEffect(() => {
    // this useEffect closes the opened dropdown which is not changing it's place then scrolling the window
    const handleScroll = () => {
      if (orderlineRef.current) {
        const fakeEvent = new Event('scroll');
        orderlineRef.current.hide(fakeEvent as unknown as React.SyntheticEvent);
      }
    };

    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  return (
    <FlexBox gap="8px" flexWrap="wrap">
      {visibleOrderlines.map(orderline => {
        return <OrderlineItem orderline={orderline} key={orderline.id} />;
      })}
      {!!remainingOrderlines.length && (
        <div ref={menuRootWrapperRef} className="orderline-list-wrapper">
          <Menu
            className="orderline-list-menu"
            ref={orderlineRef}
            popup
            id="popup_menu_right"
            popupAlignment="left"
            model={items}
          />
          <button
            className="remaining-count"
            onClick={toggleOrderlineSelect}
            aria-controls="popup_menu_right"
            aria-haspopup
          >
            +{remainingOrderlines.length}
          </button>
        </div>
      )}
    </FlexBox>
  );
};

export default AlertOrderlines;

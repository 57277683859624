import React from 'react';

import classNames from 'classnames';

import Tooltip from 'rc-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyePointer } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import CustomIcons from 'assets/icons';
import EllipsisTextWithTooltip from 'Components/EllipsisTextWithTooltip';

import { compactNumberConverter } from 'Utils/helpers';

import { CreativeTypeEnum } from 'enums';
import type { TOrderlineCreative } from 'types';

import './CreativeCard.scss';

interface CreativeCardProps {
  creative: TOrderlineCreative;
  highestPerforming?: boolean;
}

export default function CreativeCard({ creative, highestPerforming = false }: CreativeCardProps) {
  return (
    <FlexBox flexDirection="column" gap="1.25rem" UNSAFE_className="creative-card-container">
      <FlexBox alignItems="center" justifyContent="space-between" gap="1rem">
        <EllipsisTextWithTooltip text={creative.name} className="creative-name" />
        {highestPerforming && (
          <Tooltip
            placement="topLeft"
            trigger="hover"
            overlayClassName={classNames('audience-tooltip', 'save-audience-info')}
            showArrow
            align={{
              offset: [-15, -3],
              targetOffset: [-6, 0],
            }}
            overlay="Highest performing campaign creative."
            getTooltipContainer={() => document.body}
          >
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <FontAwesomeIcon icon={faStar} fontSize={24} color="#FFAB03" />
            </span>
          </Tooltip>
        )}
      </FlexBox>
      <FlexBox alignItems="flex-start" gap="1.25rem">
        <div className="creative-preview-container">
          <img
            src={
              (creative.creative_type === CreativeTypeEnum.VIDEO
                ? creative.thumb_presigned_url
                : creative.presigned_url) ?? ''
            }
            alt={creative.name}
            className="creative-preview"
          />
        </div>
        <FlexBox flexDirection="column" gap="0.75rem">
          <span className="creative-specs">
            {creative.creative_type === CreativeTypeEnum.BANNER ? `Banner Size:` : 'Video:'}{' '}
            {creative.file_specs}
          </span>
          <FlexBox flexDirection="column" gap="0.5rem">
            <FlexBox alignItems="center" gap="0.75rem">
              <CustomIcons name="eye" fontSize={20} color="#757575" />
              <FlexBox alignItems="center" gap="0.5rem">
                <span className="creative-stat-label">Click:</span>
                <span className="creative-stat-value">{creative.click}</span>
              </FlexBox>
            </FlexBox>
            <FlexBox alignItems="center" gap="0.75rem">
              <FontAwesomeIcon
                icon={faBullseyePointer}
                flip="horizontal"
                style={{ fontSize: 20, color: '#757575' }}
              />
              <FlexBox alignItems="center" gap="0.5rem">
                <span className="creative-stat-label">Impressions:</span>
                <span className="creative-stat-value">{creative.impressions}</span>
              </FlexBox>
            </FlexBox>
            <FlexBox alignItems="center" gap="0.75rem">
              <CustomIcons name="hand_point" fontSize={20} color="#757575" />
              <FlexBox alignItems="center" gap="0.5rem">
                <span className="creative-stat-label">CTR:</span>
                <span className="creative-stat-value">
                  {compactNumberConverter(creative.ctr, 'percent')}
                </span>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

import {
  AudienceType,
  ResponseType,
  TAudiences,
  TDeleteAudiences,
  TSaveAudience,
  TSaveAudienceFromRecommendations,
  AssociatedCampaignResponse,
  TOrderlineTarget,
} from 'types';
import requests from '../http';

export const getAudiences = async (
  page?: number | null,
  size?: number | null,
  search?: string | null,
  sort_key?: string | null,
  order?: string | null,
  orderline_id?: string
): Promise<ResponseType<TAudiences>> => {
  const queryParams = new URLSearchParams();

  if (page) queryParams.append('page', page.toString());
  if (size) queryParams.append('size', size.toString());
  if (search) queryParams.append('search', search);
  if (sort_key && order) {
    queryParams.append('sort_key', sort_key);
    queryParams.append('order', order);
  }
  if (orderline_id) queryParams.append('orderline_id', orderline_id);
  const req = `/audience?${queryParams.toString()}`;
  return requests.get(req);
};
export const getAudienceById = async (audience_id: string): Promise<ResponseType<AudienceType>> => {
  return requests.get(`/audience/${audience_id}`);
};

export const deleteAudience = async (body: Object): Promise<ResponseType<TDeleteAudiences>> => {
  return requests.post(`/audience/delete-audience`, body);
};
export const saveAudience = async (body: Partial<any>): Promise<ResponseType<TSaveAudience>> => {
  return requests.post(`/audience/save`, body);
};
export const updateAudience = async (
  audience_id: string,
  body: Partial<AudienceType>
): Promise<ResponseType<AudienceType>> => {
  return requests.patch(`/audience/${audience_id}`, body);
};

export const createAudience = async (
  body: Partial<AudienceType>
): Promise<ResponseType<AudienceType>> => requests.post(`/audience`, body);

export const saveAudienceFromRecommendations = async (
  body: Partial<any>
): Promise<ResponseType<TSaveAudienceFromRecommendations>> => {
  return requests.post(`/audience/from-recommendation`, body);
};

export const getAssociatedCampaigns = async (
  recommendation_id: string,
  page: number
): Promise<ResponseType<AssociatedCampaignResponse>> => {
  return requests.get(`/audience/recommendation/${recommendation_id}/associated-campaigns`, {
    page,
  });
};

export const editAudiencesFromOrderline = async (
  orderlineId: string,
  body: {
    added_audience_ids?: number[];
    removed_audience_ids?: number[];
    payment_source_id: string;
    showen_budget: number;
  }
): Promise<ResponseType<TSaveAudienceFromRecommendations>> => {
  return requests.post(`/orderline/${orderlineId}/attach-audience`, body);
};

export const editFlightDateFromOrderline = async (
  orderlineId: string,
  body: {
    start_date?: Date | string;
    end_date?: Date | string;
    payment_source_id: string;
    showen_budget: number;
  }
): Promise<ResponseType<TSaveAudienceFromRecommendations>> => {
  return requests.put(`/orderline/${orderlineId}/set-dates`, body);
};

export const submitOrderline = async (
  orderlineId: string
): Promise<ResponseType<TSaveAudienceFromRecommendations>> => {
  return requests.post(`/orderline/submit-orderline/${orderlineId}`);
};

export const checkName = (val: string) => {
  if (val === 'banner') {
    return `Digital Banner Clickthrough URL`;
  }
  return 'Video Clickthrough URL';
};

export const creativeClickUrl = (str: any) => {
  return str?.replace('https://', '');
};

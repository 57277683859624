import React from 'react';
import EmptyHistory from 'assets/Images/EmptyHistoryAlert.svg';
import { Button, FlexBox, Text } from '@eltoro-ui/components';

import { usePermissionContext } from 'contexts/PermissionContext';
import './EmptyHistoryAlert.scss';

const EmptyHistoryAlert = () => {
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  return (
    <FlexBox UNSAFE_className="EmptyBox">
      <img src={EmptyHistory} width="99px" height="110px" alt="No Data" />
      <Text on="white" weight="bold" size="xxl" textAlign="center" truncate>
        Track the impact of your ads!
      </Text>
      <Text on="white" UNSAFE_style={{ color: '#888' }} textAlign="center">
        No impact has been recorded yet. Keep using the app, and we’ll show your impact once it
        happens!
      </Text>
      <Button
        key="new-campaign"
        kind="primary"
        weight="bold"
        to="?action=create-campaign"
        replace
        UNSAFE_style={{
          justifyContent: 'center',
        }}
        size="l"
        disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
      >
        New Campaign
      </Button>
    </FlexBox>
  );
};
export default EmptyHistoryAlert;

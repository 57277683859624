import { ResponseType, UserType, TUpdateUser, TUploadPicture, APIFeatureType } from 'types';
import { RecommendationTypeEnum, UserDeviceEnum } from 'enums';
import requests from '../http';

export const getUser = async (): Promise<ResponseType<UserType>> => requests.get(`/user`);

export const getUserRecommendations = async (user_id: number, page?: string) =>
  requests.get(`/users/${user_id}/recommendations?page=${page ?? '1'}&size=10`);

export const updateUser = async (body: Partial<UserType>): Promise<ResponseType<UserType>> =>
  requests.patch(`/user`, body);

export const createUser = async (body: UserType): Promise<ResponseType<UserType>> =>
  requests.post(`/user`, body);

export const updateTheUserMls = async (body: Object): Promise<ResponseType<TUpdateUser>> =>
  requests.patch('/users/update_user', body);

export const updateTheUser = async (body: Object): Promise<ResponseType<TUpdateUser>> =>
  requests.put('/users/update_user', body);

export const uploadProfilePicture = async (body: Object): Promise<ResponseType<TUploadPicture>> =>
  requests.post('/users/upload_picture', body, true);

export const userdeviceToken = async (body: object): Promise<ResponseType<string>> => {
  const req = '/users/user_device';
  return requests.post(req, body);
};

export const logout = async (device_token?: string | null): Promise<ResponseType<string>> =>
  requests.post('/logout', { device: UserDeviceEnum.WEB, device_token });

export const removeMyListing = async (
  listingId: number
): Promise<ResponseType<{ message: string }>> => requests.patch(`/users/listing/${listingId}`);

export const addToMyListing = async (listingId: number): Promise<ResponseType<APIFeatureType>> =>
  requests.post(`/users/listing/${listingId}`);

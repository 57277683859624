import React from 'react';

import { EmptyStatus, Loading } from 'Components';

import CreativeCard from 'Pages/CampaignCreation/components/CampaignCreatives/_components/ChooseFromCreativesLibraryModal/elements/CreativesContent/elements/CreativeCard';

import type { CreativeType, TCreative, TOrderlineCreative } from 'types';

type CreativesContentProps = {
  creatives: TCreative[];
  campaignCreatives: (CreativeType | TOrderlineCreative | TCreative)[];
  selectedCreatives: TCreative[];
  page: number;
  loading: boolean;
  total_pages: number;
  moreLoading: boolean;
  onLoadMore: () => void;
  onSelectCreative: (creative: TCreative) => () => void;
};

const CreativesContent = ({
  creatives,
  campaignCreatives,
  loading,
  page,
  moreLoading,
  onLoadMore,
  selectedCreatives,
  total_pages,
  onSelectCreative,
}: CreativesContentProps) => {
  if (loading) return <Loading />;

  if (!creatives.length)
    return (
      <EmptyStatus
        heading="You do not have any creatives yet"
        subHeading="Creatives uploaded in the library will be displayed here."
      />
    );

  return (
    <div className="creatives-content">
      <div className="creatives-card-container">
        {creatives.map(creative => (
          <CreativeCard
            key={creative.creative_uuid}
            creative={creative}
            selected={
              !!selectedCreatives.find(
                ({ creative_uuid }) => creative_uuid === creative.creative_uuid
              )
            }
            used={
              !!campaignCreatives.find(
                creativeItem =>
                  ('id' in creativeItem ? creativeItem.id : creativeItem.creative_uuid) ===
                  creative.creative_uuid
              )
            }
            onSelectCreative={onSelectCreative(creative)}
          />
        ))}
      </div>
      {moreLoading ? (
        <Loading />
      ) : total_pages && total_pages > 1 && total_pages !== page ? (
        <button className="load-more-creatives" onClick={onLoadMore}>
          More Creatives
        </button>
      ) : null}
    </div>
  );
};

export default CreativesContent;

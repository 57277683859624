import { Menu } from 'primereact/menu';
import { Button as PrimeButton, ButtonProps } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import React, { useRef } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { usePermissionContext } from 'contexts/PermissionContext';
import './Orderlines.scss';

type ToggleMenuProps = {
  menuItems: MenuItem[];
  isButtonDisable: boolean;
};
export default function ToggleMenu({ menuItems, isButtonDisable }: ToggleMenuProps) {
  const actionRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);
  const { userIsDeactivatedWithActiveOrderlines } = usePermissionContext();
  const toggleCreativeActionSelect: ButtonProps['onClick'] = event => {
    if (actionRef && actionRef.current) {
      actionRef.current.toggle(event);
    }
  };

  return (
    <div ref={menuRootWrapperRef} className="orderline-actions-wrapper">
      <Menu
        className="orderline-actions-menu"
        ref={actionRef}
        popup
        id="popup_menu_right"
        popupAlignment="right"
        model={menuItems}
      />
      <PrimeButton
        className="orderline-action-btn"
        disabled={userIsDeactivatedWithActiveOrderlines || isButtonDisable}
        icon={
          <FontAwesomeIcon
            icon={faEllipsisV}
            color={isButtonDisable ? '#EAECF0' : '#757575'}
            style={{ fontSize: 20 }}
          />
        }
        onClick={toggleCreativeActionSelect}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
    </div>
  );
}

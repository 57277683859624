import React from 'react';

import { FlexBox, GridBox } from '@eltoro-ui/components';

import OrderlineDetailsContainer from 'Pages/OrderlineDetails/components/OrderlineDetailsContainer';
import PerformanceStat from 'Pages/OrderlineDetails/components/PerformanceMetrics/components/PerformanceStat';
import PerformanceStatChart from 'Pages/OrderlineDetails/components/PerformanceMetrics/components/PerformanceStatChart';

import { EPerformanceStat } from 'Pages/OrderlineDetails/components/PerformanceMetrics/components/PerformanceStat/constants';
import { EPerformanceChartStat } from 'Pages/OrderlineDetails/components/PerformanceMetrics/components/PerformanceStatChart/constants';

import type { TOrderlineDetails } from 'types';
import { compactNumberConverter } from 'Utils/helpers';

export interface PerformanceMetricsProps
  extends Pick<
    TOrderlineDetails,
    | 'total_impressions'
    | 'clicks'
    | 'ctr'
    | 'served_impressions'
    | 'budget'
    | 'budget_spent'
    | 'status'
  > {}

export default function PerformanceMetrics({
  total_impressions,
  served_impressions,
  ctr,
  budget,
  budget_spent,
  clicks,
  status,
}: PerformanceMetricsProps) {
  const remainingImpressions = total_impressions - served_impressions;

  return (
    <OrderlineDetailsContainer title="Performance Metrics" status={status}>
      <FlexBox flexDirection="column" gap="1.5rem">
        <GridBox gap="1rem" gridTemplateColumns="repeat(3, 1fr)">
          <PerformanceStat name={EPerformanceStat.IMPRESSIONS} value={total_impressions} />
          <PerformanceStat name={EPerformanceStat.CLICKS} value={clicks} />
          <PerformanceStat
            name={EPerformanceStat.CTR}
            value={compactNumberConverter(ctr, 'percent')}
          />
        </GridBox>
        <GridBox gap="1rem" gridTemplateColumns="repeat(2, 1fr)">
          <PerformanceStatChart
            name={EPerformanceChartStat.IMPRESSIONS}
            stats={{
              Served: served_impressions,
              Remaining: remainingImpressions < 0 ? 0 : remainingImpressions,
            }}
            pieValue={served_impressions}
          />
          <PerformanceStatChart
            name={EPerformanceChartStat.BUDGET}
            stats={{
              Spent: budget_spent,
              Remaining: budget - budget_spent,
            }}
            pieValue={budget_spent}
          />
        </GridBox>
      </FlexBox>
    </OrderlineDetailsContainer>
  );
}

import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { CampaignSetup, CampaignCheckout, CampaignCreatives } from './components';

import './CampaignCreation.scss';

export const CampaignCreation = () => {
  const { url } = useRouteMatch();

  return (
    <div className="CampaignCreation">
      <Switch>
        <Route path={`${url}/:campaignId/setup`} component={CampaignSetup} />
        <Route path={`${url}/:campaignId/creatives`} component={CampaignCreatives} />
        <Route path={`${url}/:campaignId/calculate`} component={CampaignCheckout} />
      </Switch>
    </div>
  );
};

import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { DateRangePicker, Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import InfoNote from 'Components/SummaryCart/SummaryEditModal/components/AdvancedOptions/InfoNote';
import Result from 'Pages/OrderlineDetails/components/AttachedAudiences/components/EditAudiencesModal/Result';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { BANNER_CPM, VIDEO_CPM } from 'Utils/constants';
import { formatToUTC, formatToUTCEndDate, reduceProspects } from 'Utils/helpers';
import { FlightMutableDataType, TOrderlineDetails } from 'types';

import { editFlightDateFromOrderline } from 'Requests/Request_Methods/audienceMethods';

import 'Components/SummaryCart/SummaryEditModal/components/AdvancedOptions/AdvancedOptions.scss';
import './EditFlightDatesModalWrapper.scss';

interface EditFlightDatesModalProps
  extends Pick<
    TOrderlineDetails,
    | 'total_days'
    | 'type'
    | 'total_impressions'
    | 'targets'
    | 'served_impressions'
    | 'start_date'
    | 'end_date'
  > {
  orderlineId: string;
  onCancel: () => void;
  flightMutableData: FlightMutableDataType;
  setFlightMutableData: Dispatch<SetStateAction<FlightMutableDataType>>;
  updateOrderlineData: () => void;
}

const EditFlightDatesModal = ({
  orderlineId,
  onCancel,
  total_days,
  flightMutableData,
  setFlightMutableData,
  type,
  total_impressions,
  targets,
  served_impressions,
  updateOrderlineData,
  start_date,
  end_date,
}: EditFlightDatesModalProps) => {
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const CPM = type === 'banner' ? BANNER_CPM : VIDEO_CPM;
  const totalProspects = reduceProspects(targets);
  const FREQ = total_impressions / (totalProspects * total_days);
  const IMP = FREQ * totalProspects * flightMutableData.days;

  const imp_remaining = total_impressions - served_impressions;

  const COST = +((CPM / 1000) * (IMP - imp_remaining)).toFixed(2);

  const canConfirmEditDate = !(
    new Date(start_date).getDate() === new Date(flightMutableData.startDate).getDate() &&
    new Date(end_date).getDate() === new Date(flightMutableData.endDate).getDate()
  );

  const onStartDateChange = (newDate: Date) => {
    setFlightMutableData(prev => {
      const duration = moment(newDate).add({ days: 1 }).diff(moment(newDate), 'days');
      return {
        ...prev,
        startDate: newDate,
        endDate: newDate,
        currentDays: duration,
        days: duration >= prev.minDays ? duration : prev.days,
      };
    });
  };

  const onEndDateChange = (newDate: Date) => {
    setFlightMutableData(prev => {
      const duration = moment(newDate).add({ days: 1 }).diff(moment(prev.startDate), 'days');
      return {
        ...prev,
        endDate: newDate,
        currentDays: duration,
        days: duration >= prev.minDays ? duration : prev.days,
      };
    });
  };

  const hasError = useMemo(() => {
    return flightMutableData.currentDays < flightMutableData.minDays;
  }, [flightMutableData.currentDays, flightMutableData.minDays]);

  useEffect(() => {
    if (hasError) {
      setCalendarOpen(true);
    } else {
      setCalendarOpen(false);
    }
  }, [hasError]);

  const postFlightDateEditedBudget = (seletedCardId: any) => {
    const now = new Date();
    const start_Date = new Date(flightMutableData.startDate).getTime();
    const ToDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

    const start_date =
      start_Date === ToDay
        ? formatToUTC(new Date(), true)
        : formatToUTC(flightMutableData.startDate);

    const end_date = formatToUTCEndDate(flightMutableData.endDate);
    setSubmitLoading(true);
    editFlightDateFromOrderline(orderlineId, {
      start_date,
      end_date,
      payment_source_id: seletedCardId,
      showen_budget: COST,
    })
      .then(() => {
        onCancel();
        updateOrderlineData();
      })
      .catch(err => {
        if (err.detail) {
          toast.error(err.detail);
        } else {
          toast.error('Something went wrong!');
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <Modal closable={false} className="budget_adv_control_modal">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        UNSAFE_style={{ width: '825px' }}
        UNSAFE_className="budget_wrapper"
      >
        <Button onClick={onCancel} UNSAFE_className="budget_cross">
          <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer', fontSize: '24px' }} />
        </Button>
        <FlexBox
          flexDirection="column"
          UNSAFE_style={{ width: '100%', position: 'relative' }}
          gap="20px"
        >
          <Text
            on="white"
            weight="bold"
            textAlign="center"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 24, margin: '1.5rem 0 1rem' }}
          >
            Edit Flight Dates
          </Text>
          <FlexBox UNSAFE_className="options_wrapper">
            <FlexBox flexDirection="column" UNSAFE_className="flight_date_wrapper">
              <FlexBox
                UNSAFE_className="cost_item_wrapper"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <FlexBox
                  UNSAFE_className="heading"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="0.75rem 1.5rem"
                  UNSAFE_style={{ background: '#FEFEFE' }}
                >
                  <FlexBox
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Text
                      on="white"
                      weight="bold"
                      textAlign="left"
                      UNSAFE_style={{
                        color: '#2B1F0A',
                        fontSize: 16,
                        textTransform: 'capitalize',
                        marginBottom: '5px',
                      }}
                    >
                      Flight Dates
                    </Text>
                    <Text
                      on="white"
                      textAlign="left"
                      UNSAFE_style={{ color: '#757575', fontSize: 14 }}
                    >
                      Based on your current set budget, flight days cannot be less than{' '}
                      {flightMutableData.minDays}.
                    </Text>
                  </FlexBox>
                  <Text
                    on="white"
                    weight="bold"
                    textAlign="left"
                    UNSAFE_style={{ color: '#2B1F0A', fontSize: 16 }}
                  >
                    {flightMutableData.days} days
                  </Text>
                </FlexBox>
                <FlexBox UNSAFE_className="flight_date_info_wrapper">
                  <InfoNote />
                </FlexBox>
                <FlexBox
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  UNSAFE_style={{
                    background: '#FEFEFE',
                    padding: '2rem',
                    width: '100%',
                    borderTop: '1px solid #E7E7E7',
                    borderRadius: '0px 0px 12px 12px',
                  }}
                >
                  <FlexBox
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    UNSAFE_style={{ width: '100%', marginBottom: '1rem' }}
                  >
                    <input
                      className={`custom_date_input ${hasError ? 'error' : ''}`}
                      value={`${moment(flightMutableData.startDate).format(
                        'MM/DD/YYYY'
                      )} - ${moment(flightMutableData.endDate).format('MM/DD/YYYY')}`}
                      readOnly
                      onClick={() => setCalendarOpen(ps => !ps)}
                    />
                    {hasError && (
                      <Text
                        on="white"
                        textAlign="left"
                        UNSAFE_style={{ color: '#FF2D38', fontSize: 14, marginTop: '5px' }}
                      >
                        Flight days cannot be less than {flightMutableData.minDays}
                      </Text>
                    )}
                  </FlexBox>
                  {isCalendarOpen && (
                    <FlexBox
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <div className="DateRangeSelector__date-selection">
                        <DateRangePicker
                          startDate={moment(flightMutableData.startDate).toDate()}
                          endDate={moment(flightMutableData.endDate).toDate()}
                          min={new Date()}
                          onStartDateChange={onStartDateChange}
                          onEndDateChange={onEndDateChange}
                        />
                      </div>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <Result
          cost={COST}
          closeModal={onCancel}
          hasError={hasError}
          confirmEdit={postFlightDateEditedBudget}
          submitLoading={submitLoading}
          canConfirmEditDate={canConfirmEditDate}
        />
      </FlexBox>
    </Modal>
  );
};

export default EditFlightDatesModal;

// import { APIFeatureResponseType } from 'types'
import axios from 'axios';
import {
  ResponseType,
  APIFeatureResponseType,
  TAutoComplete,
  TPostAreaSearch,
  TPostFYP,
  TMapLocationsRes,
  TLocation,
  TDigitalProspectsRes,
} from 'types';
// import axios from 'axios'
import requests from '../http';
import { mapzenLocationResolver } from '../../Components';

export const getCanvassingData = async (
  location: string,
  radius: number
): Promise<ResponseType<any>> => {
  const res = await requests.get(`prospects/canvas`);
  return res.data && res.data;
};
export const getMoverData = async (
  location: string,
  radius: number
): Promise<ResponseType<any>> => {
  const res = await requests.get(`prospects/canvas`);
  return res.data && res.data;
};

// export const getLocationOnSearch = async (
//   value: string,
//   ourRequest?: any,
// ): Promise<TAutoComplete> => {
//   const res = await requests.get(
//     `/listings/auto-complete?q=${value}`,
//     {},
//     ourRequest || '',
//   )
//   return res.data && res.data
// }

export const getLocationOnSearch = async (value: string): Promise<TAutoComplete> => {
  const { data } = await axios.get<TMapLocationsRes>(
    `https://map.dev.beewo.com/v1/search?text=${value}&boundary.country=USA`,
    {
      headers: {
        'Accept-Language': 'en-US,en;q=0.9',
      },
    }
  );

  let searchFormat: TLocation[] = [];
  if (data.features.length) {
    searchFormat = data.features
      .filter(
        location =>
          location?.properties?.layer === 'locality' || location?.properties?.layer === 'postalcode'
      )
      .map(mapzenLocationResolver);
  }
  return searchFormat;
};

export const getPlaceGeoJSON = async (
  place_id: string | number,
  layer: string
): Promise<TAutoComplete> => {
  const { data } = await axios.get<TMapLocationsRes>(
    `https://map.dev.beewo.com/v1/place?ids=whosonfirst:${layer}:${place_id}`
  );

  return data.features.map(mapzenLocationResolver);
};

export const postAreaSearch = async (
  body: TPostAreaSearch
): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = '/listings/area-search/listining';
  return requests.post(req, body);
};

export const getDigitalProspects = async (
  body: TPostFYP
): Promise<ResponseType<{ response: string }>> => {
  // const searchParams = new URLSearchParams();
  // body?.bounding_box?.map(box => searchParams.append('bounding_box', String(box)));
  return requests.post(`/listings/digital-farming`, body);
};

export const postFYP = async (body: TPostFYP): Promise<ResponseType<APIFeatureResponseType>> => {
  const req = '/listings/fyp/listining';
  return requests.post(req, body, false, body.ourRequest);
};
// export const getLocationSearchResults = async (query: string) => {
//   const url = `https://nominatim.openstreetmap.org/search?countrycodes=us&format=json&q=Orlando,florida`
//   return fetch(encodeURI(url))
//     .then((res) => res.json())
//     .then((json) => openStreepMapResolver(json))
// }

import React from 'react';

import { Loading } from 'Components';
import { FlexBox } from '@eltoro-ui/components';
import EmptyHistoryAlert from 'Pages/CampaignDashboard/components/EmptyHistoryAlert/EmptyHistoryAlert';
import HistoryAlertSection from 'Pages/CampaignDashboard/components/HistoryAlerts/HistoryAlertSection/HistoryAlertSection';

import { THistoryAlert } from 'types';

import './HistoryAlerts.scss';

type HistoryAlertsProps = {
  isLoading: boolean;
  alerts: THistoryAlert[];
};
const HistoryAlerts = ({ isLoading, alerts }: HistoryAlertsProps) => {
  return (
    <FlexBox UNSAFE_className="HistoryAlerts">
      {isLoading ? (
        <Loading />
      ) : alerts.length ? (
        <HistoryAlertSection alerts={alerts} />
      ) : (
        <EmptyHistoryAlert />
      )}
    </FlexBox>
  );
};

export default HistoryAlerts;

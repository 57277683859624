import type { LinearComponentProps } from 'react-chartjs-2';

export const verticalStackingOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  events: [],
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        stacked: true,
      },
    ],
  },
};

export const multiOverlappingOptions: LinearComponentProps['options'] = {
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 13,
      boxHeight: 13,
      fontColor: '#000000',
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'left',
    },
  },
  scales: {
    xAxes: [{ stacked: true }],
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

// export const multiOverlappingOptions = {
//   legend: {
//     position: 'bottom',
//   },
//   scales: {
//     // xAxes: [{ stacked: true }],
//     // yAxes: [
//     //   {
//     //     stacked: false,
//     //     ticks: {
//     //       beginAtZero: true,
//     //     },
//     //   },
//     // ],
//   },
// }

import { OrderlineStatusEnum } from 'enums';

export const orderlineStatusTagColors = {
  [OrderlineStatusEnum.IN_REVIEW]: { background: '#FFFDEA', color: '#FFCC1B' },
  [OrderlineStatusEnum.REJECTED]: { background: '#FFF0F1', color: '#FF2D38' },
  [OrderlineStatusEnum.APPROVED]: { background: '#EAFFFE', color: '#007B94' },
  [OrderlineStatusEnum.ACTIVE]: { background: '#F1FDE8', color: '#5ABB82' },
  [OrderlineStatusEnum.PAUSED]: { background: '#FFF9ED', color: '#FA8C16' },
  [OrderlineStatusEnum.COMPLETED]: { background: '#F1EAF9', color: '#9747FF' },
};

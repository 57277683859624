import React from 'react';
import moment from 'moment/moment';
import CustomIcons from 'assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem, faUsdCircle, faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { FlexBox, Text } from '@eltoro-ui/components';
import AlertOrderlines from 'Pages/CampaignDashboard/components/HistoryAlerts/HistoryAlertSection/AlertOrderlines';
import { compactNumberConverter } from 'Utils/helpers';
import { THistoryAlert } from 'types';

import './HistoryAlert.scss';

type HistoryAlertItemProps = {
  alert: THistoryAlert;
};
const HistoryAlertItem = ({ alert }: HistoryAlertItemProps) => {
  const {
    listing: { price, address, created_at },
    orderlines,
  } = alert;
  const spent = (p: { name: string; budget: number }[]) => {
    return p.reduce((acc, cur) => acc + cur.budget, 0);
  };
  return (
    <FlexBox
      flexDirection="column"
      alignItems="flex-start"
      gap="24px"
      UNSAFE_className="alert_item"
    >
      <FlexBox alignItems="center" justifyContent="space-between" UNSAFE_className="alert-heading">
        <FlexBox alignItems="center" gap="4px">
          <CustomIcons name="gained" fontSize={24} />
          <Text on="white" UNSAFE_style={{ color: '#317912' }} size="s" tag="span">
            New Listing Won
          </Text>
        </FlexBox>
        <Text on="white" UNSAFE_style={{ color: '#888' }} size="s" tag="span">
          Entered your MLS:{' '}
          <Text on="white" UNSAFE_style={{ color: '#757575' }} tag="span" weight={700}>
            {moment(created_at).format('MM/DD/YYYY')}
          </Text>
        </Text>
      </FlexBox>
      <FlexBox flexDirection="column" gap="12px">
        <FlexBox gap="8px" alignItems="center">
          <FontAwesomeIcon icon={faMapMarkerAlt} fontSize={24} color="#757575" />
          <Text on="white" UNSAFE_style={{ color: '#454545' }}>
            Listing Address:{' '}
            <Text on="white" weight={700} tag="span">
              {address}
            </Text>
          </Text>
        </FlexBox>
        <FlexBox gap="8px" alignItems="center">
          <FontAwesomeIcon icon={faGem} fontSize={24} color="#757575" />
          <Text on="white" UNSAFE_style={{ color: '#454545' }}>
            Listing Value:{' '}
            <Text on="white" weight={700} tag="span">
              {compactNumberConverter(price, 'currency')}
            </Text>
          </Text>
        </FlexBox>
        <FlexBox gap="8px" alignItems="center">
          <FontAwesomeIcon icon={faUsdCircle} fontSize={24} color="#757575" />
          <Text on="white" UNSAFE_style={{ color: '#454545' }}>
            Ad spend:{' '}
            <Text on="white" weight={700} tag="span">
              {compactNumberConverter(spent(orderlines), 'currency')}
            </Text>
          </Text>
        </FlexBox>
      </FlexBox>
      {!!orderlines.length && (
        <>
          <Text on="white" UNSAFE_style={{ color: '#888' }}>
            Ads sent to this address
          </Text>
          <AlertOrderlines orderlines={orderlines} />
        </>
      )}
    </FlexBox>
  );
};

export default HistoryAlertItem;

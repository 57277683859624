import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PieChart } from 'react-minimal-pie-chart';
import { pdf } from '@react-pdf/renderer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom, faHome, faUsdCircle } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox, Text } from '@eltoro-ui/components';
import { useAsync } from '@eltoro-ui/hooks';

import { CampaignDetails, CompactSavedAudiences, Loading, PageHeader } from 'Components';
import CampaignPDF from 'Pages/CampaignView/components/CampaignPDF';
import OrderlinesTable from 'Pages/CampaignView/components/OrderlinesTable';

import { getCampaignDetailsById } from 'Requests/Request_Methods/campaignMethods';

import { compactNumberConverter } from 'Utils/helpers';

import target from 'assets/Images/target1.svg';
import person from 'assets/Images/person.svg';
import clicks from 'assets/Images/clicks.svg';
import impressions from 'assets/Images/impressions.svg';
import ctr from 'assets/Images/ctr.svg';
import totalimpression from 'assets/Images/totalimpression.png';

import type { CampaignCreativeType, CampaignDetailsType, CampaignOrderlineType } from 'types';
import { CampaignCreativeTypeEnum, CampaignOrderlineCreativeTypeEnum } from 'enums';

import './CampaignView.scss';

export const CampaignView = () => {
  const { campaignId } = useParams<{ campaignId: string }>();

  const [reportCardData, setReportCardData] = useState<any>([]);
  const [largeReportCardData, setLargeReportCardData] = useState<any>([]);
  const [summerSale, setSummerSale] = useState<CampaignCreativeType | null>(null);

  const [campaign, setCampaign] = useState<CampaignDetailsType | null>(null);

  const { status, value } = useAsync(() => getCampaignDetailsById(campaignId));

  useEffect(() => {
    const campaign = value?.data;
    if (campaign) {
      setCampaign(campaign);
      setReportCardData([
        {
          icon: clicks,
          title: campaign.clicks,
          subTitle: 'Clicks',
        },
        {
          icon: impressions,
          title: campaign.served_impressions,
          subTitle: 'Impressions',
        },
        {
          icon: ctr,
          title: `${compactNumberConverter(campaign.ctr, 'percent')}`,
          subTitle: 'CTR',
        },
      ]);

      const remainingImps = campaign.total_impressions - campaign.served_impressions;

      setLargeReportCardData([
        {
          icon: faHome,
          title: campaign.total_impressions,
          subTitle: 'Total Impressions',
          detail1: `Served: ${campaign.served_impressions} Impressions`,
          detail2: `Remaining: ${remainingImps < 0 ? 0 : remainingImps} Impressions`,
          servedColor: '#0668c9',
          remainingColor: '#e4f1ff',
          pieChartData: [
            { value: campaign.served_impressions, color: '#0668c9' },
            {
              value: remainingImps < 0 ? 0 : remainingImps,
              color: '#e4f1ff',
            },
          ],
        },
        {
          icon: faUsdCircle,
          title: `$${campaign.total_rate}`,
          subTitle: 'Total Budget',
          detail1: `Spent: $${campaign.served_rate}`,
          detail2: `Remaining: $${campaign.total_rate - campaign.served_rate}`,
          servedColor: '#00ae26',
          remainingColor: '#e2fce8',
          pieChartData: [
            { value: campaign.served_rate, color: '#00ae26' },
            {
              value: campaign.total_rate - campaign.served_rate,
              color: '#e2fce8',
            },
          ],
        },
      ]);

      const summerSale = campaign.orderlines.reduce<CampaignCreativeType>(
        (previousValue, currentValue) => {
          const creativeSale = currentValue.creatives.reduce<CampaignCreativeType>(
            (previousValue, currentValue) => {
              return currentValue.ctr > previousValue.ctr ? currentValue : previousValue;
            },
            previousValue
          );
          return creativeSale.ctr > previousValue.ctr ? creativeSale : previousValue;
        },
        campaign.orderlines[0].creatives[0]
      );

      setSummerSale(summerSale);
    }
  }, [value]);

  const generatePDF = useCallback(async () => {
    if (campaign) {
      const blob = await pdf(<CampaignPDF campaign={campaign} />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${campaign?.name}.pdf`;
      link.click();
    }
  }, [campaign]);

  const [height, setHeight] = React.useState(0);

  const measuredRef = React.useCallback(
    node => {
      if (node !== null) {
        setTimeout(() => {
          setHeight(node.getBoundingClientRect().height);
        }, 100);
      }
    },
    [campaign]
  );

  const orderlines = useMemo<CampaignOrderlineType[]>(() => {
    return (
      campaign?.orderlines.reduce<CampaignOrderlineType[]>((prevValue, currentValue) => {
        return [...prevValue, currentValue];
      }, []) ?? []
    );
  }, [campaign]);

  return (
    <>
      {!value ? (
        <div style={{ height: 'calc(100vh - 132px)', display: 'flex' }}>
          <Loading />
        </div>
      ) : (
        <div className="CampaignView">
          <PageHeader
            UNSAFE_CLASSNAME="CampaignView__header"
            title={campaign?.name}
            subTitle=""
            actions={[
              <Button
                key="link-1"
                kind="primary"
                weight="bold"
                size="l"
                onClick={generatePDF}
                UNSAFE_className="CampaignView__downloadPdf"
                iconLeft={<FontAwesomeIcon icon={faArrowToBottom} />}
              >
                Download PDF
              </Button>,
            ]}
          />
          {!!campaign && status === 'success' && (
            <>
              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '15px 0',
                }}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <div className="campaign-created">
                  <CampaignDetails campaign={campaign} />
                </div>
                <div className="campaign-save_audience">
                  <Text
                    UNSAFE_className="campaign-save-audience-heading"
                    on="grey-050"
                    UNSAFE_style={{
                      display: 'inline-block',
                      marginBottom: '0.8rem',
                    }}
                  >
                    Saved Audience
                  </Text>
                  <div
                    style={{
                      height: 308,
                    }}
                  >
                    {campaign?.orderlines[0]?.targets.length && (
                      <CompactSavedAudiences
                        audiences={campaign?.orderlines[0]?.targets}
                        targetIcon={target}
                        userIcon={person}
                      />
                    )}
                  </div>
                </div>
              </FlexBox>

              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '30px 0',
                }}
                flexDirection="column"
              >
                <Text UNSAFE_className="CampaignView__reportHeading" on="grey-050">
                  Report
                </Text>
                <hr className="divider" />
                <div className="CampaignView__report">
                  {reportCardData.map((data: any, index: number) => (
                    <div key={index} className="report_card">
                      <div className="img_div">
                        <img src={data.icon} alt="clicks" />
                      </div>
                      <div className="title_div">
                        <Text on="grey-050" UNSAFE_className="title">
                          {data.title}
                        </Text>
                        <Text on="grey-050" UNSAFE_className="sub_title">
                          {data.subTitle}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="CampaignView__report">
                  {/* Large size report cards */}
                  {largeReportCardData.map((data: any, index: number) => (
                    <div key={index} className="report_larg_card">
                      <div className="nested_div">
                        <div className="detail_div">
                          <Text on="grey-050" UNSAFE_className="title">
                            {data.title}
                          </Text>
                          <Text on="grey-050" UNSAFE_className="sub_title">
                            {data.subTitle}
                          </Text>
                        </div>
                        <div className="detail_div">
                          <span>
                            <div
                              className="card_dot s_impressions_color"
                              style={{
                                backgroundColor: data.servedColor,
                              }}
                            />
                            <Text on="grey-050" UNSAFE_className="detail1">
                              {data.detail1}
                            </Text>
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              className="card_dot r_impressions_color"
                              style={{
                                backgroundColor: data.remainingColor,
                              }}
                            />

                            <Text on="grey-050" UNSAFE_className="detail2">
                              {data.detail2}
                            </Text>
                          </span>
                        </div>
                      </div>
                      <div style={{ flexGrow: 1.2, textAlign: 'center' }}>
                        <PieChart
                          data={data.pieChartData}
                          lineWidth={40}
                          labelPosition={0}
                          className="report_pie_chart"
                          label={({ dataIndex }) => (
                            <React.Fragment key={dataIndex}>
                              <img src={totalimpression} alt="totalimpression" />
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </FlexBox>
              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '15px 0',
                }}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text size="xxl" on="grey-050" UNSAFE_className="creative-title">
                  Creatives
                </Text>
                <hr className="divider" style={{ marginBottom: '2.5rem' }} />

                <div
                  ref={measuredRef}
                  style={{
                    width: '40%',
                    paddingRight: '1rem',
                    height: 'fit-content',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className="CampaignView__creatives_container"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Text UNSAFE_className="card_title" on="grey-050" size="xl">
                        Summer Sale
                      </Text>
                      <Text UNSAFE_className="sub_title" on="grey-050">
                        Highest performing campaign creative
                      </Text>
                    </div>
                    <div className="" style={{ display: 'flex', marginTop: '2.5rem' }}>
                      <div>
                        {summerSale?.type === CampaignCreativeTypeEnum.CREATIVE_TYPE_BANNER ? (
                          <img
                            src={summerSale?.files?.[0].uri}
                            alt="..."
                            className="creative_img"
                          />
                        ) : (
                          <video className="creative_img">
                            <source src={summerSale?.files?.[0].uri} type="video/mp4" />
                            <track src="captions_en.vtt" kind="captions" label="english_captions" />
                          </video>
                        )}
                      </div>
                      <div className="right_container">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Text UNSAFE_className="card_title" on="grey-050" size="xl">
                            {summerSale?.name.split('__')[1]}
                          </Text>
                          <Text UNSAFE_className="sub_title_heading" on="grey-050">
                            {summerSale?.type === CampaignCreativeTypeEnum.CREATIVE_TYPE_BANNER &&
                            summerSale?.files?.[0].width &&
                            summerSale?.files?.[0].height
                              ? `${
                                  summerSale?.type === CampaignCreativeTypeEnum.CREATIVE_TYPE_BANNER
                                    ? 'Banner'
                                    : 'Video'
                                } - ${summerSale?.files?.[0].width}x${
                                  summerSale?.files?.[0].height
                                }`
                              : summerSale?.type === CampaignCreativeTypeEnum.CREATIVE_TYPE_BANNER
                              ? 'Banner'
                              : 'Video'}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              margin: '5px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img className="small_imgs" src={clicks} alt="..." />
                            <Text UNSAFE_className="des" on="grey-050">
                              Click:{' '}
                              <span className="allCampaignCreatives">{summerSale?.click}</span>
                            </Text>
                          </span>
                          <span
                            style={{
                              margin: '5px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img className="small_imgs" src={impressions} alt="..." />
                            <Text UNSAFE_className="des" on="grey-050">
                              Impression:{' '}
                              <span className="allCampaignCreatives">
                                {summerSale?.impressions}
                              </span>
                            </Text>
                          </span>
                          <span
                            style={{
                              margin: '5px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img className="small_imgs" src={ctr} alt="..." />
                            <Text UNSAFE_className="des" on="grey-050">
                              CTR: <span className="allCampaignCreatives">{summerSale?.ctr}%</span>
                            </Text>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%',
                    paddingLeft: '1rem',
                    height,
                  }}
                >
                  <div className="allCampaignCreatives_box">
                    <Text
                      UNSAFE_style={{ marginLeft: '1rem' }}
                      UNSAFE_className="allCampaignCreatives"
                      on="grey-050"
                    >
                      All Campaign Creatives
                    </Text>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      height: '100%',
                      overflow: 'auto',
                      padding: '0 5px',
                    }}
                  >
                    {campaign?.orderlines.map(orderline => {
                      return orderline.creatives.map((creative: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="CampaignView__creatives_container CampaignView__all_campaign_creatives"
                          >
                            <div>
                              {orderline.creative_type ===
                              CampaignOrderlineCreativeTypeEnum.BANNER ? (
                                <img
                                  style={{ marginRight: 21 }}
                                  src={creative?.files?.[0].uri}
                                  alt="..."
                                  className="creative_img"
                                />
                              ) : (
                                <video className="creative_img" style={{ marginRight: 21 }}>
                                  <source src={creative?.files?.[0].uri} type="video/mp4" />
                                  <track
                                    src="captions_en.vtt"
                                    kind="captions"
                                    label="english_captions"
                                  />
                                </video>
                              )}
                            </div>
                            <div className="right_container">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Text UNSAFE_className="card_title" on="grey-050" size="xl">
                                  {creative?.files?.[0].name.split('__')[1]}
                                </Text>
                                <Text UNSAFE_className="sub_title_heading" on="grey-050">
                                  {orderline.creative_type ===
                                    CampaignOrderlineCreativeTypeEnum.BANNER &&
                                  creative?.files?.[0].width &&
                                  creative?.files?.[0].height
                                    ? `${orderline.creative_type} - ${creative?.files?.[0].width}x${creative?.files?.[0].height}`
                                    : orderline.creative_type}
                                </Text>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 10,
                                }}
                              >
                                <Text UNSAFE_className="des" on="grey-050">
                                  Click:{' '}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                    }}
                                  >
                                    {creative.click}
                                  </span>
                                </Text>
                                <Text UNSAFE_className="des" on="grey-050">
                                  Impression:{' '}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                    }}
                                  >
                                    {creative.impressions}
                                  </span>
                                </Text>
                                <Text UNSAFE_className="des" on="grey-050">
                                  CTR:{' '}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                    }}
                                  >
                                    {creative.ctr}%
                                  </span>
                                </Text>
                              </div>
                            </div>
                          </div>
                        );
                      });
                    })}
                  </div>
                </div>
              </FlexBox>
              <FlexBox
                UNSAFE_style={{
                  background: 'white',
                  padding: '31px 30px',
                  borderRadius: 20,
                  margin: '15px 0',
                }}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text size="xxl" on="grey-050" UNSAFE_className="creative-title">
                  Ads
                </Text>
                <hr className="divider" style={{ marginBottom: '1rem' }} />
                <OrderlinesTable orderlines={orderlines} />
              </FlexBox>
            </>
          )}
        </div>
      )}
    </>
  );
};

import React, { FC } from 'react';
import { Button, FlexBox, Text } from '@eltoro-ui/components';
import './DeleteLabel.scss';
import { usePermissionContext } from 'contexts/PermissionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

type deleteLable = {
  onDelete: () => void;
  deleteItemCounts: number;
  title: string;
  deletable: boolean;
};

export const DeleteLable: FC<deleteLable> = ({ onDelete, deleteItemCounts, title, deletable }) => {
  const { userIsDeactivatedWithActiveOrderlines } = usePermissionContext();
  return (
    <div className="TableSelectionWrapper__selection-header">
      <Text on="white" UNSAFE_className="TableSelectionWrapper__selected-row">
        {`${deleteItemCounts} ${title}${deleteItemCounts > 1 ? 's' : ''} selected`}
      </Text>
      {deletable && (
        <FlexBox alignItems="center">
          <div className="Campaigns__select_divider" />
          <Button
            type="button"
            UNSAFE_className={
              userIsDeactivatedWithActiveOrderlines
                ? 'Campaigns__select-action Campaigns__select-action_disabled'
                : 'Campaigns__select-action'
            }
            onClick={onDelete}
            disabled={userIsDeactivatedWithActiveOrderlines}
            iconLeft={
              <FontAwesomeIcon
                icon={faTrash}
                className="TableSelectionWrapper__selected-row-delete"
              />
            }
          />
        </FlexBox>
      )}
    </div>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { HTMLAttributes } from 'react';

import Tooltip from 'rc-tooltip';

import { useResizeEffect } from 'Hooks';

import { isEllipsisActive } from 'Utils/helpers';

interface EllipsisTextWithTooltipProps extends Pick<HTMLAttributes<HTMLSpanElement>, 'className'> {
  text: string;
}

export default function EllipsisTextWithTooltip({ text, className }: EllipsisTextWithTooltipProps) {
  const textRef = useRef<HTMLSpanElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (textRef.current) {
      setTimeout(() => {
        setDisabled(isEllipsisActive(textRef.current as HTMLElement));
      }, 0);
    }
  }, [textRef.current]);

  const setDisabledOnResize = useCallback(() => {
    if (!textRef.current) return;
    setDisabled(isEllipsisActive(textRef.current as HTMLElement));
  }, [textRef.current]);

  // @ts-ignore
  useResizeEffect(setDisabledOnResize, [textRef]);

  return (
    <Tooltip
      placement="topLeft"
      trigger="hover"
      showArrow
      overlay={text}
      overlayClassName="audience-tooltip creative-name-tooltip"
      getTooltipContainer={() => document.body}
      {...(!disabled ? {} : { visible: false })}
    >
      <span ref={textRef} className={className}>
        {text}
      </span>
    </Tooltip>
  );
}

import React from 'react';
import { useAsyncDebounce } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import { TextInput } from '@eltoro-ui/components';

import './ReactTableSearchBar.scss';

type ReactTableSearchBarType = {
  searchValue: string;
  handleSearchValue: (value: string) => void;
  placeholder?: string;
};

export const ReactTableSearchBar: React.FC<ReactTableSearchBarType> = ({
  placeholder = 'Search',
  searchValue,
  handleSearchValue,
}) => {
  const [value, setValue] = React.useState(searchValue);

  const onChange = useAsyncDebounce(_value => {
    handleSearchValue(_value || '');
  }, 200);
  return (
    <TextInput
      className="react_table_search_bar"
      value={value || ''}
      placeholder={placeholder}
      color="#2B1F0A"
      icon={
        <FontAwesomeIcon icon={faSearch} color="rgba(43, 31, 10, 0.44)" style={{ fontSize: 15 }} />
      }
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

import React from 'react';
import type { ReactNode } from 'react';

import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';

import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';

import './index.scss';

type ConfirmationModalProps = {
  onSubmit: (e: any) => void;
  onCancel: () => void;
  loading?: boolean;
  heading?: string;
  note?: string | ReactNode;
  okText?: string;
  cancelText?: string;
  deletion?: boolean;
};

export const ConfirmationModal = ({
  onSubmit,
  onCancel,
  loading,
  heading = 'Are You sure you want to delete?',
  okText = 'Yes',
  note,
  cancelText = 'Cancel',
  deletion = false,
}: ConfirmationModalProps) => {
  return (
    <Modal offClick={onCancel}>
      <div className="deleteConfirmationModal">
        <img className="rightImage" src={rightGroup} alt="confirmleftcomb" />
        <FlexBox flexDirection="column" alignItems="center" gap="16px">
          <Text UNSAFE_className="deleteConfirmationModal__heading" on="tertiary-300">
            {heading}
          </Text>
          {note && (
            <span className="confirmation-note">
              <span className="note-name">Note: </span>
              <span>{note}</span>
            </span>
          )}
        </FlexBox>
        <div className="deleteConfirmationModal__button">
          <Button kind="default" size="l" weight="bold" onClick={onCancel} disabled={loading}>
            {cancelText}
          </Button>
          <Button
            size="l"
            weight="bold"
            kind={deletion ? 'danger' : 'primary'}
            loading={loading}
            onClick={onSubmit}
            disabled={loading}
          >
            {okText}
          </Button>
        </div>
        <img className="leftImage" src={leftGroup} alt="lconfirmleftcomb" />
      </div>
    </Modal>
  );
};

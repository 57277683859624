import React, { useEffect, useState } from 'react';

import { Button, FlexBox, Text } from '@eltoro-ui/components';

import AudienceCard from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AudienceCard';
import AddAudiencesModal from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AddAudiencesModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TOrderlineDetails, TOrderlineTarget } from 'types';
import { reduceProspects } from 'Utils/helpers';
import { BANNER_CPM, VIDEO_CPM } from 'Utils/constants';

import './EditAudiencesModal.scss';

interface AttachedAudiencesContentProps
  extends Pick<
    TOrderlineDetails,
    | 'targets'
    | 'type'
    | 'total_impressions'
    | 'total_days'
    | 'served_days'
    | 'budget'
    | 'served_impressions'
  > {
  targetAudiences: TOrderlineTarget[];
  handleNewBudget: (cost: number) => void;
  setTargetAudiences: React.Dispatch<React.SetStateAction<TOrderlineTarget[]>>;
  totalProspectCount: number;
  setTotalProspectCount: React.Dispatch<React.SetStateAction<number>>;
}

const AttachedAudiencesContent = ({
  targets,
  targetAudiences,
  type,
  total_impressions,
  total_days,
  served_days,
  handleNewBudget,
  setTargetAudiences,
  served_impressions,
  totalProspectCount,
  setTotalProspectCount,
}: AttachedAudiencesContentProps) => {
  const [openAddNewAudienceModal, setOpenAddNewAudienceModal] = useState(false);
  const CPM = type === 'banner' ? BANNER_CPM : VIDEO_CPM;
  const FREQ = total_impressions / (reduceProspects(targets) * total_days);
  const IMP = FREQ * totalProspectCount * (total_days - served_days);
  const imp_remaining = total_impressions - served_impressions;
  const COST = +((CPM / 1000) * (IMP - imp_remaining)).toFixed(2);
  const handleCloseAddModal = () => {
    setOpenAddNewAudienceModal(false);
  };
  useEffect(() => {
    handleNewBudget(COST);
  }, [COST]);
  const handleUpdatedData = (totalProspects: number, newTargetList: TOrderlineTarget[]) => {
    setTotalProspectCount(totalProspects);
    setTargetAudiences(newTargetList);
  };
  return (
    <div className="attached_audiences_content">
      <FlexBox justifyContent="space-between" alignItems="center" UNSAFE_className="attached_count">
        <FlexBox flexDirection="column">
          <Text
            on="white"
            weight="bold"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 16, paddingBottom: '4px' }}
          >
            Attached Audiences
          </Text>
          <Text on="white" UNSAFE_style={{ fontSize: 14, color: '#757575' }}>
            Add more audiences or replace with ones having equal or more prospects.
          </Text>
        </FlexBox>
        <Text on="white" weight="bold" UNSAFE_style={{ color: '#2B1F0A', fontSize: 16 }}>
          {totalProspectCount} prospect{totalProspectCount > 1 ? 's' : ''}
        </Text>
      </FlexBox>
      <FlexBox
        flexDirection="column"
        gap="0.75rem"
        UNSAFE_style={{ padding: '32px', maxHeight: '350px', overflowY: 'auto' }}
      >
        {targetAudiences.map(target => (
          <AudienceCard key={target.id} target={target} />
        ))}
        <Button
          kind="link"
          size="m"
          weight="bold"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setOpenAddNewAudienceModal(true)}
        >
          Add/Update Audiences
        </Button>
      </FlexBox>

      {openAddNewAudienceModal && (
        <AddAudiencesModal
          targets={targets}
          targetAudiences={targetAudiences}
          onCancel={handleCloseAddModal}
          handleUpdatedData={handleUpdatedData}
        />
      )}
    </div>
  );
};

export default AttachedAudiencesContent;

import { CreativeType, ResponseType, getDataType, TGeneratedBanner, TCreative } from 'types';
import requests from '../http';

export const getCreatives = async (
  skip?: number | null,
  limit?: number | null
): Promise<ResponseType<getDataType<CreativeType[]>>> => requests.get(`/creatives`);

export const getCreativeById = async (creative_id: string): Promise<ResponseType<CreativeType>> => {
  return requests.get(`/creative/${creative_id}`);
};

export const updateCreative = async (
  creative_id: string,
  body: Partial<CreativeType>
): Promise<ResponseType<CreativeType>> => {
  return requests.patch(`/creative/${creative_id}`, body);
};

export const createCreative = async (
  body: Partial<CreativeType>
): Promise<ResponseType<CreativeType>> => requests.post(`/creative`, body);

export const generateMagicCreatives = async (
  data: FormData
): Promise<ResponseType<TGeneratedBanner[]>> =>
  requests.post('/creative/generate_banners', data, true);

export const generatedBannersUse = async (
  data: string[]
): Promise<ResponseType<{ creatives: TCreative[] }>> =>
  requests.post('/creative/use-banners', data);

import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { setUser } from 'Redux/actions';

const defaultValue = {
  userChannel: new BroadcastChannel('user'),
};

type TMultiTabAuthenticationContext = {
  userChannel: BroadcastChannel;
};

type MultiTabAuthenticationContextProviderProps = {
  children: ReactNode;
};

const MultiTabAuthenticationContext = createContext<TMultiTabAuthenticationContext>(defaultValue);

export const useMultiTabAuthenticationContext = () => useContext(MultiTabAuthenticationContext);

export default function MultiTabAuthenticationContextProvider({
  children,
}: MultiTabAuthenticationContextProviderProps) {
  const dispatch = useDispatch();

  const userChannel = new BroadcastChannel('user');

  const userSignOut = () => {
    localStorage.clear();
    dispatch(setUser({}));
  };

  const userChannelListener = () => {
    userChannel.onmessage = msg => {
      if (msg.data === 'SIGN_OUT') userSignOut();
      if (msg.data === 'SIGN_IN') {
        window.location.href = '/dashboard';
      }
    };
  };

  useEffect(() => {
    userChannelListener();
  }, []);

  return (
    <MultiTabAuthenticationContext.Provider value={{ userChannel }}>
      {children}
    </MultiTabAuthenticationContext.Provider>
  );
}

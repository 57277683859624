export enum EMagicGeneratorStep {
  CHOOSE_GOAL = 'Choose Your Goal',
  FILL_DETAILS = 'Fill in the Details',
  USE_BANNERS = 'Use Magic Banners',
}

export const steps = [
  {
    stepIndex: 1,
    label: EMagicGeneratorStep.CHOOSE_GOAL,
    title: 'Choose your campaign goal to achieve the best campaign banner results.',
    submitText: 'Continue',
  },
  {
    stepIndex: 2,
    label: EMagicGeneratorStep.FILL_DETAILS,
    title: 'Provide the required components and Beewo will generate banner ads for your campaign.',
    submitText: 'Generate Magic Banner',
  },
  {
    stepIndex: 3,
    label: EMagicGeneratorStep.USE_BANNERS,
    title: 'Use these magic banners in your campaign.',
    submitText: 'Use Banners',
  },
];

export type StepType = (typeof steps)[number];

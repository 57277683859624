import React, { useRef, useState, useEffect } from 'react';
import type { ChangeEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import { Modal } from '@eltoro-ui/components';
import { useDebouncedCallback } from '@eltoro-ui/hooks';

import type { TLocation } from 'types';

import { toTitleCase } from 'Helpers';

import { getLocationOnSearch } from 'Requests/Request_Methods/prospectMethods';

import './MapSearch.scss';

export const MapSearch = ({ onSelect }: { onSelect: (location: TLocation) => void }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="MapSearch">
      <button type="button" className="MapSearch__button" onClick={() => setIsActive(!isActive)}>
        <FontAwesomeIcon icon={faSearch} />
        <span className="content">Search</span>
      </button>

      {isActive && <MapSearchModal onSelect={onSelect} onClose={() => setIsActive(false)} />}
    </div>
  );
};

export const MapSearchModal = ({
  onSelect,
  onClose,
}: {
  onSelect: (location: TLocation) => void;
  onClose: () => void;
}) => {
  const [searchedText, setSearchedText] = useState('');

  const [suggestions, setSuggestions] = useState<TLocation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => inputRef.current?.focus(), []);

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) =>
    setSearchedText(toTitleCase(value));

  const getLocationsDebounced = useDebouncedCallback(async (query: string) => {
    setLoading(true);
    const results = await getLocationOnSearch(query);
    setSuggestions(results);
    setLoading(false);
  }, 800);

  useEffect(() => {
    if (searchedText.length) {
      getLocationsDebounced(searchedText);
    } else {
      setSuggestions([]);
      setLoading(false);
    }
    return () => {
      setSuggestions([]);
      setLoading(false);
    };
  }, [searchedText]);

  const makeBold = (item: string) => {
    // if(keyword)
    // const res = keyword.toUpperCase()
    const pattern = searchedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const re = new RegExp(pattern, 'ig');
    // return item.replace(re, `<b>${searchedText.toUpperCase()}</b>`)
    return item.replace(re, `<b>${searchedText}</b>`);
  };

  return (
    <Modal className="LocationSearchModal" offClick={onClose}>
      <div className="MapSearch__model-content">
        <div className="MapSearch__search">
          <FontAwesomeIcon icon={faSearch} className="MapSearch__icon" />
          <input
            placeholder="Search for a location"
            ref={inputRef}
            className="MapSearch__input"
            type="text"
            value={searchedText}
            onChange={onChangeSearch}
            maxLength={20}
          />
        </div>
        {/* <Text on="white" kind="subdued" size="xs">
          Search by city, zip, etc.
        </Text> */}
        {loading && searchedText.length > 2 ? (
          <div
            style={{
              textAlign: 'center',
              margin: '2rem',
            }}
          >
            <div className="before-spinner" style={{ position: 'unset' }} />
          </div>
        ) : (
          !!suggestions &&
          suggestions.length > 0 &&
          searchedText.length > 0 && (
            <ul className="MapSearch__suggestions">
              {suggestions.map((suggestion: TLocation, index: number) => (
                <li key={suggestion.key + index}>
                  <button
                    aria-label="suggestion"
                    className="MapSearch__suggestion-button"
                    type="button"
                    onClick={() => {
                      onSelect(suggestion);
                      onClose();
                    }}
                  >
                    <span
                      style={{ textTransform: 'capitalize' }}
                      id={`index${index}`}
                      dangerouslySetInnerHTML={{
                        __html: makeBold(suggestion.display_value),
                      }}
                    />
                  </button>
                </li>
              ))}
            </ul>
          )
        )}
      </div>
    </Modal>
  );
};

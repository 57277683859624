import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import nodata from 'assets/Images/nodata.png';
import { FlexBox, Text } from '@eltoro-ui/components';

import 'Pages/CampaignDashboard/components/Charts/Charts.scss';

type AdTypeProps = {
  data: {
    video: number;
    banner: number;
  };
};
const AdType = ({ data }: AdTypeProps) => {
  const adTypeDataSchema = {
    pieData: [
      { title: 'Banner Ad', value: data?.banner || 0, color: '#007B94' },
      { title: 'Video Ad', value: data?.video || 0, color: '#FFAB03' },
    ],
    context: [
      {
        value: data?.video || 0,
        label: `Video Ad (${data?.video || 0}%)`,
        backgroundColor: '#FFAB03',
      },
      {
        value: data?.banner || 0,
        label: `Banner Ad (${data?.banner || 0}%)`,
        backgroundColor: '#007B94',
      },
    ],
  };
  const adData = Object.values(data).every(value => !value);
  return (
    <div className="adType">
      <Text on="white" UNSAFE_style={{ color: '#757575', paddingBottom: '54px' }} weight="bold">
        Ad Type
      </Text>
      <div className="adType__ad-type">
        <div className="adType__ad-type-text">
          {adTypeDataSchema.context.map(({ value, label, backgroundColor }) => {
            return (
              <FlexBox key={`${label}-${value}`} alignItems="center">
                <div
                  className="adType__ad-box"
                  style={{
                    backgroundColor,
                  }}
                />
                <Text on="white" UNSAFE_className="adType__label">
                  {label}
                </Text>
              </FlexBox>
            );
          })}
        </div>
        {adData ? (
          <div className="noData-ad-type">
            <img style={{ width: '54px' }} src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Ads to Display
            </Text>
          </div>
        ) : (
          <PieChart
            className="adType__pieChart"
            lineWidth={20}
            labelPosition={0}
            data={adTypeDataSchema.pieData}
          />
        )}
      </div>
    </div>
  );
};

export default AdType;

import React from 'react';

import { FlexBox } from '@eltoro-ui/components';

import { useMagicCreativeContext } from 'contexts/MagicCreativeContext';
import MagicGeneratorStepper from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/StepSider/components/MagicGeneratorStepper';

export default function StepSider() {
  const { activeStep, onStepChange } = useMagicCreativeContext();

  return (
    <FlexBox flexDirection="column" gap="42px" UNSAFE_className="creative-generator-modal-sider">
      <FlexBox flexDirection="column" gap="12px">
        <span className="creative-generator-step">Step {activeStep.stepIndex}</span>
        <span className="creative-generator-title">{activeStep.title}</span>
      </FlexBox>
      <MagicGeneratorStepper activeStep={activeStep} onStepChange={onStepChange} />
    </FlexBox>
  );
}

import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';

import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { Button, Text, Modal } from '@eltoro-ui/components';
import { useValidatedState } from '@eltoro-ui/hooks';
import { LoginHeader, LoginFooter } from 'Components';
import { LabelInputField } from 'Components/LabelInputField';
import { ErrorMsg } from 'Pages/PasswordSetup/components/ErrorMsg';

import { emailVerify, restEmailVerify } from 'Requests';

import logo from 'Images/Icon_color@2x.png';
import Warning from 'Images/warning.png';
import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';
import cross from 'assets/Images/cross.png';

import './CreateAcc.scss';
import InfoBarInLoginPage from '../Login/InfoBarInLoginPage';

export const CreateAcc = () => {
  const history = useHistory();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [baseUrl, setBaseUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [email, setEmail, isValidEmail] = useValidatedState('', 'email');
  const [sendingEmailLoading, setSendingEmailLoading] = useState(false);
  const { userStatus } = location.state || {};

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      setBaseUrl(process.env.REACT_APP_URL);
    } else {
      setBaseUrl('http://localhost:3000');
    }
  }, []);

  function sendEmail() {
    setSendingEmailLoading(true);
    if (location.pathname === '/signup') {
      const body = {
        email,
        redirect_link: baseUrl,
      };

      emailVerify(body)
        .then((response: any) => {
          if (response.data.msg === 'Verification email sent!') {
            setModalOpen(true);
            setSendingEmailLoading(false);
            setLoading(false);
          } else {
            setError(true);
            setSendingEmailLoading(false);
            setLoading(false);
          }
        })
        .catch((err: any) => {
          toast.error(err.detail);
          setError(true);
          setSendingEmailLoading(false);
          setLoading(false);
        });
    } else {
      const body = {
        email,
        redirect_url: baseUrl,
      };
      restEmailVerify(body)
        .then((res: any) => {
          if (res.detail) {
            setError(true);
            setSendingEmailLoading(false);
            setLoading(false);
          } else {
            setModalOpen(true);
            setSendingEmailLoading(false);
            setLoading(false);
          }
        })
        .catch(err => {
          toast.error(err.detail);
          setError(true);
          setSendingEmailLoading(false);
          setLoading(false);
        });
    }
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    sendEmail();
  };
  return (
    <div className="loginContainer">
      <LoginHeader />
      {/* login */}

      {/* Modal */}
      {modalOpen && (
        <Modal
          offClick={() => {
            setModalOpen(false);
          }}
        >
          <div
            style={{
              position: 'absolute',
              marginLeft: '27rem',
              marginTop: '1rem',
              zIndex: '999',
            }}
          >
            <img
              src={cross}
              onClick={() => {
                setModalOpen(false);
              }}
              alt="cross"
              role="presentation"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div
            className="deleteConfirmationModal"
            style={{
              width: '470px',
              height: '260px',
              grid: '0rem',
              gridGap: '0rem',
            }}
          >
            <img className="rightImage" src={rightGroup} alt="confirmleftcomb" />
            <Text
              UNSAFE_className="deleteConfirmationModal__heading"
              on="white"
              weight="bold"
              size="xl"
              textAlign="center"
            >
              Email Sent!
            </Text>
            <br />
            <div
              // style={{
              // maxWidth: '280px',
              // margin: '0 30px',
              // display: 'flex',
              // }}
              className="email_has_been_sent"
            >
              <Text on="white" weight="normal" size="m" textAlign="center">
                {location.pathname === '/email-verify' ? (
                  <p
                    style={{
                      color: '#6D6D6D',
                      fontFamily: 'Open Sans',
                      fontWeight: 400,
                    }}
                  >
                    An email has been sent to you providing instructions to reset your password.
                  </p>
                ) : (
                  <p
                    style={{
                      color: '#6D6D6D',
                      fontFamily: 'Open Sans',
                      fontWeight: 400,
                    }}
                  >
                    You should expect an email from us within the next 5 minutes.
                  </p>
                )}
              </Text>
            </div>
            <div
              className={`loginResendMail ${
                sendingEmailLoading ? 'resetPassword__button_disabled' : ''
              }`}
              onClick={() => {
                sendEmail();
              }}
              aria-hidden="true"
            >
              <p style={{ fontWeight: 'bold' }}>Didn&apos;t receive the email?</p>
            </div>
            <img className="leftImage" src={leftGroup} alt="lconfirmleftcomb" />
          </div>
        </Modal>
      )}
      {/* End Modal */}
      <div className="mainLogin">
        <div className="forget_all_content">
          <div className="mainLogin__logo" style={{ marginTop: '27px', marginBottom: '2rem' }}>
            <img src={logo} alt="beewo-logo" className="public-beewo-logo" />
          </div>
          {location.pathname === '/logout' && (
            <div className="Logout__content">
              <b>You’ve Successfully Logged Out</b>
            </div>
          )}
          {location.pathname === '/signup' ? (
            <div style={{ display: 'contents' }}>
              <Text weight="bold" size="xl" on="white" UNSAFE_className="creat_acc_heading">
                Create Account
              </Text>
              <div style={{ textAlign: 'center', padding: '0.8rem 0 0 0' }}>
                <p className="Create_account_description">
                  Enter your email address and we&apos;ll send you a confirmation link
                </p>
              </div>
            </div>
          ) : (
            <div style={{ display: 'contents' }}>
              {userStatus && <InfoBarInLoginPage status={userStatus} />}
              <Text weight="bold" size="xl" on="white" UNSAFE_className="creat_acc_heading">
                Forgot Password?
              </Text>
              <div style={{ textAlign: 'center' }}>
                <p className="Create_account_description">
                  Enter your email to recieve a password reset.
                </p>
              </div>
            </div>
          )}
          <br />
          <div className="Login__form">
            <form onSubmit={onSubmit}>
              <LabelInputField
                title="Email Address"
                placeholder="Enter your email address"
                type="email"
                onChange={e => {
                  setLoading(false);
                  setError(false);
                  setEmail(e.target.value);
                }}
                isError={!isValidEmail}
                iconLeft={<FontAwesomeIcon icon={faEnvelope} style={{ color: '#b3b0b0' }} />}
                iconRight={
                  error ? (
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={Warning}
                      alt="icon"
                    />
                  ) : null
                }
              />
              {isValidEmail ? (
                ''
              ) : (
                <ErrorMsg
                  title="Invalid email"
                  icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
              )}
              <br />
              <Button
                type="submit"
                kind="primary"
                size="l"
                UNSAFE_className="sendVerification"
                disabled={!isValidEmail || !email || loading}
                fonts="16px"
                weight="bold"
                width="100%"
                data_testid="submit_btn_verification"
                UNSAFE_style={{ justifyContent: 'center' }}
                loading={loading && sendingEmailLoading}
              >
                {location.pathname === '/signup'
                  ? 'Send Verification Email'
                  : 'Send Password Recovery'}
              </Button>
              {location.pathname === '/signup' ? (
                <div>
                  {' '}
                  <div className="crtAccOr">
                    <Text weight="bold" size="m" on="white">
                      Or
                    </Text>
                  </div>
                  <Button
                    UNSAFE_style={{
                      borderRadius: '10px',
                      padding: '26px 0',
                    }}
                    type="button"
                    kind="default"
                    size="l"
                    weight="bold"
                    width="100%"
                    data_testid="submit_btn"
                    // UNSAFE_style={{ height: '64px' }}
                    onClick={() => history.push('/login')}
                  >
                    Sign in
                  </Button>
                </div>
              ) : (
                ''
              )}
              <div className="loginBottom">
                <p>Having issues with your account? </p>
                <p>
                  Contact{' '}
                  <Link
                    to="/login"
                    className="loginBottomHelp"
                    style={{ paddingRight: '4px', color: '#FFAB03' }}
                  >
                    help@beewo.com
                  </Link>
                  for support
                </p>
              </div>

              {/* Go Back */}
              {location.pathname !== '/signup' ? (
                <div style={{ position: 'relative' }}>
                  <div
                    className="onboardingBack"
                    onClick={() => {
                      history.goBack();
                    }}
                    aria-hidden="true"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} color="#FFAB03" />
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: '#FFAB03',
                      }}
                    >
                      Go Back
                    </p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </form>
          </div>
        </div>
      </div>
      {/* End login */}
      <LoginFooter />
    </div>
  );
};

import React, { createContext, useContext, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

import type { Area } from 'react-easy-crop';
import type { Point } from 'react-easy-crop/types';

const initialCrop = { x: 0, y: 0 };
const initialCroppedAreaPixels = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

const initialSetter = () => undefined;

type CropImageModalContextType = {
  crop: [Point, Dispatch<SetStateAction<Point>>];
  zoom: [number, Dispatch<SetStateAction<number>>];
  croppedAreaPixels: [Area, Dispatch<SetStateAction<Area>>];
  resetStates: VoidFunction;
};

const CropImageModalContext = createContext<CropImageModalContextType>({
  crop: [initialCrop, initialSetter],
  zoom: [1, initialSetter],
  croppedAreaPixels: [initialCroppedAreaPixels, initialSetter],
  resetStates: () => {},
});

export const useCropImageModalContext = () => useContext(CropImageModalContext);

interface CropImageModalContextProviderProps {
  children: ReactNode;
}

export default function CropImageModalContextProvider({
  children,
}: CropImageModalContextProviderProps) {
  const [crop, setCrop] = useState(initialCrop);
  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(initialCroppedAreaPixels);

  const resetStates = () => {
    setZoom(1);
    setCrop(initialCrop);
  };

  return (
    <CropImageModalContext.Provider
      value={{
        crop: [crop, setCrop],
        zoom: [zoom, setZoom],
        croppedAreaPixels: [croppedAreaPixels, setCroppedAreaPixels],
        resetStates,
      }}
    >
      {children}
    </CropImageModalContext.Provider>
  );
}

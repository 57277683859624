import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { deleteCampaign, getCampaigns } from 'Requests';
import { Button } from '@eltoro-ui/components';

import { usePermissionContext } from 'contexts/PermissionContext';

import { EmptyStatus, PageHeader, ReactTable, ReactTableSearchBar } from 'Components';
import RenameOrderlineModal from 'Components/RenameOrderlineModal';
import DeactivateInfo from 'Pages/MyProfile/components/DeactivateAccount/components/DeactivateInfo';
import CampaignsList from 'Pages/Orderlines/CampaignsList';

import { getOrderlineColumns, redirectCampaign, redirectOrderline } from 'Pages/Orderlines/helpers';

import { getOrderlines, getTotalCounts } from 'Requests/Request_Methods/campaignMethods';

import { Tabs } from 'enums';
import type { TCampaign, TOrderline, TResPagination } from 'types';

import './Orderlines.scss';

export default function Orderlines() {
  const { flagsReady } = useFlagsStatus();
  const orderlineViewFlag = useFlag('orderline-view');
  const location = useLocation();
  const history = useHistory();
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed, userIsActive } =
    usePermissionContext();

  const [campaigns, setCampaigns] = useState<TCampaign[]>([]);
  const [orderlines, setOrderlines] = useState<TOrderline[]>([]);
  const [totals, setTotals] = useState({ orderine: 0, camapign: 0 });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteCampaignData, setDeleteCampaignData] = useState<TCampaign[]>([]);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [campaignPage, setCampaignPage] = useState<number>(1);
  const [orderlinePage, setOrderlinePage] = useState<number>(1);
  const [campaignPagination, setCampaignPagination] = useState<TResPagination>({
    total_count: 0,
    total_pages: 0,
  });
  const [orderlinePagination, setOrderlinePagination] = useState<TResPagination>({
    total_count: 0,
    total_pages: 0,
  });
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [currentOrderline, setCurrentOrderline] = useState<null | TOrderline>(null);

  const [searchValue, setSearchValue] = useState('');

  const filter = new URLSearchParams(location.search).get('filter');
  const searchPlaceholder =
    location.pathname.includes(Tabs.ORDERLINES) && !filter
      ? 'Search by orderline'
      : 'Search by campaign';

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    if (location.pathname.includes(Tabs.ORDERLINES) && !filter) {
      setOrderlinePage(1);
    } else {
      setCampaignPage(1);
    }
  };
  const _fetchCampaignData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getCampaigns(campaignPage, 50, searchValue, null);
      if (res.data) {
        const { total_campaigns, total_pages, campaigns } = res.data;
        setCampaigns(campaigns);
        setCampaignPagination({ total_count: total_campaigns, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  const _fetchTabsTotalCounts = async () => {
    const res = await getTotalCounts();
    if (res.data)
      setTotals({ orderine: res.data.orderline_count, camapign: res.data.campaign_count });
  };
  const _fetchOrderlineData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getOrderlines(orderlinePage, 50, searchValue, null);
      if (res.data) {
        const { total_orderlines, total_pages, orderlines } = res.data;
        setOrderlines(orderlines);
        setOrderlinePagination({ total_count: total_orderlines, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail || 'Something went wrong!');
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    _fetchTabsTotalCounts();
    if (location.pathname.includes(Tabs.ORDERLINES) && filter) {
      _fetchCampaignData();
    } else {
      _fetchOrderlineData();
    }
  }, [
    campaignPage,
    orderlinePage,
    location.pathname,
    filter,
    location.state?.refreshData,
    searchValue,
  ]);

  const onDelete = async (_items: TCampaign[]) => {
    setDeleteIsLoading(true);
    const ids = _items.map((item: any) =>
      deleteCampaignData.length ? item?.id : item?.original?.id
    );
    try {
      const { data } = await deleteCampaign({ campaign_id: ids });
      if (data?.length)
        toast.success(`${data.length} Campaign${data.length > 1 ? 's' : ''} Deleted`);
      if (deleteCampaignData.length) {
        setDeleteCampaignData([]);
      } else {
        setDeleteModalIsOpen(false);
      }

      setDeleteIsLoading(false);

      if (campaignPage === 1) await _fetchCampaignData();
      else await setCampaignPage(1);
    } catch (err: any) {
      toast.error(err.detail);
      setDeleteIsLoading(false);
    }
  };

  const handleOpenRenameModal = (open: boolean, orderline: TOrderline | null) => {
    setRenameModalOpen(open);
    setCurrentOrderline(orderline);
  };

  const changeTab = () => {
    if (location.pathname.includes(Tabs.ORDERLINES) && filter) {
      setCampaignPage(1);
      history.push('/orderlines');
    } else {
      setOrderlinePage(1);
      history.push('/orderlines?filter=campaigns');
    }
    setSearchValue('');
  };
  const updateCreativeAfterEditOrderlineName = ({
    orderline_uuid,
    name,
  }: Pick<TOrderline, 'name' | 'orderline_uuid'>) =>
    setOrderlines(orderlines =>
      orderlines.map(orderline =>
        orderline.orderline_uuid === orderline_uuid ? { ...orderline, name } : { ...orderline }
      )
    );

  const changeRoutePath = (actionItem: TCampaign | TOrderline) => {
    if ('step' in actionItem) {
      history.push(redirectCampaign(actionItem));
    }
    if ('orderline_uuid' in actionItem) {
      history.push(redirectOrderline(actionItem));
    }
  };

  return (
    <div className="Campaigns">
      <PageHeader
        UNSAFE_CLASSNAME="Campaigns__PageHeader"
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span className="MyListings__totalCounts">
            You have{' '}
            {location.pathname.includes(Tabs.ORDERLINES) && filter ? (
              <b>
                {campaignPagination.total_count} campaign
                {campaignPagination.total_count > 1 ? 's' : ''}
              </b>
            ) : (
              <b>
                {orderlinePagination.total_count} orderline
                {orderlinePagination.total_count > 1 ? 's' : ''}
              </b>
            )}
          </span>
        }
        actions={[
          <ReactTableSearchBar
            key={searchPlaceholder}
            searchValue={searchValue}
            handleSearchValue={handleSearchValue}
            placeholder={searchPlaceholder}
          />,
          <Button
            key="new-campaign"
            kind="primary"
            to="?action=create-campaign"
            replace
            size="l"
            weight="bold"
            disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
          >
            New Campaign
          </Button>,
        ]}
      />
      <DeactivateInfo />
      {flagsReady && orderlineViewFlag && (
        <div className="table-tabs">
          <button
            onClick={changeTab}
            className={
              location.pathname.includes(Tabs.ORDERLINES) && !filter ? 'active' : 'inactive'
            }
          >
            Orderlines ({totals.orderine})
          </button>
          <button onClick={changeTab} className={filter === Tabs.CAMPAIGNS ? 'active' : 'inactive'}>
            Campaigns ({totals.camapign})
          </button>
        </div>
      )}

      {location.pathname.includes(Tabs.ORDERLINES) && !filter ? (
        <>
          <ReactTable<TOrderline>
            title="orderline"
            loading={fetchDataIsLoading}
            emptyText={
              <EmptyStatus
                heading={
                  searchValue && !orderlines.length
                    ? 'No results found'
                    : 'You don’t have any orderlines yet.'
                }
                subHeading={
                  searchValue && !orderlines.length
                    ? 'You might consider trying different keywords, double-checking for any spelling errors, or adjusting your filters.'
                    : 'Your orderline list will appear here once you create campaigns.'
                }
              />
            }
            data={orderlines}
            pageSize={50}
            selectAllFeature={false}
            selectFeature={false}
            totalCount={orderlinePagination.total_count}
            pageCount={orderlinePagination.total_pages}
            currentPage={orderlinePage}
            setPage={setOrderlinePage}
            columns={getOrderlineColumns(userIsActive, handleOpenRenameModal, changeRoutePath)}
          />
          {renameModalOpen && currentOrderline && (
            <RenameOrderlineModal
              orderline_uuid={currentOrderline.orderline_uuid}
              name={currentOrderline.name}
              onSuccess={async name => {
                await updateCreativeAfterEditOrderlineName({
                  orderline_uuid: currentOrderline.orderline_uuid,
                  name,
                });
              }}
              onCancel={() => handleOpenRenameModal(false, null)}
            />
          )}
        </>
      ) : (
        <CampaignsList
          campaigns={campaigns}
          fetchDataIsLoading={fetchDataIsLoading}
          campaignPagination={campaignPagination}
          onDelete={onDelete}
          deleteModalIsOpen={deleteModalIsOpen}
          setDeleteModalIsOpen={setDeleteModalIsOpen}
          deleteIsLoading={deleteIsLoading}
          campaignPage={campaignPage}
          setCampaignPage={setCampaignPage}
          changeRoutePath={changeRoutePath}
          searchValue={searchValue}
        />
      )}
    </div>
  );
}

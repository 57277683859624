import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { ChromePicker } from 'react-color';
import type { ColorChangeHandler, RGBColor } from 'react-color';

import CustomIcons from 'assets/icons';

import './Picker.scss';

interface PickerProps {
  isSelected: boolean;
  color: RGBColor;
  onChangeColorPicker: ColorChangeHandler;
}

export default function Picker({ isSelected, color, onChangeColorPicker }: PickerProps) {
  const ref = useRef<ChromePicker>(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    if (displayColorPicker) {
      if (ref.current) {
        // @ts-ignore
        ref.current.handleChange(color);
      }
    }
  }, [displayColorPicker]);

  return (
    <div className={classNames('color-field-wrapper', { selected: isSelected })}>
      <button
        aria-label="color-picker"
        className="color-field"
        style={{ background: `rgb(${color.r}, ${color.g}, ${color.b})` }}
        onClick={() => setDisplayColorPicker(true)}
      />
      <CustomIcons name="color_picker" fontSize={24} className="color-picker-icon" />
      {displayColorPicker && (
        <div className="popover">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="cover" onClick={handleClose} />
          <ChromePicker ref={ref} disableAlpha color={color} onChange={onChangeColorPicker} />
        </div>
      )}
    </div>
  );
}

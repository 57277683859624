import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Loading } from 'Components';
import { Button, FlexBox } from '@eltoro-ui/components';

import { CampaignPerformance } from 'Pages/CampaignDashboard/components/CampaignPerformance';
import AdType from 'Pages/CampaignDashboard/components/Charts/AdType/AdType';
import Ads from 'Pages/CampaignDashboard/components/Charts/Ads/Ads';
import HistoryAlerts from 'Pages/CampaignDashboard/components/HistoryAlerts/HistoryAlerts';
import DeactivateInfo from 'Pages/MyProfile/components/DeactivateAccount/components/DeactivateInfo';

import { usePermissionContext } from 'contexts/PermissionContext';
import { getAllOrderlinesStats } from 'Requests/Request_Methods/campaignMethods';
import { getAlerts } from 'Requests/Request_Methods/orderlineMethods';

import type { AllOrderlinesStats, THistoryAlert } from 'types';

import './CampaignDashboard.scss';

export const CampaignDashboard = () => {
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  const [statsData, setStatsData] = useState<AllOrderlinesStats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alerts, setAlerts] = useState<THistoryAlert[]>([]);

  const getHistoryAlerts = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAlerts();
      if (data) setAlerts(data);
    } catch (e) {
      if (e.detail) toast.error(e.detail);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistoryAlerts();
  }, []);

  useEffect(() => {
    getAllOrderlinesStats()
      .then(({ data }) => {
        if (data) setStatsData(data);
      })
      .catch(err => {
        if ('detail' in err) {
          toast.error(err.detail);
        } else {
          toast.error('Something went wrong!');
        }
      });

    return () => {
      setStatsData(null);
    };
  }, []);

  return (
    <div className="CampaignDashboard">
      <DeactivateInfo />
      {!statsData ? (
        <Loading />
      ) : (
        <div className="CampaignDashboard__main_div">
          {!!alerts?.length && (
            <FlexBox justifyContent="flex-end" UNSAFE_style={{ paddingBottom: '16px' }}>
              <Button
                key="new-campaign"
                kind="primary"
                weight="bold"
                to="?action=create-campaign"
                replace
                UNSAFE_style={{
                  justifyContent: 'center',
                }}
                size="l"
                disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
              >
                New Campaign
              </Button>
            </FlexBox>
          )}
          <div className="CampaignDashboard__chart_div">
            <div className="CampaignDashboard__left_main_col">
              <div className="CampaignDashboard__CampaignPerformance">
                <CampaignPerformance />
              </div>
            </div>
            <div className="CampaignDashboard__right_main_col">
              <AdType
                data={{
                  video: statsData.video_orderlines_percentage,
                  banner: statsData.banner_orderlines_percentage,
                }}
              />
              <Ads
                data={{
                  inReview: statsData['In Review'],
                  active: statsData.Active,
                  completed: statsData.Completed,
                }}
              />
            </div>
          </div>
          <HistoryAlerts isLoading={isLoading} alerts={alerts} />
        </div>
      )}
    </div>
  );
};

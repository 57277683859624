import React, { useState } from 'react';

import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';

import HeadingContent from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AddAudiencesModal/HeadingContent';
import AudienceTable from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AddAudiencesModal/AudienceTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { TAudience, TOrderlineDetails, TOrderlineTarget } from 'types';

import { reduceProspects } from 'Utils/helpers';

interface AddAudiencesModalProps extends Pick<TOrderlineDetails, 'targets'> {
  onCancel: () => void;
  handleUpdatedData: (total: number, newTargets: TOrderlineTarget[]) => void;
  targetAudiences: TOrderlineTarget[];
}
const AddAudiencesModal = ({
  targets,
  onCancel,
  handleUpdatedData,
  targetAudiences,
}: AddAudiencesModalProps) => {
  const previousSelectedProspectCount = reduceProspects(targetAudiences);

  const [newSelectedProspectCount, setNewSelectedProspectCount] = useState(
    previousSelectedProspectCount
  );
  const [selectedAudiences, setSelectedAudiences] = useState<TAudience[]>(targetAudiences);
  const handleNewSelectedProspectCount = (newList: TAudience[]) => {
    setNewSelectedProspectCount(() => reduceProspects(newList));
  };

  const onConfirm = () => {
    handleUpdatedData(newSelectedProspectCount, selectedAudiences as TOrderlineTarget[]);
    onCancel();
  };

  return (
    <Modal closable={false} className="budget_adv_control_modal">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        gap="46px"
        UNSAFE_style={{ width: '900px', padding: '32px', overflow: 'hidden' }}
        UNSAFE_className="budget_wrapper"
      >
        <Button onClick={onCancel} UNSAFE_className="budget_cross">
          <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer', fontSize: '24px' }} />
        </Button>
        <FlexBox
          flexDirection="column"
          UNSAFE_style={{ width: '100%', position: 'relative', overflow: 'hidden' }}
          gap="20px"
        >
          <Text
            on="white"
            weight="bold"
            textAlign="center"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 24, margin: '1.5rem 0 1rem' }}
          >
            Add Audiences
          </Text>
          <HeadingContent
            currentProspectCount={reduceProspects(targets)}
            selectedProspectCount={newSelectedProspectCount}
          />
          <AudienceTable
            selectedAudiences={selectedAudiences}
            alreadySelected={targetAudiences}
            handleNewSelectedProspectCount={handleNewSelectedProspectCount}
            setSelectedAudiences={setSelectedAudiences}
          />
          <div className="confirm-modal-footer">
            <Button size="l" onClick={onCancel} weight="bold">
              Cancel
            </Button>
            <Button
              kind="primary"
              size="l"
              weight="bold"
              onClick={onConfirm}
              loading={false}
              disabled={reduceProspects(targets) > newSelectedProspectCount}
            >
              Add {selectedAudiences.length > 0 ? selectedAudiences.length : ''} audience
              {selectedAudiences.length > 1 ? 's' : ''}
            </Button>
          </div>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default AddAudiencesModal;

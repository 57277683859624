import React from 'react';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faScrubber } from '@fortawesome/pro-regular-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import {
  StepType,
  steps,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/constants';

const renderStepIcon = (active: boolean, checked: boolean, index: number) => {
  if (active) return <FontAwesomeIcon icon={faScrubber} />;
  if (checked) return <FontAwesomeIcon icon={faCheckCircle} />;

  return (
    <FlexBox alignItems="center" justifyContent="center" UNSAFE_className="step-item-step-index">
      0{index + 1}
    </FlexBox>
  );
};

interface MagicGeneratorStepperProps {
  // steps: StepType[];
  activeStep: StepType;
  onStepChange: (step: StepType) => void;
}

export default function MagicGeneratorStepper({
  activeStep,
  onStepChange,
}: MagicGeneratorStepperProps) {
  return (
    <FlexBox flexDirection="column" gap="8px" UNSAFE_className="magic-creative-stepper">
      {steps.map((step, index, steps) => {
        const active = activeStep.label === step.label;
        const checked =
          steps.findIndex(({ label }) => label === step.label) < activeStep.stepIndex - 1;

        return (
          <FlexBox
            key={step.label}
            flexDirection="column"
            gap="8px"
            UNSAFE_className={classNames('step-item', {
              'step-item-active': active,
              'step-item-checked': checked,
            })}
          >
            <button
              className="step-item-header"
              style={{ cursor: checked ? 'pointer' : 'initial' }}
              onClick={() => (checked ? onStepChange(step) : undefined)}
            >
              {renderStepIcon(active, checked, index)}
              <span className="step-item-label">{step.label}</span>
            </button>
            {steps.length - 1 !== index && (
              <FlexBox justifyContent="center" UNSAFE_className="step-item-separator">
                <div className="step-item-separator-divider" />
              </FlexBox>
            )}
          </FlexBox>
        );
      })}
    </FlexBox>
  );
}

import React from 'react';

import classnames from 'classnames';

import { FlexBox } from '@eltoro-ui/components';

import CustomIcons from 'assets/icons';

import { CampaignGoalType } from 'Utils/constants';

import './CampaignGoalCard.scss';

interface CampaignGoalCardProps {
  campaignGoal: CampaignGoalType;
  onSelectGoal: (goal: CampaignGoalType) => void;
  checked: boolean;
}

export default function CampaignGoalCard({
  campaignGoal,
  onSelectGoal,
  checked,
}: CampaignGoalCardProps) {
  return (
    <FlexBox
      alignItems="center"
      gap="1.5rem"
      UNSAFE_className={classnames('campaign-gaol-card', {
        checked,
      })}
    >
      <button
        onClick={() => onSelectGoal(campaignGoal)}
        className={classnames('campaign-goal-radio-button', {
          checked,
        })}
      >
        {checked && <div className="campaign-goal-radio-button-inner" />}
      </button>
      <CustomIcons name={campaignGoal.icon} fontSize={32} />
      <FlexBox flexDirection="column" gap="0.5rem">
        <span className="campaign-gaol-card-name">{campaignGoal.name}</span>
        <span className="campaign-gaol-card-description">{campaignGoal.description}</span>
      </FlexBox>
    </FlexBox>
  );
}

import React from 'react';

import { FlexBox } from '@eltoro-ui/components';

import CampaignGoalCard from 'Pages/CampaignCreation/components/CampaignCreateModal/components/CampaignGoalCard';

import { useMagicCreativeContext } from 'contexts/MagicCreativeContext';

import { CampaignGoals } from 'Utils/constants';

export default function ChooseGoal() {
  const { selectedGoal, onSelectGoal } = useMagicCreativeContext();

  return (
    <FlexBox flexDirection="column" gap="24px">
      {CampaignGoals.map(goal => (
        <CampaignGoalCard
          key={goal.name}
          campaignGoal={goal}
          onSelectGoal={onSelectGoal}
          checked={selectedGoal.name === goal.name}
        />
      ))}
    </FlexBox>
  );
}

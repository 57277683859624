import type { Dispatch, SetStateAction } from 'react';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import toast from 'react-hot-toast';
import moment from 'moment';

import { Menu } from 'primereact/menu';
import type { MenuItem } from 'primereact/menuitem';
import type { ButtonProps } from 'primereact/button';
import { Button as PrimeButton } from 'primereact/button';

import RenameOrderlineModal from 'Components/RenameOrderlineModal';
import OrderlineActionModal from 'Pages/OrderlineDetails/components/OrderlineHeader/components/OrderlineActions/components/OrderlineActionModal/OrderlineActionModal';
import { orderlineActionItems } from 'Pages/OrderlineDetails/constants';
import EditFlightDatesModal from 'Pages/OrderlineDetails/components/EditFlightDatesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

import { OrderlineActionsEnum, OrderlineStatusEnum } from 'enums';
import type { FlightMutableDataType, TOrderlineDetails } from 'types';
import { formatFromUTC } from 'Utils/helpers';

import { pauseOrderline, playOrderline } from 'Requests/Request_Methods/orderlineMethods';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

interface OrderlineActionsProps
  extends Pick<
    TOrderlineDetails,
    | 'name'
    | 'status'
    | 'start_date'
    | 'end_date'
    | 'total_days'
    | 'total_impressions'
    | 'served_days'
    | 'served_impressions'
    | 'type'
    | 'targets'
  > {
  setOrderline: Dispatch<SetStateAction<TOrderlineDetails | null>>;
  getOrderline: () => Promise<void>;
  generatePDF: () => Promise<void>;
  updateOrderlineData: () => void;
}

export default function OrderlineActions({
  name,
  status,
  end_date,
  start_date,
  total_days,
  setOrderline,
  getOrderline,
  generatePDF,
  served_days,
  targets,
  type,
  total_impressions,
  served_impressions,
  updateOrderlineData,
}: OrderlineActionsProps) {
  const { flagsReady } = useFlagsStatus();
  const editOrderlineFlag = useFlag('edit-orderline');
  const { orderlineId } = useParams<{ orderlineId: string }>();
  const history = useHistory();
  const initialFlightDateData = {
    startDate: moment(start_date).toDate(),
    endDate: moment(end_date).toDate(),
    currentDays: total_days - served_days,
    minDays: total_days - served_days,
    days: total_days - served_days,
  };

  const [orderlineActionLoading, setOrderlineActionLoading] = useState(false);
  const [editFlightDate, setEditFlightDate] = useState(false);

  const [flightMutableData, setFlightMutableData] =
    useState<FlightMutableDataType>(initialFlightDateData);

  const [renameOrderlineModalOpen, setRenameOrderlineModalOpen] = useState(false);
  const [activeOrderlineAction, setActiveOrderlineAction] = useState<
    OrderlineActionsEnum.RESUME | OrderlineActionsEnum.PAUSE | null
  >(null);

  const orderlineActionRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);

  const actionsCommand = {
    [OrderlineActionsEnum.CONTACT_SUPPORT]: () => history.push('/profile/notifications'),
    [OrderlineActionsEnum.RESUME]: () => setActiveOrderlineAction(OrderlineActionsEnum.RESUME),
    [OrderlineActionsEnum.PAUSE]: () => setActiveOrderlineAction(OrderlineActionsEnum.PAUSE),
    [OrderlineActionsEnum.RENAME]: () => setRenameOrderlineModalOpen(true),
    [OrderlineActionsEnum.DOWNLOAD_PDF]: () => generatePDF(),
    [OrderlineActionsEnum.EDIT_DATES]: () => setEditFlightDate(true),
  };

  const toggleOrderlineActionSelect: ButtonProps['onClick'] = event => {
    if (orderlineActionRef && orderlineActionRef.current) {
      orderlineActionRef.current.toggle(event);
    }
  };

  const items: MenuItem[] = orderlineActionItems
    .filter(action => {
      if (!(flagsReady && editOrderlineFlag) && action.label === OrderlineActionsEnum.EDIT_DATES) {
        return false;
      }
      if (action.accessStatuses.includes(status) && status === OrderlineStatusEnum.PAUSED) {
        if (action.flightDatePassed === undefined) return true;
        if (moment(end_date).isBefore(moment())) return action?.flightDatePassed;
        return !action?.flightDatePassed;
      }

      return action.accessStatuses.includes(status);
    })
    .map(({ accessStatuses, ...item }) => ({
      ...item,
      command: actionsCommand[item.label],
    }));

  const updateOrderlineAfterSuccessAction = () => getOrderline();

  const onCloseActionModal = () => setActiveOrderlineAction(null);

  const onConfirmOrderlineAction = async () => {
    try {
      setOrderlineActionLoading(true);
      const { data } = await (activeOrderlineAction === OrderlineActionsEnum.PAUSE
        ? pauseOrderline
        : playOrderline)(orderlineId);

      if (data) {
        toast.success(data.message);
        onCloseActionModal();
        updateOrderlineAfterSuccessAction();
      }
    } catch (e) {
      if (e.detail) toast.error(e.detail);
      onCloseActionModal();
    } finally {
      setOrderlineActionLoading(false);
    }
  };

  const onCancelEditFlightDate = () => {
    setFlightMutableData(initialFlightDateData);
    setEditFlightDate(false);
  };

  return (
    <div ref={menuRootWrapperRef} className="orderline-actions-wrapper">
      <Menu
        className="orderline-actions-menu"
        ref={orderlineActionRef}
        popup
        id="popup_menu_right"
        popupAlignment="right"
        model={items}
      />
      <PrimeButton
        className="orderline-action-btn"
        icon={<FontAwesomeIcon icon={faEllipsisV} color="#757575" style={{ fontSize: 20 }} />}
        onClick={toggleOrderlineActionSelect}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
      {activeOrderlineAction && (
        <OrderlineActionModal
          action={activeOrderlineAction}
          onCancel={onCloseActionModal}
          onConfirm={onConfirmOrderlineAction}
          loading={orderlineActionLoading}
        />
      )}
      {renameOrderlineModalOpen && (
        <RenameOrderlineModal
          orderline_uuid={orderlineId}
          name={name}
          onSuccess={orderlineName => {
            setOrderline(orderline => {
              if (!orderline) return orderline;
              return { ...orderline, name: orderlineName };
            });
          }}
          onCancel={() => setRenameOrderlineModalOpen(false)}
        />
      )}
      {editFlightDate && (
        <EditFlightDatesModal
          onCancel={onCancelEditFlightDate}
          total_days={total_days}
          flightMutableData={flightMutableData}
          start_date={start_date}
          end_date={end_date}
          setFlightMutableData={setFlightMutableData}
          total_impressions={total_impressions}
          served_impressions={served_impressions}
          type={type}
          targets={targets}
          orderlineId={orderlineId}
          updateOrderlineData={updateOrderlineData}
        />
      )}
    </div>
  );
}

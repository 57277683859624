import React, { useState } from 'react';

import { FlexBox } from '@eltoro-ui/components';

import OrderlineDetailsContainer from 'Pages/OrderlineDetails/components/OrderlineDetailsContainer';
import AudienceCard from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AudienceCard';

import type { TOrderlineDetails } from 'types';
import EditAudiencesModal from 'Pages/OrderlineDetails/components/AttachedAudiences/components/EditAudiencesModal';

interface AttachedAudiencesProps
  extends Pick<
    TOrderlineDetails,
    | 'targets'
    | 'status'
    | 'type'
    | 'total_impressions'
    | 'total_days'
    | 'served_days'
    | 'budget'
    | 'served_impressions'
  > {
  orderlineId: string;
  updateOrderlineData: () => void;
}

export default function AttachedAudiences({
  targets,
  status,
  orderlineId,
  type,
  total_impressions,
  total_days,
  served_days,
  budget,
  served_impressions,
  updateOrderlineData,
}: AttachedAudiencesProps) {
  const [openEditAudiencesModal, setOpenEditAudiencesModal] = useState(false);
  const handleOpenEditModal = (open: boolean) => {
    setOpenEditAudiencesModal(open);
  };
  return (
    <OrderlineDetailsContainer
      title="Attached Audiences"
      handleOpenEditModal={handleOpenEditModal}
      status={status}
    >
      <FlexBox flexDirection="column" gap="0.75rem">
        {targets.map(target => (
          <AudienceCard key={target.id} target={target} />
        ))}
      </FlexBox>
      {openEditAudiencesModal && (
        <EditAudiencesModal
          orderlineId={orderlineId}
          targets={targets}
          onCancel={() => setOpenEditAudiencesModal(false)}
          type={type}
          total_impressions={total_impressions}
          total_days={total_days}
          served_days={served_days}
          budget={budget}
          served_impressions={served_impressions}
          updateOrderlineData={updateOrderlineData}
        />
      )}
    </OrderlineDetailsContainer>
  );
}

import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import Collapsible from 'react-collapsible';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartArea,
  faCog,
  faColumns,
  faImage,
  faImages,
  faLightbulb,
  faPlus,
  faSearch,
  faShareAlt,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faUserCircle,
  faUsers,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';

import { faReceipt } from '@fortawesome/pro-solid-svg-icons';

import { Avatar, HeaderItem, HeaderLogo, Modal } from '@eltoro-ui/components';
import { ConfirmationModal } from 'Components';
import { VotingModal } from 'Components/VotingModal';
import { createBrowserHistory } from 'history';

import CreativesLibraryIcon from 'assets/icons/CreativeLibraryIcon';

import { useSidebarContext } from 'contexts/SidebarContext';

import type { TRootState } from 'types';

import logo from 'assets/Images/logo-update.svg';
import Charticons from 'assets/Images/charticons.png';
import { GuideEnum } from 'enums';
import { usePermissionContext } from 'contexts/PermissionContext';
import GuidePopoverSelector from '../GuidePopoverSelector';
import './SideNavigationBarForDesktop.scss';

const childItemRoutes = [
  '/campaign-dashboard',
  '/orderlines?filter=campaigns',
  '/orderlines',
  '/all-campaigns',
  '/saved-audiences',
  '/create-campaign',
  '/creatives-library',
  '/profile',
];

export const SideNavigationBarForDesktop: FC<{
  onLogOut: () => void;
  isAdmin: boolean;
}> = ({ onLogOut, isAdmin }) => {
  const socialMediaPostingFlag = useFlag('social-media-posting');
  const { flagsReady } = useFlagsStatus();
  const orderlineViewFlag = useFlag('orderline-view');

  const history = createBrowserHistory();
  const Location = useLocation();

  const { campaignsDropdown, settingsDropdown, toggleDropdown } = useSidebarContext();
  const { userIsDeactivatedWithActiveOrderlines, userIsActive, userIsFailed } =
    usePermissionContext();
  const [dropdownIsActive, setDropdownIsActive] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [collapseToggle, setCollapseToggle] = useState(false);
  const [chartModal, setChartModal] = useState(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [activeGuideTypes, setActiveGuideTypes] = useState<GuideEnum[]>([]);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const quideBotContacts = sessionStorage.getItem('guideBotContacts');
  const quideBotRecommendation = sessionStorage.getItem('guideBotRecommendation');
  const { userRecommendations } = useSelector((state: TRootState) => state.recommendationsReducer);

  const isActiveRoute = (route: string) => {
    let className = '';
    if (childItemRoutes.includes(route) && Location.pathname.includes(route)) {
      className = 'active_sidebar_icon_color';
    } else if (Location.pathname.includes(route)) {
      className = 'active_sidebar_icon';
    }
    return className;
  };

  const socialMediaPosting = useMemo(() => {
    if (!flagsReady) return null;
    if (socialMediaPostingFlag)
      return (
        <li className="list">
          <abbr title="Social Media Posting">
            <HeaderItem
              childrenClassName="bar_text"
              iconClassname={isActiveRoute('/social-media-posting')}
              disabled={!userIsActive}
              to={userIsActive ? '/social-media-posting' : null}
              icon={<FontAwesomeIcon className="sidebar_icon" icon={faShareAlt} />}
              onClick={() => {
                toggleDropdown();
              }}
            >
              Social Media Posting
            </HeaderItem>
          </abbr>
        </li>
      );
    // if (!flagsReady) return null;
    // if (socialMediaPostingFlag)
    if (!userData.ms_secret_key && !userData.ms_api_key) {
      return null;
    }
    return (
      <li className="list">
        <abbr title="Social Media Posting">
          <HeaderItem
            childrenClassName="bar_text"
            iconClassname={isActiveRoute('/social-media-posting')}
            disabled={!userIsActive}
            to={userIsActive ? '/social-media-posting' : null}
            icon={<FontAwesomeIcon className="sidebar_icon" icon={faShareAlt} />}
            onClick={() => {
              toggleDropdown();
            }}
          >
            Social Media Posting
          </HeaderItem>
        </abbr>
      </li>
    );

    // return null;
  }, [flagsReady, socialMediaPostingFlag]);

  const handleCloseRecommendations = (guideType: GuideEnum) => {
    sessionStorage.setItem(
      guideType === GuideEnum.CONTACTS ? 'guideBotContacts' : 'guideBotRecommendation',
      JSON.stringify({
        isActive: false,
        type: guideType,
      })
    );
    setActiveGuideTypes(ps => ps.filter(bot => bot !== guideType));
  };

  useEffect(() => {
    if (quideBotContacts) {
      const contactsGuideBotData = JSON.parse(quideBotContacts);
      setActiveGuideTypes(ps => [...ps, contactsGuideBotData.type]);
    }

    if (quideBotRecommendation) {
      const recGuideBotData = JSON.parse(quideBotRecommendation);
      setActiveGuideTypes(ps => [...ps, recGuideBotData.type]);
    }
  }, [quideBotContacts, quideBotRecommendation]);

  return (
    <div className="side_nav_bar_container_for_desktop">
      {collapseToggle && (
        <div
          className={classNames('shadow_container', {
            'sidebar-collapsed': collapseToggle,
          })}
          onClick={() => {
            setCollapseToggle(false);
          }}
          onKeyDown={() => {
            setCollapseToggle(false);
          }}
          role="none"
        />
      )}
      <div
        className={classNames('side_nav_bar_nested_container', {
          set_width: collapseToggle,
        })}
      >
        <div className="icon_container">
          <HeaderLogo
            onClick={() => {
              setCollapseToggle(!collapseToggle);
            }}
            logo={logo}
            to=""
          />
        </div>

        <div>
          <ul className="unorder_list">
            <>
              <li className="list">
                <abbr title="Dashboard">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={
                      Location.pathname === '/'
                        ? 'active_sidebar_icon'
                        : isActiveRoute('/dashboard')
                    }
                    to={userIsActive ? '/dashboard' : null}
                    disabled={!userIsActive}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faColumns} />}
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    Dashboard
                  </HeaderItem>
                </abbr>
              </li>

              <li className="list">
                <abbr title="My Sphere">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('/my-sphere')}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faUserCircle} />}
                    disabled={!userIsActive}
                    to={userIsActive ? '/my-sphere' : null}
                    hasRecommendations={
                      activeGuideTypes.includes(GuideEnum.CONTACTS) ||
                      (userRecommendations?.contact_files.active === 0 &&
                        userRecommendations?.contact_files.pending === 0)
                    }
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    My Sphere
                  </HeaderItem>
                </abbr>
                <GuidePopoverSelector
                  isActive={
                    activeGuideTypes.includes(GuideEnum.CONTACTS) &&
                    quideBotContacts &&
                    JSON.parse(quideBotContacts).isActive
                  }
                  collapseToggle={collapseToggle}
                  type={GuideEnum.CONTACTS}
                  handleClose={() => handleCloseRecommendations(GuideEnum.CONTACTS)}
                />
              </li>

              <li className="list">
                <abbr title="Prospects">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('/prospects')}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faSearch} />}
                    to={userIsActive ? '/prospects' : null}
                    disabled={!userIsActive}
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    Prospects
                  </HeaderItem>
                </abbr>
              </li>

              <li className="list">
                <abbr title="Advanced Reporting">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('#')}
                    icon={
                      <FontAwesomeIcon
                        className="sidebar_icon"
                        color="lightgray"
                        icon={faChartArea}
                      />
                    }
                    disabled={!userIsActive}
                    to={!userIsActive ? '#' : null}
                    onClick={() => {
                      if (!userIsDeactivatedWithActiveOrderlines) {
                        toggleDropdown();
                        setChartModal(true);
                      }
                    }}
                  >
                    Advanced Reporting
                  </HeaderItem>
                </abbr>
              </li>
              <li className="list">
                <abbr title="Activity Alerts">
                  <HeaderItem
                    childrenClassName="bar_text"
                    iconClassname={isActiveRoute('/activity-alerts')}
                    icon={<FontAwesomeIcon className="sidebar_icon" icon={faLightbulb} />}
                    disabled={!userIsActive}
                    to={userIsActive ? '/activity-alerts' : null}
                    hasRecommendations={
                      activeGuideTypes.includes(GuideEnum.RECOMENDATIONS) &&
                      !!quideBotRecommendation &&
                      !JSON.parse(quideBotRecommendation).isUpToDate &&
                      !history.location.pathname.includes('/activity-alerts')
                    }
                    onClick={() => toggleDropdown()}
                  >
                    Activity Alerts
                  </HeaderItem>
                </abbr>
                {quideBotRecommendation &&
                  !!JSON.parse(quideBotRecommendation).count &&
                  userRecommendations?.recommendations && (
                    <GuidePopoverSelector
                      isActive={
                        activeGuideTypes.includes(GuideEnum.RECOMENDATIONS) &&
                        quideBotRecommendation &&
                        !history.location.pathname.includes('/activity-alerts') &&
                        JSON.parse(quideBotRecommendation).isActive
                      }
                      handleClose={() => handleCloseRecommendations(GuideEnum.RECOMENDATIONS)}
                      collapseToggle={collapseToggle}
                      type={GuideEnum.RECOMENDATIONS}
                      recommendationType={
                        quideBotRecommendation
                          ? JSON.parse(quideBotRecommendation).msgType
                          : undefined
                      }
                      recommendationsCount={
                        quideBotRecommendation ? JSON.parse(quideBotRecommendation).count : 0
                      }
                      recommendationListingName={userRecommendations?.recommendations[0]?.name}
                      sellersCount={
                        userRecommendations?.recommendations?.find(
                          (rec: any) => rec.sub_type === 'seller'
                        )?.seller_count ?? 0
                      }
                      buyersCount={
                        userRecommendations?.recommendations?.find(
                          (rec: any) => rec.sub_type === 'buyer'
                        )?.buyer_count ?? 0
                      }
                      locationData={{
                        state: userRecommendations?.recommendations[0]?.state,
                        city: userRecommendations?.recommendations[0]?.city,
                        street: userRecommendations?.recommendations[0]?.street,
                        zip: userRecommendations?.recommendations[0]?.zip,
                      }}
                    />
                  )}
              </li>
              {chartModal && (
                <Modal offClick={() => setChartModal(false)}>
                  <VotingModal
                    handleModel={() => setChartModal(false)}
                    icons={Charticons}
                    title="Advanced Reporting"
                    subTitle="View geographic statistics based on physical foot traffic and broker-level competition."
                  />
                </Modal>
              )}
              <div>
                <Collapsible
                  className={classNames(!collapseToggle && 'collapsed')}
                  openedClassName={classNames(
                    !collapseToggle && 'collapsed',
                    !collapseToggle && campaignsDropdown && 'active-dropdown'
                  )}
                  open={campaignsDropdown}
                  onTriggerOpening={() => {
                    setDropdownIsActive(!dropdownIsActive);
                    toggleDropdown('campaigns');
                  }}
                  onTriggerClosing={() => {
                    setDropdownIsActive(!dropdownIsActive);
                    toggleDropdown();
                  }}
                  trigger={
                    <li className="list">
                      <abbr title="My Campaigns">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname="icon_group"
                          icon={
                            <>
                              <FontAwesomeIcon className="sidebar_icon" icon={faImages} />
                              <FontAwesomeIcon
                                className="sidebar_icon"
                                icon={campaignsDropdown ? faSortUp : faSortDown}
                              />
                            </>
                          }
                          to={null}
                          disabled={!(userIsDeactivatedWithActiveOrderlines || userIsActive)}
                        >
                          My Campaigns
                        </HeaderItem>
                      </abbr>
                    </li>
                  }
                >
                  <ul className={collapseToggle ? 'unorder_list_withOutPadding' : 'unorder_list'}>
                    <li className="list sub_item">
                      <abbr title="Campaign Dashboard">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute(
                            '/campaign-dashboard'
                          )} active_sidebar_img box_shadow_none`}
                          icon={<FontAwesomeIcon className="sidebar_icon" icon={faReceipt} />}
                          to={
                            userIsDeactivatedWithActiveOrderlines || userIsActive
                              ? '/campaign-dashboard'
                              : null
                          }
                          disabled={!(userIsDeactivatedWithActiveOrderlines || userIsActive)}
                        >
                          Campaign Dashboard
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title={orderlineViewFlag && flagsReady ? 'Orderlines' : 'Campaigns'}>
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute(
                            orderlineViewFlag && flagsReady ? '/orderlines' : '/all-campaigns'
                          )} ${isActiveRoute(
                            orderlineViewFlag && flagsReady ? '/campaigns' : '/all-campaigns'
                          )} box_shadow_none`}
                          icon={
                            <FontAwesomeIcon
                              className="sidebar_icon"
                              icon={orderlineViewFlag && flagsReady ? faBullhorn : faImage}
                            />
                          }
                          to={
                            userIsDeactivatedWithActiveOrderlines || userIsActive
                              ? orderlineViewFlag && flagsReady
                                ? '/orderlines'
                                : '/all-campaigns'
                              : null
                          }
                          title={orderlineViewFlag && flagsReady ? 'Orderlines' : 'Campaigns'}
                          disabled={!(userIsDeactivatedWithActiveOrderlines || userIsActive)}
                        >
                          {orderlineViewFlag && flagsReady ? 'Orderlines' : 'Campaigns'}
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="Saved Audience">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute('/saved-audiences')} box_shadow_none`}
                          icon={<FontAwesomeIcon className="sidebar_icon" icon={faUsers} />}
                          to={userIsActive ? '/saved-audiences' : null}
                          disabled={!userIsActive}
                        >
                          Saved Audience
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="Creatives Library">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute('/creatives-library')} box_shadow_none`}
                          icon={<CreativesLibraryIcon className="sidebar_icon" />}
                          to={userIsActive ? '/creatives-library' : null}
                          disabled={!userIsActive}
                        >
                          Creatives Library
                        </HeaderItem>
                      </abbr>
                    </li>
                    <li className="list sub_item">
                      <abbr title="New Campaign">
                        <HeaderItem
                          childrenClassName="bar_text"
                          iconClassname={`${isActiveRoute(
                            '/create-campaign/setup'
                          )} box_shadow_none`}
                          disabled={!userIsActive}
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          to={userIsActive ? '?action=create-campaign' : null}
                          replace
                        >
                          New Campaign
                        </HeaderItem>
                      </abbr>
                    </li>
                  </ul>
                </Collapsible>
              </div>
              {socialMediaPosting}
              <Collapsible
                className={classNames(!collapseToggle && 'collapsed')}
                openedClassName={classNames(
                  !collapseToggle && 'collapsed',
                  !collapseToggle && settingsDropdown && 'active-dropdown'
                )}
                open={settingsDropdown}
                onTriggerOpening={() => {
                  setDropdownIsActive(!dropdownIsActive);
                  toggleDropdown('settings');
                }}
                onTriggerClosing={() => {
                  setDropdownIsActive(!dropdownIsActive);
                  toggleDropdown();
                }}
                trigger={
                  <li className="list">
                    <abbr title="Settings">
                      <HeaderItem
                        childrenClassName="bar_text"
                        iconClassname="icon_group"
                        icon={
                          <>
                            <FontAwesomeIcon className="sidebar_icon" icon={faCog} />
                            <FontAwesomeIcon
                              className="sidebar_icon"
                              icon={settingsDropdown ? faSortUp : faSortDown}
                            />
                          </>
                        }
                        to={null}
                      >
                        Settings
                      </HeaderItem>
                    </abbr>
                  </li>
                }
              >
                <ul className={collapseToggle ? 'unorder_list_withOutPadding' : 'unorder_list'}>
                  <li className="list sub_item">
                    <abbr title="Profile">
                      <HeaderItem
                        childrenClassName="bar_text"
                        iconClassname={`${isActiveRoute('/profile')} box_shadow_none`}
                        disabled={
                          !(userIsDeactivatedWithActiveOrderlines || userIsActive || userIsFailed)
                        }
                        to={
                          userIsDeactivatedWithActiveOrderlines || userIsActive || userIsFailed
                            ? '/profile'
                            : null
                        }
                        icon={
                          userData?.profile_pic ? (
                            <Avatar
                              name={`${userData?.first_name} ${userData?.last_name}`}
                              rounded
                              size="s"
                              src={userData?.profile_pic}
                              UNSAFE_className="avatar"
                            />
                          ) : (
                            <FontAwesomeIcon className="sidebar_icon" icon={faUserCircle} />
                          )
                        }
                      >
                        Profile
                      </HeaderItem>
                    </abbr>
                  </li>

                  <li className="list sub_item">
                    <abbr title="Logout">
                      <HeaderItem
                        childrenClassName="bar_text"
                        iconClassname="box_shadow_none"
                        UNSAFE_CLASSNAME="box_shadow_none"
                        icon={<FontAwesomeIcon className="sidebar_icon" icon={faSignOutAlt} />}
                        onClick={() => setLogoutModal(true)}
                      >
                        Logout
                      </HeaderItem>
                    </abbr>
                  </li>
                </ul>
              </Collapsible>
              {logoutModal && (
                <ConfirmationModal
                  heading="Are you sure you want to logout?"
                  onSubmit={() => {
                    onLogOut();
                    setIsLoading(true);
                  }}
                  onCancel={() => setLogoutModal(false)}
                  loading={Boolean(isLoading)}
                />
              )}
            </>

            {/* {!isAdmin && ( */}
            {/*  <ul className="unorder_list"> */}
            {/*    <li className="list sub_item"> */}
            {/*      <abbr title="Logout"> */}
            {/*        <HeaderItem */}
            {/*          childrenClassName="bar_text" */}
            {/*          iconClassname="box_shadow_none" */}
            {/*          UNSAFE_CLASSNAME="box_shadow_none" */}
            {/*          icon={<FontAwesomeIcon className="sidebar_icon" icon={faSignOutAlt} />} */}
            {/*          onClick={onLogOut} */}
            {/*        > */}
            {/*          Logout */}
            {/*        </HeaderItem> */}
            {/*      </abbr> */}
            {/*    </li> */}
            {/*  </ul> */}
            {/* )} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

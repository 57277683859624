import React from 'react';

import { Button, FlexBox } from '@eltoro-ui/components';

import { Spinner } from 'Components';
import { useMagicCreativeContext } from 'contexts/MagicCreativeContext';

import CustomIcons from 'assets/icons';

import { steps } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/constants';

interface MagicGeneratorStateProps {
  state: 'error' | 'loading';
}

const statesContent = {
  loading: {
    title: "Hang tight! We're crafting your magic banners",
    description: 'This might take a moment, but great things are worth the wait!',
  },
  error: {
    title: 'Oops, there was an issue with generating your banners.',
    description: 'Please go back to regenerate your magic banners or edit your form if needed.',
  },
};

export default function MagicGeneratorState({ state }: MagicGeneratorStateProps) {
  const { onStepChange } = useMagicCreativeContext();

  return (
    <FlexBox flexDirection="column" UNSAFE_className="magic-banners-usage states" gap="0.5rem">
      <span className="images-info">Ad Preview</span>
      <FlexBox
        UNSAFE_className="not-success-state-container"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="0.75rem"
      >
        <CustomIcons
          name={state === 'error' ? 'magic_split_paper' : 'magic_hat'}
          fontSize={state === 'error' ? 60 : 72}
        />
        <FlexBox
          flexDirection="column"
          alignItems="center"
          gap="0.5rem"
          UNSAFE_style={{ maxWidth: state === 'error' ? 415 : 388 }}
        >
          <span className="not-success-state-title">{statesContent[state].title}</span>
          <span className="not-success-state-description">{statesContent[state].description}</span>
        </FlexBox>
        {state === 'error' ? (
          <Button
            kind="default"
            size="l"
            UNSAFE_className="contacts_btn"
            weight="bold"
            onClick={() => onStepChange(steps[1])}
          >
            Back to Regenerate
          </Button>
        ) : (
          <div className="before-spinner" style={{ position: 'initial', margin: 0 }} />
        )}
      </FlexBox>
    </FlexBox>
  );
}

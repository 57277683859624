import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import moment from 'moment/moment';

import Tooltip from 'rc-tooltip';

import type { Column } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import { Button, FlexBox } from '@eltoro-ui/components';
import { EmptyStatus, PageHeader, ReactTable, ReactTableSearchBar } from 'Components';

import CustomIcons from 'assets/icons';

import { deleteCampaign, getCampaigns } from 'Requests';

import { CampaignStatusEnum, CampaignStep } from 'enums';
import type { TCampaign, TResPagination, TRootState } from 'types';

import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { usePermissionContext } from 'contexts/PermissionContext';
import DeactivateInfo from '../MyProfile/components/DeactivateAccount/components/DeactivateInfo';
import './Campaigns.scss';

export const getStatusColor = (status: string) => {
  if (status === 'processed' || status === 'Open') return '#ffab03';
  if (status === 'pending' || status === 'processing') return '#6D797C';
  if (status === 'Ready' || status === 'Serving') return 'var(--color-success-400)';
  return '#AAB2B5';
};

export const getStatus = (status: string) => {
  if (status === 'pending' || status === 'processing') return 'Draft';
  if (status === 'processed' || status === 'Open') return 'In Review';
  if (status === 'Serving') return 'Active';
  return 'pending';
};

const redirect = (campaign: TCampaign) => {
  if (campaign.step === CampaignStep.AUDIENCE) {
    return `/create-campaign/${campaign?.id}/setup`;
  }
  if (campaign.step === CampaignStep.CREATIVE) {
    return `/create-campaign/${campaign?.id}/creatives`;
  }
  if (campaign.step === CampaignStep.CHECKOUT) {
    return `/create-campaign/${campaign?.id}/calculate`;
  }

  return `/campaigns/${campaign.id}`;
};

const getRedirectLink = (campaign: TCampaign, userIsActive: boolean) => {
  if (campaign.status === CampaignStatusEnum.CREATING) {
    if (campaign.step !== CampaignStep.DONE)
      return (
        <Link
          to={userIsActive ? redirect(campaign) : '#'}
          style={{ cursor: userIsActive ? 'pointer' : 'not-allowed' }}
        >
          <CustomIcons name="edit" color={userIsActive ? '#007B94' : '#D7D7D7'} fontSize={20} />
        </Link>
      );

    return (
      <FontAwesomeIcon
        icon={faSpinnerThird}
        color="#FFAB03"
        style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
      />
    );
  }

  if (campaign.status === CampaignStatusEnum.ERROR) {
    if (campaign.step !== CampaignStep.DONE)
      return (
        <Link to={userIsActive ? redirect(campaign) : '#'}>
          <CustomIcons name="edit" color="#007B94" fontSize={20} />
        </Link>
      );

    return (
      <FlexBox justifyContent="center" alignItems="center" UNSAFE_style={{ cursor: 'not-allowed' }}>
        <CustomIcons name="edit" color="#D7D7D7" fontSize={20} />
      </FlexBox>
    );
  }

  if (
    campaign.status === CampaignStatusEnum.PAYMENT_FAILED &&
    campaign.step === CampaignStep.CHECKOUT
  )
    return (
      <Link to={userIsActive ? redirect(campaign) : '#'}>
        <CustomIcons name="edit" color="#007B94" fontSize={20} />
      </Link>
    );

  if (campaign.status === CampaignStatusEnum.IN_REVIEW && campaign.step === CampaignStep.DONE)
    return (
      <Link to={userIsActive ? `/campaigns/${campaign.id}` : '#'}>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={userIsActive ? '#808080' : '#EBEBEB'}
          style={{
            fontSize: 20,
            cursor: userIsActive ? 'pointer' : 'not-allowed',
          }}
        />
      </Link>
    );

  return null;
};

const getInfoIconAndTooltip = (campaign: TCampaign) => {
  if (
    campaign.status === CampaignStatusEnum.ERROR ||
    campaign.status === CampaignStatusEnum.PAYMENT_FAILED
  ) {
    return (
      <Tooltip
        placement="topRight"
        trigger="hover"
        overlayClassName={classNames('audience-tooltip', {
          'campaign-status-error': campaign.step !== CampaignStep.DONE,
        })}
        showArrow
        align={{
          offset: [5, -3],
          targetOffset: [-6, 0],
        }}
        overlay={
          campaign.step === CampaignStep.DONE
            ? 'Campaign creation has failed.'
            : 'There are errors in your campaign. Please review them before publishing.'
        }
        getTooltipContainer={triggerNode =>
          triggerNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
            ?.parentNode as HTMLElement
        }
      >
        <span style={{ cursor: 'pointer', display: 'flex' }}>
          <FontAwesomeIcon icon={faExclamationCircle} color="#FF0000" style={{ fontSize: 20 }} />
        </span>
      </Tooltip>
    );
  }
  return null;
};

export const Campaigns = () => {
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed, userIsActive } =
    usePermissionContext();
  const [campaigns, setCampaigns] = useState<TCampaign[]>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);
  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const onDelete = async (_items: any[]) => {
    setDeleteIsLoading(true);
    const ids = _items.map((item: any) => item?.original?.id);
    try {
      const { data } = await deleteCampaign({ campaign_id: ids });
      if (data?.length)
        toast.success(`${data.length} Campaign${data.length > 1 ? 's' : ''} Deleted`);
      setDeleteModalIsOpen(false);
      setDeleteIsLoading(false);

      if (page === 1) await _fetchData();
      else await setPage(1);
    } catch (err: any) {
      toast.error(err.detail);
      setDeleteIsLoading(false);
    }
  };

  const _fetchData = async () => {
    setFetchDataIsLoading(true);
    try {
      const res = await getCampaigns(page, 50, searchValue, null);
      if (res.data) {
        const { total_campaigns, total_pages, campaigns } = res.data;
        setCampaigns(campaigns);
        setPagination({ total_count: total_campaigns, total_pages });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    _fetchData();
  }, [page, searchValue]);

  const onViewDetail = (campaign: TCampaign) => {
    return (
      <FlexBox
        alignItems="center"
        justifyContent="flex-end"
        gap="12px"
        UNSAFE_style={{ marginInlineEnd: '8px' }}
      >
        {getInfoIconAndTooltip(campaign)}
        {getRedirectLink(campaign, userIsActive)}
      </FlexBox>
    );
  };
  const columns: Column<TCampaign>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Campaign Name',
          accessor: 'name',
          Cell: ({ row: { original: campaign } }) => (
            <FlexBox
              flexDirection="column"
              gap={
                campaign.status === CampaignStatusEnum.CREATING &&
                campaign.step !== CampaignStep.DONE
                  ? '2px'
                  : '0'
              }
            >
              <span className="campaign-name">{campaign.name}</span>
              {campaign.status === CampaignStatusEnum.CREATING &&
                campaign.step !== CampaignStep.DONE && (
                  <span className="campaign-status">Draft</span>
                )}
            </FlexBox>
          ),
        },
        {
          Header: 'Days',
          accessor: campaign => campaign.days || '-',
        },
        {
          Header: 'Audience Size',
          accessor: campaign => campaign.prospects_counts || '-',
        },
        {
          Header: 'Date Uploaded',
          accessor: campaign => moment(campaign.created_at).format('MM/DD/YYYY'),
        },
        {
          Header: 'Campaign Goal',
          accessor: campaign => campaign.goal || '--',
        },
        {
          Header: '',
          id: 'state',
          accessor: onViewDetail,
        },
      ],
    },
  ];

  return (
    <div className="Campaigns">
      <PageHeader
        UNSAFE_CLASSNAME="Campaigns__PageHeader"
        LeftContent_ClassName="MyListings__header"
        subTitle={
          <span className="MyListings__totalCounts">
            You have <b>{pagination.total_count} campaigns</b>
          </span>
        }
        actions={[
          <ReactTableSearchBar
            key="campaign-searchbar"
            searchValue={searchValue}
            handleSearchValue={handleSearchValue}
          />,
          <Button
            key="new-campaign"
            kind="primary"
            to="?action=create-campaign"
            replace
            size="l"
            weight="bold"
            disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
          >
            New Campaign
          </Button>,
        ]}
      />
      <DeactivateInfo />
      <ReactTable<TCampaign>
        title="campaign"
        loading={fetchDataIsLoading}
        deleteModalNote="Only draft campaigns can be deleted"
        deleteModalHeader="Are you sure you want to delete the selected campaign(s)?"
        emptyText={
          <EmptyStatus
            heading={searchValue && !campaigns.length ? 'No results found' : undefined}
            subHeading={
              searchValue && !campaigns.length
                ? 'You might consider trying different keywords, double-checking for any spelling errors, or adjusting your filters.'
                : 'You can try by creating new campaign to get better audience.'
            }
          />
        }
        data={campaigns}
        onDelete={onDelete}
        deleteModal={deleteModalIsOpen}
        setDeleteModal={setDeleteModalIsOpen}
        deleteIsLoading={deleteIsLoading}
        pageSize={50}
        totalCount={pagination.total_count}
        pageCount={pagination.total_pages}
        currentPage={page}
        setPage={setPage}
        columns={columns}
      />
    </div>
  );
};

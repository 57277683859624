import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox, GridBox, Modal, Text } from '@eltoro-ui/components';

import { EmptyStatus, Loading } from 'Components';

import BannerSizesModal from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadBannerModal/components/BannerSizesModal';
import CanvaDesignCard from 'Pages/CreativesLibrary/components/CanvaDesignsModal/components/CanvaDesignCard';

import { exportDesign, getDesigns } from 'canva-integration/services';

import { maxSize, validateBanner } from 'Components/Uploader';

import warning_icon from 'assets/Images/warning_icon.svg';

import type { Design } from 'canva-integration/type';

import './CanvaDesignsModal.scss';

interface CanvaDesignsModalProps {
  open: boolean;
  onSubmit: (base64s: any[]) => Promise<void>;
  onCancel: VoidFunction;
}

export default function CanvaDesignsModal({ open, onSubmit, onCancel }: CanvaDesignsModalProps) {
  const [sizesModalOpen, setSizesModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [canvaDesigns, setCanvaDesigns] = useState<Array<Design>>([]);

  const [selectedDesign, setSelectedDesign] = useState<Design | null>(null);

  const getCanvaDesigns = async () => {
    try {
      setIsLoading(true);
      const { items } = await getDesigns();
      setCanvaDesigns(items.filter(design => design.thumbnail));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) getCanvaDesigns();
    return () => {
      setCanvaDesigns([]);
      setSelectedDesign(null);
    };
  }, [open]);

  const onSelectDesign = (design: Design) => () => {
    setSelectedDesign(selectedDesign => {
      if (selectedDesign) {
        if (selectedDesign.id === design.id) return null;
        return design;
      }
      return design;
    });
  };

  const toBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onUpload = async () => {
    try {
      setIsUploading(true);
      if (selectedDesign) {
        const { job } = await exportDesign({ designId: selectedDesign.id });
        if (job.urls) {
          const response = await fetch(job.urls[0]);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', { type: 'image/png' });

          const { file: validatedFile, isValid } = await validateBanner(file, true, true);

          if (isValid) {
            if (file.size <= maxSize.banner) {
              const base64 = await toBase64(blob);
              const [width, height] = validatedFile.file_specs?.split('x').map(Number);
              await onSubmit([{ handleDataURL: base64, width, height }]);
              await onCancel();
            } else
              toast.error(
                'Oops! Your image is too large, please make sure your file is under 150 KB.',
                {
                  icon: <img src={warning_icon} alt="warn" />,
                }
              );
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsUploading(false);
    }
  };

  if (!open) return null;

  return (
    <Modal className="canva-designs-modal">
      <div className="canva-designs-modal-header">
        <span className="canva-designs-modal-header-title">Choose Banners from Canva</span>
      </div>
      <GridBox gap="0.5rem">
        <FlexBox alignItems="center" gap="0.5rem">
          <FontAwesomeIcon icon={faStar} color="#FFAB03" />
          <Text on="white" size="l">
            Recommended Parameters
          </Text>
        </FlexBox>
        <FlexBox flexDirection="column" gap="0.5rem">
          <FlexBox alignItems="center" gap="0.5rem" UNSAFE_className="creative-recommendations">
            <span className="label">Banner Size:</span>
            <span className="text">
              <b>300 x 250</b> (desktop, tablet, mobile), <b>320 x 100</b> (best for
              mobile/smartphones)
            </span>
            <Button
              size="m"
              kind="text"
              UNSAFE_style={{ fontWeight: 400 }}
              onClick={() => setSizesModalOpen(true)}
              UNSAFE_className="refresh-contacts"
            >
              See all sizes
            </Button>
          </FlexBox>
          <FlexBox alignItems="center" gap="0.5rem" UNSAFE_className="creative-recommendations">
            <span className="label">File Size per upload:</span>
            <span className="text">
              <b>up to 150kbs</b>
            </span>
          </FlexBox>
        </FlexBox>
      </GridBox>
      <div className="canva-designs-modal-content">
        {isLoading ? (
          <Loading />
        ) : canvaDesigns.length ? (
          <div className="canva-designs-card-content">
            <div className="canva-designs-card-container">
              {canvaDesigns.map(design => (
                <CanvaDesignCard
                  key={design.id}
                  design={design}
                  selected={design.id === selectedDesign?.id}
                  onSelectDesign={onSelectDesign(design)}
                />
              ))}
            </div>
          </div>
        ) : (
          <EmptyStatus
            heading="You do not have banners in Canva"
            subHeading="Your Canva banners will be displayed here once added."
          />
        )}
      </div>
      <div className="choose-from-creatives-library-footer">
        <Button onClick={onCancel} weight="bold" size="l">
          Cancel
        </Button>
        <Button
          kind="primary"
          weight="bold"
          size="l"
          loading={isUploading}
          disabled={isUploading || isLoading || !selectedDesign}
          onClick={onUpload}
        >
          Upload
        </Button>
      </div>
      {sizesModalOpen && <BannerSizesModal onClickOutside={() => setSizesModalOpen(false)} />}
    </Modal>
  );
}

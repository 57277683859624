import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/pro-regular-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import { prospectsIconLinks } from 'Pages/OrderlineDetails/components/AttachedAudiences/components/AudienceCard/constants';

import type { TOrderlineTarget } from 'types';

import './AudienceCard.scss';

interface AudienceCardProps {
  target: TOrderlineTarget;
}

export default function AudienceCard({ target }: AudienceCardProps) {
  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      UNSAFE_className="audience-card-container"
    >
      <FlexBox alignItems="center" gap="0.75rem">
        <img className="lens-img" src={prospectsIconLinks[target.source]} alt="audience_name" />
        <span className="audience-name">{target.name}</span>
      </FlexBox>
      <FlexBox alignItems="center" gap="0.5rem">
        <FontAwesomeIcon icon={faWalking} style={{ fontSize: 20, color: '#FFAB03' }} />
        <span className="prospects-count-value">{target.prospects_counts}</span>
        <span className="prospects-count-label">Prospects</span>
      </FlexBox>
    </FlexBox>
  );
}

import sell_your_listing_faster from 'assets/Images/prospects_lg_2.png';
import talk_to_your_sphere from 'assets/Images/prospects_lg_1_active.png';
import grow_your_sphere from 'assets/Images/prospects_lg_3_active.png';
import likely_sellers from 'assets/Images/likely-sellers-active.png';
import virtual_just_listed_just_sold from 'assets/Images/prospects_lg_6_active.png';
import digital_farming from 'assets/Images/digital_farming.png';

import { ProspectNameTypeEnums } from 'enums';

export const prospectsIconLinks = {
  [ProspectNameTypeEnums.SELL_YOUR_LISTING_FASTER]: sell_your_listing_faster,
  [ProspectNameTypeEnums.TALK_TO_YOUR_SPHERE]: talk_to_your_sphere,
  [ProspectNameTypeEnums.GROW_YOUR_SPHERE]: grow_your_sphere,
  [ProspectNameTypeEnums.LIKELY_SELLERS]: likely_sellers,
  [ProspectNameTypeEnums.VIRTUAL_JUST_LISTED_JUST_SOLD]: virtual_just_listed_just_sold,
  [ProspectNameTypeEnums.DIGITAL_FARMING]: digital_farming,
};

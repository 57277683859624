import React from 'react';

import moment from 'moment';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

import type { TOrderlineDetails } from 'types';

const styles = StyleSheet.create({
  orderline: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '13px 16px',
    borderRadius: '20px',
    flexDirection: 'column',
  },
  // -------- header -------
  orderline_header: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  orderline_header_name: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderline_name: {
    color: '#2B1F0A',
    fontSize: '16px',
    lineHeight: '1',
    marginRight: '10px',
  },
  orderline_flight_dates: {
    marginTop: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  orderline_flight_dates_label: {
    color: '#757575',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 'normal',
  },
  orderline_flight_dates_value: {
    color: '#757575',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 700,
  },
  // orderline status tag
  orderline_status: {
    padding: '4px 8px',
    borderRadius: '6px',
  },
  orderline_status_text: {
    fontSize: '12px',
  },
  // active
  orderline_status_active: {
    backgroundColor: '#F1FDE8',
  },
  orderline_status_text_active: {
    color: '#5ABB82',
  },
  // in review
  orderline_status_in_review: {
    backgroundColor: '#FFFDEA',
  },
  orderline_status_text_in_review: {
    color: '#FFCC1B',
  },
  // rejected
  orderline_status_rejected: {
    backgroundColor: '#FFF0F1',
  },
  orderline_status_text_rejected: {
    color: '#FF2D38',
  },
  // approved
  orderline_status_approved: {
    backgroundColor: '#EAFFFE',
  },
  orderline_status_text_approved: {
    color: '#007B94',
  },
  // paused
  orderline_status_paused: {
    backgroundColor: '#FFF9ED',
  },
  orderline_status_text_paused: {
    color: '#FA8C16',
  },
  // completed
  orderline_status_completed: {
    backgroundColor: '#F1EAF9',
  },
  orderline_status_text_completed: {
    color: '#9747FF',
  },
});

interface OrderlineHeaderProps
  extends Pick<TOrderlineDetails, 'name' | 'status' | 'start_date' | 'end_date'> {}

export default function OrderlineHeader({
  name,
  status,
  end_date,
  start_date,
}: OrderlineHeaderProps) {
  return (
    <View style={styles.orderline}>
      <View style={styles.orderline_header}>
        <View style={styles.orderline_header_name}>
          <View>
            <Text style={styles.orderline_name}>{name}</Text>
          </View>
          <View
            style={{
              ...styles.orderline_status,
              // @ts-ignore
              ...styles[`orderline_status_${status.toLowerCase().split(' ').join('_')}`],
            }}
          >
            <Text
              style={{
                ...styles.orderline_status_text,
                // @ts-ignore
                ...styles[`orderline_status_text_${status.toLowerCase().split(' ').join('_')}`],
              }}
            >
              {status}
            </Text>
          </View>
        </View>
        <View style={styles.orderline_flight_dates}>
          <View>
            <Text style={styles.orderline_flight_dates_label}>Flight Dates: </Text>
          </View>
          <View>
            <Text style={styles.orderline_flight_dates_value}>
              {moment(end_date).format('MM/DD/YYYY')} - {moment(start_date).format('MM/DD/YYYY')}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

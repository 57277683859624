import requests from 'Requests/http';

import type { ResponseType, TOrderlineDetails } from 'types';
import { returnHttpsPrefix } from 'Utils/helpers';
import { THistoryAlert } from 'types';

export const getOrderlineDetails = async (
  orderlineId: string
): Promise<ResponseType<TOrderlineDetails>> => {
  return requests.get(`/orderline/details/${orderlineId}`);
};

export const renameOrderline = async (
  orderlineId: string,
  name: string
): Promise<ResponseType<{ msg: string }>> => {
  return requests.post(`/orderline/rename-orderline/${orderlineId}`, { name });
};

export const pauseOrderline = async (
  orderlineId: string
): Promise<ResponseType<{ message: string }>> => {
  return requests.post(`/orderline/${orderlineId}/pause`);
};

export const playOrderline = async (
  orderlineId: string
): Promise<ResponseType<{ message: string }>> => {
  return requests.post(`/orderline/${orderlineId}/play`);
};

export const editOrderlineCreatives = async (
  orderlineId: string,
  removed_creative_ids: string[],
  added_creative_ids: string[],
  clickThroughUrl: string
): Promise<ResponseType<{ msg: string }>> => {
  const click_through_url = returnHttpsPrefix(clickThroughUrl);
  return requests.put(`/orderline/${orderlineId}`, {
    added_creative_ids,
    removed_creative_ids,
    click_through_url,
  });
};

export const getAlerts = async (): Promise<ResponseType<THistoryAlert[]>> => {
  return requests.get(`/orderline/alerts`);
};

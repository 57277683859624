import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { GoalSectionTitles } from 'enums';
import { TTarget, TTargetAllGoalAudiences, TTargetAudiencesByGoal, TTargets } from 'types';
import AudienceTableBySelectedGoal from './AudienceTableBySelectedGoal';
import '../CampaignSetup.scss';

type AudiencesWithGoalsProps = {
  allAudienceData: TTargetAllGoalAudiences;
  selectedAudiences: TTargets;
  onSelect: (audienceList: TTarget[], goal: GoalSectionTitles) => void;
};

interface Section {
  title: string;
  content: ReactNode;
  audienceCount: number;
}

const sections = (
  campaignId: string | number | undefined,
  selectedAudiences: TTargets,
  onSelect: (audienceList: TTarget[], goal: GoalSectionTitles) => void,
  campaignGoal: GoalSectionTitles | null,
  goals: Record<GoalSectionTitles, TTargetAudiencesByGoal>
): Section[] => {
  const allSections: Section[] = [
    {
      title: GoalSectionTitles.CONQUEST_LEADS,
      audienceCount: goals[GoalSectionTitles.CONQUEST_LEADS].total,
      content: (
        <AudienceTableBySelectedGoal
          goal={GoalSectionTitles.CONQUEST_LEADS}
          campaignId={campaignId}
          selectedAudiences={selectedAudiences}
          onSelect={onSelect}
          initialData={goals[GoalSectionTitles.CONQUEST_LEADS]}
        />
      ),
    },
    {
      title: GoalSectionTitles.RETENTION,
      audienceCount: goals[GoalSectionTitles.RETENTION].total,
      content: (
        <AudienceTableBySelectedGoal
          goal={GoalSectionTitles.RETENTION}
          campaignId={campaignId}
          selectedAudiences={selectedAudiences}
          onSelect={onSelect}
          initialData={goals[GoalSectionTitles.RETENTION]}
        />
      ),
    },
    {
      title: GoalSectionTitles.PROMOTE_LISTING,
      audienceCount: goals[GoalSectionTitles.PROMOTE_LISTING].total,
      content: (
        <AudienceTableBySelectedGoal
          goal={GoalSectionTitles.PROMOTE_LISTING}
          campaignId={campaignId}
          selectedAudiences={selectedAudiences}
          onSelect={onSelect}
          initialData={goals[GoalSectionTitles.PROMOTE_LISTING]}
        />
      ),
    },
    {
      title: GoalSectionTitles.BRANDING_AWARENESS,
      audienceCount: goals[GoalSectionTitles.BRANDING_AWARENESS].total,
      content: (
        <AudienceTableBySelectedGoal
          goal={GoalSectionTitles.BRANDING_AWARENESS}
          campaignId={campaignId}
          selectedAudiences={selectedAudiences}
          onSelect={onSelect}
          initialData={goals[GoalSectionTitles.BRANDING_AWARENESS]}
        />
      ),
    },
  ];

  // Sort sections to move the selected goal to the start
  if (campaignGoal) {
    return [
      ...allSections.filter(section => section.title === campaignGoal),
      ...allSections.filter(section => section.title !== campaignGoal),
    ];
  }

  return allSections;
};
export default function AudiencesWithGoals({
  allAudienceData,
  selectedAudiences,
  onSelect,
}: AudiencesWithGoalsProps) {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});

  const { campaign_id, campaign_goal, goals, using_goals } = allAudienceData;

  useEffect(() => {
    const initialOpenSections = sections(
      campaign_id,
      selectedAudiences,
      onSelect,
      campaign_goal,
      goals
    ).reduce((acc, section) => {
      if (
        campaign_goal &&
        using_goals.length > 0 &&
        !using_goals.includes(campaign_goal as GoalSectionTitles)
      ) {
        // Open only sections from using_goals
        acc[section.title] = using_goals.includes(section.title as GoalSectionTitles);
      } else if (campaign_goal && using_goals.length === 0) {
        // Open the section of campaign_goal
        acc[section.title] = section.title === campaign_goal;
      } else if (!campaign_goal && using_goals.length > 0) {
        // Open only sections from using_goals
        acc[section.title] = using_goals.includes(section.title as GoalSectionTitles);
      } else if (!campaign_goal && using_goals.length === 0) {
        // Open the Conquest Leads section
        acc[section.title] = section.title === 'Conquest Leads';
      } else {
        // Open both campaign_goal and using_goals sections
        acc[section.title] =
          section.title === campaign_goal ||
          using_goals.includes(section.title as GoalSectionTitles);
      }
      return acc;
    }, {} as { [key: string]: boolean });

    setOpenSections(initialOpenSections);
  }, [allAudienceData, campaign_id, campaign_goal, using_goals]);

  const toggleSection = (title: string) => {
    setOpenSections(prevState => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <div className="section-wrapper">
      {sections(campaign_id, selectedAudiences, onSelect, campaign_goal, goals).map(
        ({ title, audienceCount, content }) => (
          <div key={title} className="goal-block">
            <label htmlFor={title}>
              <span className="section-title">
                {title}
                <span className="section-audience-count">
                  ({audienceCount} audience{audienceCount > 1 ? 's' : ''})
                </span>
              </span>
              <button
                onClick={() => toggleSection(title)}
                aria-label={`toggle ${title} section`}
                className="toggle-goal"
                id={title}
              >
                <FontAwesomeIcon icon={openSections[title] ? faCaretUp : faCaretDown} />
              </button>
            </label>
            {openSections[title] && <div>{content}</div>}
          </div>
        )
      )}
    </div>
  );
}

import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode, Dispatch, SetStateAction } from 'react';

import { useSelector } from 'react-redux';

import { checkAuthorizationStatus } from 'canva-integration/services';

import type { TRootState } from 'types';

export type PropsWithChildren = {
  children: ReactNode;
};

type CanvaIntegrationContextType = {
  isAuthorized: boolean;
  setIsAuthorized: Dispatch<SetStateAction<boolean>>;
  displayName: string;
  setDisplayName: Dispatch<SetStateAction<string>>;
};

const defaultValues: CanvaIntegrationContextType = {
  isAuthorized: false,
  setIsAuthorized: () => {},
  displayName: '',
  setDisplayName: () => {},
};

const CanvaIntegrationContext = createContext<CanvaIntegrationContextType>(defaultValues);

export const useCanvaIntegrationContext = () => useContext(CanvaIntegrationContext);

const CanvaIntegrationContextProvider = ({ children }: PropsWithChildren) => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const { isAuthorized } = await checkAuthorizationStatus();
        setIsAuthorized(isAuthorized);
      } catch (error) {
        console.error(error);
      }
    };

    if (userData) checkAuthorization();
  }, [userData]);

  const value: CanvaIntegrationContextType = {
    isAuthorized,
    setIsAuthorized,
    displayName,
    setDisplayName,
  };

  return (
    <CanvaIntegrationContext.Provider value={value}>{children}</CanvaIntegrationContext.Provider>
  );
};

export default CanvaIntegrationContextProvider;

import React, { useState } from 'react';

import toast from 'react-hot-toast';
import classnames from 'classnames';

import Tooltip from 'rc-tooltip';
import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import { ErrorMsg, InputBox } from 'Components';
import ChooseFromCreativesLibraryModal from 'Pages/CampaignCreation/components/CampaignCreatives/_components/ChooseFromCreativesLibraryModal';
import { checkName, creativeClickUrl } from 'Pages/OrderlineDetails/helper';

import { TCreative, TOrderlineCreative, TOrderlineDetails } from 'types';
import { CreativeTypeEnum } from 'enums';
import { validateURL } from 'Helpers';

import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faLink, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { editOrderlineCreatives } from 'Requests/Request_Methods/orderlineMethods';

import './OrderlineCreativeEditModal.scss';

interface OrderlineCretiveEditModalProps
  extends Pick<TOrderlineDetails, 'clickThroughUrl' | 'creatives' | 'type'> {
  onCancel: () => void;
  onSave: () => void;
  orderlineId: string;
}
const OrderlineCretiveEditModal = ({
  onCancel,
  onSave,
  creatives,
  clickThroughUrl,
  type,
  orderlineId,
}: OrderlineCretiveEditModalProps) => {
  const [clickUrl, setClickUrl] = useState<string>(clickThroughUrl);
  const [isError, setIsError] = useState(false);
  const [creativeList, setCreativeList] = useState<(TCreative | TOrderlineCreative)[]>(creatives);
  const [creativesLibraryModalOpen, setCreativesLibraryModalOpen] = useState<boolean>(false);
  const [editCreativesLoading, setEditCreativesLoading] = useState(false);
  const onChangeUrl = (e: any) => {
    if (e.target.value.length <= 4 || !validateURL(e.target.value)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setClickUrl(e.target.value);
  };
  const onCancelEdit = () => {
    setClickUrl(clickThroughUrl);
    setIsError(false);
    onCancel();
  };

  const onRemoveCreative = (creativeUUId: string) => {
    if (creativeList.find(creative => creative.creative_uuid === creativeUUId)) {
      const newCreatives = creativeList.filter(creative => creative.creative_uuid !== creativeUUId);
      setCreativeList(newCreatives);
    }
  };

  const _fetchEditOrderlineCreatives = async (
    deletedCreatives: string[],
    addedCreatives: string[]
  ) => {
    setEditCreativesLoading(true);
    try {
      if (clickUrl) {
        await editOrderlineCreatives(orderlineId, deletedCreatives, addedCreatives, clickUrl);
        setEditCreativesLoading(false);
        onSave();
      }
    } catch (err: any) {
      if (err.detail) {
        toast.error(err.detail);
      } else {
        toast.error('Something went wrong!');
      }
      setEditCreativesLoading(false);
    } finally {
      setEditCreativesLoading(false);
      onCancel();
    }
  };

  const onSaveCreative = () => {
    const deletedCreatives = creatives
      .filter(
        ({ creative_uuid }) =>
          !creativeList.find(creative => creative.creative_uuid === creative_uuid)
      )
      .map(({ creative_uuid }) => creative_uuid);
    const addedCreatives = creativeList
      .filter(
        ({ creative_uuid }) => !creatives.find(creative => creative.creative_uuid === creative_uuid)
      )
      .map(({ creative_uuid }) => creative_uuid);
    _fetchEditOrderlineCreatives(deletedCreatives, addedCreatives);
  };

  return (
    <Modal className="edit-creative-modal">
      <Button onClick={onCancelEdit} UNSAFE_className="close_cross">
        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer', fontSize: '24px' }} />
      </Button>
      <Text
        on="white"
        weight="bold"
        textAlign="center"
        UNSAFE_style={{ color: '#2B1F0A', fontSize: 24, margin: '1.5rem 0 1rem', display: 'block' }}
      >
        Edit Creatives
      </Text>
      <FlexBox alignItems="center" gap="4px" UNSAFE_className="url_wrapper">
        <Text
          UNSAFE_className="digital_banner_title"
          tag="div"
          on="white"
          size="l"
          UNSAFE_style={{ color: '#2B1F0A' }}
        >
          {checkName(type)}
          <span style={{ color: 'red' }}>*</span>
        </Text>
        <Tooltip
          placement="topLeft"
          trigger="hover"
          overlayClassName={classnames('audience-tooltip', 'creative-url')}
          showArrow
          align={{
            offset: [-20, -3],
            targetOffset: [-6, 0],
          }}
          overlay="This is the link that takes users to your landing page when they click your ad."
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 16, color: '#4F4F4F' }} />
        </Tooltip>
      </FlexBox>
      <>
        <InputBox
          wdt="100%"
          Input__ClassName="link_input_field"
          value={creativeClickUrl(clickUrl)}
          prefix="https://"
          placeHolder="www.example.com"
          onChange={onChangeUrl}
          UNSAFE_CLASS_icon="link_input_field_prefix"
          UNSAFE_CLASS_text="link_input_field_err"
          UNSAFE_CLASS_Boolean={false}
          UNSAFE_CLASS_INPUT_BOX="link_input_field_wrapper"
          icon={<FontAwesomeIcon icon={faLink} className="AttachedCreatives__icon" />}
          isError={isError}
          validIcon={
            <abbr title="https://www.example.com">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{
                  fontSize: '23px',
                }}
              />
            </abbr>
          }
        />
        {isError && (
          <ErrorMsg
            UNSAFE_CLASS_icon="creative-error"
            title="Please enter url"
            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
          />
        )}
        {creativeList.length > 0 && (
          <FlexBox UNSAFE_className="creative_images" gap="16px">
            {creativeList.map(creative => {
              return (
                <div className="creative_content" key={creative.creative_uuid}>
                  <span className="creative_title" title={creative.name}>
                    {creative.name}
                  </span>
                  <div className="creative_img_wrapper">
                    <div className="creative_img">
                      <div className="creative_action">
                        <button
                          className="action"
                          onClick={() => onRemoveCreative(creative.creative_uuid)}
                        >
                          <FontAwesomeIcon icon={faTrash} style={{ fontSize: '24px' }} />
                        </button>
                      </div>

                      <img
                        src={
                          (creative.creative_type === CreativeTypeEnum.VIDEO
                            ? creative.thumb_presigned_url
                            : creative.presigned_url) ?? ''
                        }
                        alt={creative.name}
                        className="creative_image"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </FlexBox>
        )}
        <FlexBox
          justifyContent={creativeList.length ? 'justify-start' : 'center'}
          alignItems="center"
          UNSAFE_style={{
            height: !creativeList.length ? '240px' : 0,
            marginBlock: !creativeList.length ? '32px' : 0,
            backgroundColor: !creativeList.length ? '#F6F6F6' : '#FEFEFE',
          }}
        >
          <Button
            kind="link"
            size="m"
            weight="bold"
            iconLeft={<FontAwesomeIcon icon={faPlus} fontSize={16} />}
            onClick={() => setCreativesLibraryModalOpen(true)}
          >
            Choose Creatives
          </Button>
        </FlexBox>
      </>

      <div className="confirm-modal-footer">
        <Button size="l" onClick={onCancelEdit} weight="bold">
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          onClick={onSaveCreative}
          loading={editCreativesLoading}
          disabled={!clickUrl?.length || isError || !creativeList.length || editCreativesLoading}
        >
          Confirm
        </Button>
      </div>
      {creativesLibraryModalOpen && (
        <ChooseFromCreativesLibraryModal
          campaignCreatives={creativeList}
          type={type === CreativeTypeEnum.BANNER ? CreativeTypeEnum.BANNER : CreativeTypeEnum.VIDEO}
          open={creativesLibraryModalOpen}
          onCancel={() => setCreativesLibraryModalOpen(false)}
          onConfirm={creative => {
            setCreativeList(prev => [...creative, ...prev]);
          }}
        />
      )}
    </Modal>
  );
};

export default OrderlineCretiveEditModal;

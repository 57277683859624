import React from 'react';

import classnames from 'classnames';

import Tooltip from 'rc-tooltip';

import { FlexBox, Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import './AddAudiencesModal.scss';

type HeadingContentProps = {
  currentProspectCount: number;
  selectedProspectCount: number;
};
const HeadingContent = ({ currentProspectCount, selectedProspectCount }: HeadingContentProps) => {
  return (
    <FlexBox justifyContent="space-between" gap="32px">
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        UNSAFE_className="prospect_count_item"
        flexGrow={1}
      >
        <Text on="white">Current Prospect Count</Text>
        <Text on="white" weight="bold" UNSAFE_style={{ color: '#2B1F0A', fontSize: 16 }}>
          {currentProspectCount} prospect{currentProspectCount > 1 ? 's' : ''}
        </Text>
      </FlexBox>
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        UNSAFE_className="prospect_count_item"
        flexGrow={1}
      >
        <FlexBox alignItems="center" gap="4px">
          <Text on="white">Selected Prospect Count</Text>{' '}
          <Tooltip
            placement="topLeft"
            trigger="hover"
            overlayClassName={classnames('audience-tooltip', 'creative-url')}
            showArrow
            align={{
              offset: [-20, -3],
              targetOffset: [-6, 0],
            }}
            overlay="Your selected prospect count should be equal or grater than your current prospect count."
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 16, color: '#000' }} />
          </Tooltip>
        </FlexBox>
        <Text on="white" weight="bold" UNSAFE_style={{ color: '#2B1F0A', fontSize: 16 }}>
          {selectedProspectCount} prospect{selectedProspectCount > 1 ? 's' : ''}
        </Text>
      </FlexBox>
    </FlexBox>
  );
};

export default HeadingContent;

import React from 'react';

import classNames from 'classnames';

interface ColorFieldProps {
  color: string;
  isSelected: boolean;
  onSelectColor: (color: string) => void;
}

export default function ColorField({ color, isSelected, onSelectColor }: ColorFieldProps) {
  return (
    <div className={classNames('color-field-wrapper', { selected: isSelected })}>
      <button
        aria-label="color-picker"
        className="color-field"
        style={{ background: color }}
        onClick={() => onSelectColor(color)}
      />
    </div>
  );
}

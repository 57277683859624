import React, { useState } from 'react';
import { usePermissionContext } from 'contexts/PermissionContext';

import { ConfirmationModal, EmptyStatus, ReactTable } from 'Components';

import { getCampaignColumns } from 'Pages/Orderlines/helpers';

import type { TCampaign, TOrderline, TResPagination } from 'types';

import './Orderlines.scss';

type CampaignsListProps = {
  campaigns: TCampaign[];
  fetchDataIsLoading: boolean;
  campaignPagination: TResPagination;
  onDelete: (_items: TCampaign[]) => Promise<void>;
  deleteModalIsOpen: boolean;
  setDeleteModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteIsLoading: boolean;
  campaignPage: number;
  setCampaignPage: React.Dispatch<React.SetStateAction<number>>;
  changeRoutePath: (actionItem: TCampaign | TOrderline) => void;
  searchValue: string;
};
export default function CampaignsList({
  campaigns,
  fetchDataIsLoading,
  campaignPagination,
  onDelete,
  deleteModalIsOpen,
  setDeleteModalIsOpen,
  deleteIsLoading,
  campaignPage,
  setCampaignPage,
  changeRoutePath,
  searchValue,
}: CampaignsListProps) {
  const { userIsActive } = usePermissionContext();
  const [deleteCampaignData, setDeleteCampaignData] = useState<TCampaign[]>([]);

  return (
    <div className="Campaigns">
      <ReactTable<TCampaign>
        title="campaign"
        loading={fetchDataIsLoading}
        deleteModalNote="Only draft campaigns can be deleted"
        deleteModalHeader="Are you sure you want to delete the selected campaign(s)?"
        emptyText={
          <EmptyStatus
            heading={
              searchValue && !campaigns.length
                ? 'No results found'
                : 'You don’t have any campaigns yet.'
            }
            subHeading={
              searchValue && !campaigns.length
                ? 'You might consider trying different keywords, double-checking for any spelling errors, or adjusting your filters.'
                : 'You can try by creating new campaign to get better audience.'
            }
          />
        }
        data={campaigns}
        onDelete={onDelete}
        deleteModal={deleteModalIsOpen}
        setDeleteModal={setDeleteModalIsOpen}
        deleteIsLoading={deleteIsLoading}
        pageSize={50}
        totalCount={campaignPagination.total_count}
        pageCount={campaignPagination.total_pages}
        currentPage={campaignPage}
        setPage={setCampaignPage}
        columns={getCampaignColumns(userIsActive, changeRoutePath, (campaign: TCampaign) =>
          setDeleteCampaignData([campaign])
        )}
      />

      {deleteCampaignData.length === 1 && (
        <ConfirmationModal
          heading="Are you sure you want to delete the selected campaign(s)?"
          note="Only draft campaigns can be deleted"
          onSubmit={() => onDelete(deleteCampaignData)}
          onCancel={() => setDeleteCampaignData([])}
          loading={deleteIsLoading}
          okText="Yes, Delete"
          cancelText="No, Thanks"
          deletion
        />
      )}
    </div>
  );
}

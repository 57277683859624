import React from 'react';

import { orderlineStatusTagColors } from 'Pages/OrderlineDetails/components/StatusTag/constants';

import { OrderlineStatusEnum } from 'enums';

import './StatusTag.scss';

interface StatusTagProps {
  status: OrderlineStatusEnum;
}

export default function StatusTag({ status }: StatusTagProps) {
  const { background, color } = orderlineStatusTagColors[status];

  return (
    <div className="orderline-status-tag" style={{ background }}>
      <span className="orderline-status-text" style={{ color }}>
        {status}
      </span>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useOnRouteChange = (execute: () => void) => {
  const [currentPathname, setCurrentPathname] = useState('');
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    setCurrentPathname(pathname);
    if (pathname !== currentPathname) execute();
  }, [pathname]);
};

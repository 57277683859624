import React from 'react';

import { FlexBox } from '@eltoro-ui/components';

import {
  EPerformanceStat,
  performanceStatIcon,
} from 'Pages/OrderlineDetails/components/PerformanceMetrics/components/PerformanceStat/constants';

import './PerformanceStat.scss';

interface PerformanceStatProps {
  name: EPerformanceStat;
  value: string | number;
}

export default function PerformanceStat({ name, value }: PerformanceStatProps) {
  return (
    <FlexBox alignItems="flex-start" gap="12px" UNSAFE_className="performance-stat-container">
      {performanceStatIcon[name]}
      <FlexBox flexDirection="column" gap="0.5rem">
        <span className="performance-stat-name">{name}</span>
        <span className="performance-stat-value">{value}</span>
      </FlexBox>
    </FlexBox>
  );
}

import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';

import classNames from 'classnames';
import moment from 'moment/moment';

import Tooltip from 'rc-tooltip';
import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

import { Button, FlexBox } from '@eltoro-ui/components';

import StatusTag from 'Pages/OrderlineDetails/components/StatusTag';
import OrderlineActions from 'Pages/OrderlineDetails/components/OrderlineHeader/components/OrderlineActions';

import { OrderlineStatusEnum } from 'enums';
import type { TOrderlineDetails } from 'types';
import { submitOrderline } from 'Requests/Request_Methods/audienceMethods';
import { usePermissionContext } from 'contexts/PermissionContext';

interface OrderlineHeaderProps
  extends Pick<
    TOrderlineDetails,
    | 'name'
    | 'status'
    | 'end_date'
    | 'start_date'
    | 'total_days'
    | 'total_impressions'
    | 'served_days'
    | 'served_impressions'
    | 'type'
    | 'targets'
    | 'is_edited'
  > {
  setOrderline: Dispatch<SetStateAction<TOrderlineDetails | null>>;
  getOrderline: () => Promise<void>;
  generatePDF: () => Promise<void>;
  updateOrderlineData: () => void;
  orderlineId: string;
}

export default function OrderlineHeader({
  name,
  status,
  start_date,
  end_date,
  total_days,
  total_impressions,
  served_days,
  served_impressions,
  type,
  targets,
  setOrderline,
  getOrderline,
  generatePDF,
  updateOrderlineData,
  is_edited,
  orderlineId,
}: OrderlineHeaderProps) {
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  const endDate = moment(end_date);
  const startDate = moment(start_date);

  const flightDates = `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`;
  const [resubmitLoading, setResubmitLoading] = useState(false);
  const resubmit = () => {
    setResubmitLoading(true);
    submitOrderline(orderlineId)
      .then(() => {
        updateOrderlineData();
      })
      .catch(err => {
        if (err.detail) {
          toast.error(err.detail);
        } else {
          toast.error('Something went wrong!');
        }
      })
      .finally(() => {
        setResubmitLoading(false);
      });
  };

  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      UNSAFE_className="orderline-details-header"
    >
      <FlexBox flexDirection="column" gap="12px">
        <FlexBox alignItems="center" gap="12px">
          <span className="orderline-details-name">{name}</span>
          {status === OrderlineStatusEnum.PAUSED && endDate.isBefore(moment()) && (
            <Tooltip
              placement="topLeft"
              trigger="hover"
              overlayClassName={classNames('audience-tooltip', 'save-audience-info')}
              showArrow
              align={{
                offset: [-15, -3],
                targetOffset: [-6, 0],
              }}
              overlay="You orderline end date is passed, Please contact support at help@beewo.com for assistance."
              getTooltipContainer={() => document.body}
            >
              <span style={{ cursor: 'pointer', display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ fontSize: 18, color: '#FFAB03' }}
                />
              </span>
            </Tooltip>
          )}
          <StatusTag status={status} />
        </FlexBox>
        <span className="orderline-details-flight_dates">
          Flight Dates: <b>{flightDates}</b>
        </span>
      </FlexBox>
      <FlexBox>
        {status === OrderlineStatusEnum.REJECTED && is_edited && (
          <Button
            key="resubmit"
            kind="primary"
            size="l"
            weight="bold"
            onClick={resubmit}
            loading={resubmitLoading}
            disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed || resubmitLoading}
          >
            Resubmit
          </Button>
        )}
        <OrderlineActions
          name={name}
          status={status}
          start_date={start_date}
          end_date={end_date}
          total_days={total_days}
          total_impressions={total_impressions}
          served_days={served_days}
          served_impressions={served_impressions}
          type={type}
          targets={targets}
          setOrderline={setOrderline}
          getOrderline={getOrderline}
          generatePDF={generatePDF}
          updateOrderlineData={updateOrderlineData}
        />
      </FlexBox>
    </FlexBox>
  );
}

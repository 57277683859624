import React, { useEffect, useMemo, useState } from 'react';

import { PhoneNumberUtil } from 'google-libphonenumber';

import type { InputTextProps } from 'primereact/inputtext';

import { useForm } from '@eltoro-ui/hooks';
import { FlexBox } from '@eltoro-ui/components';

import { useMagicCreativeContext } from 'contexts/MagicCreativeContext';

import BannerBackgroundColorPicker from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/BannerBackgroundColorPicker';
import TextInput from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/TextInput';
import CreativesGeneratorUploader from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/CreativesGeneratorUploader';
import CropImageModal from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/CreativesGeneratorUploader/components/CropImageModal';

import agentPhotoLogo from 'assets/magic-generator/agent-photo.svg';
import listingPhotoLogo from 'assets/magic-generator/listing-photo.svg';
import agencyPhotoLogo from 'assets/magic-generator/agency-logo.svg';

import { ECreativeUploadTitles } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/enum';
import { GoalSectionTitles } from 'enums';

import './FillDetails.scss';

const agentFormGoals = [GoalSectionTitles.CONQUEST_LEADS, GoalSectionTitles.RETENTION];

const phoneUtil = PhoneNumberUtil.getInstance();

export default function FillDetails() {
  const {
    formState: [formState, setFormState],
    isValidForm: [, setIsValidForm],
    selectedGoal,
  } = useMagicCreativeContext();

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(true);

  const { formData, isValidForm, touched } = useForm({ ...formState });

  const agentNameError = useMemo(() => {
    if (touched.includes('agent_name')) {
      if (!formData.agent_name) return 'Agent name cannot be empty.';
      if (formData.agent_name.length > 40) return 'Max character count is 40.';
    }

    return '';
  }, [touched, formData.agent_name]);

  const agentIdError = useMemo(() => {
    if (touched.includes('agent_id')) {
      if (formData.agent_id?.length > 40) return 'Max character count is 40.';
    }
    return '';
  }, [touched, formData.agent_id]);

  const agentPhoneNumberError = useMemo(() => {
    if (touched.includes('agent_phone')) {
      if (!formData.agent_phone) return 'Agent Phone Number cannot be empty.';
      if (!isValidPhoneNumber) return 'Invalid Phone number';
    }
    return '';
  }, [touched, formData.agent_phone]);

  const listingAddressError = useMemo(() => {
    if (formData.type === 'listing') {
      if (touched.includes('listing_address') && !formData.listing_address)
        return 'Listing Address cannot be empty.';
    }
    return '';
  }, [touched, formData]);

  const realEstateAgencyError = useMemo(() => {
    if (touched.includes('real_estate_agency')) {
      if (!formData.real_estate_agency) return 'Real estate agency cannot be empty.';
      if (formData.real_estate_agency?.length > 40) return 'Max character count is 40.';
    }
    return '';
  }, [touched, formData]);

  const bedsError = useMemo(() => {
    if (formData.type === 'listing') {
      if (touched.includes('bed') && !formData.bed) return 'Number of Bed(s) cannot be empty.';
    }
    return '';
  }, [touched, formData]);

  const bathsError = useMemo(() => {
    if (formData.type === 'listing') {
      if (touched.includes('bath') && !formData.bath) return 'Number of Baths(s) cannot be empty.';
    }
    return '';
  }, [touched, formData]);

  const squareFootError = useMemo(() => {
    if (formData.type === 'listing') {
      if (touched.includes('sqft') && !formData.sqft) return 'Square foot cannot be empty.';
    }
    return '';
  }, [touched, formData]);

  const callActionError = useMemo(() => {
    if (formData.type === 'listing') {
      if (touched.includes('call_to_action') && formData.call_to_action?.length > 20)
        return 'Max character count is 20.';
    }
    return '';
  }, [touched, formData]);

  const agentWordsError = useMemo(() => {
    if (formData.type === 'agent') {
      if (touched.includes('agent_word')) {
        if (!formData.agent_word) return 'Agent words cannot be empty.';
        if (formData.agent_word.length > 40) return 'Max character count is 40.';
      }
    }
    return '';
  }, [touched, formData]);

  const teamSloganError = useMemo(() => {
    if (formData.type === 'agent') {
      if (touched.includes('team_slogan')) {
        if (!formData.team_slogan) return 'Team slogan cannot be empty.';
        if (formData.team_slogan.length > 40) return 'Max character count is 40.';
      }
    }
    return '';
  }, [touched, formData]);

  const requiredFields =
    formData.type === 'agent'
      ? ['agent_word', 'team_slogan', 'background_color']
      : ['listing_address', 'bed', 'bath', 'sqft'];

  const fieldsValidationChecks =
    formData.type === 'agent'
      ? [!agentWordsError, !teamSloganError]
      : [!listingAddressError, !bedsError, !bathsError, !squareFootError, !callActionError];

  const { validForm } = isValidForm([
    ...requiredFields,
    'agentPhoto',
    'listingPhoto',
    'agencyLogo',
    'agent_name',
    'agent_phone',
    'real_estate_agency',
    !realEstateAgencyError,
    !agentNameError,
    !agentIdError,
    isValidPhoneNumber,
    ...fieldsValidationChecks,
  ]);

  useEffect(() => {
    setIsValidForm(validForm);
  }, [formState]);

  const onChangeAgentName: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, agent_name: value }));

  const onChangeAgentPhoneNumber: InputTextProps['onChange'] = ({ target: { value } }) => {
    let phoneInput = value.replace(/[^0-9() -]+/g, '');
    setFormState(prevState => ({ ...prevState, agent_phone: phoneInput }));

    if (value) {
      try {
        const number = phoneUtil.parseAndKeepRawInput(phoneInput, 'US');
        setIsValidPhoneNumber(phoneUtil.isValidNumberForRegion(number, 'US'));
      } catch (e) {
        setIsValidPhoneNumber(false);
      }
    } else setIsValidPhoneNumber(true);
  };

  const onSelectBackgroundColor = (color: string) =>
    setFormState(prevState => ({ ...prevState, background_color: color }));

  const onChangeAgentWords: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, agent_word: value }));

  const onChangeTeamSlogan: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, team_slogan: value }));

  const onChangeRealEstateAgency: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, real_estate_agency: value }));

  const onChangeAgentId: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, agent_id: value }));

  const onChangeCallAction: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, call_to_action: value }));

  const onChangeListingAddress: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, listing_address: value }));

  const onChangeBeds: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, bed: value.replace(/[^0-9() -]+/g, '') }));

  const onChangeBaths: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, bath: value.replace(/[^0-9() -]+/g, '') }));

  const onChangeSquareFoot: InputTextProps['onChange'] = ({ target: { value } }) =>
    setFormState(prevState => ({ ...prevState, sqft: value }));

  const onUploadAgentPhoto = (file: File) =>
    setFormState(prevState => ({ ...prevState, agentPhoto: file }));

  const onUploadAgencyLogo = (file: File) =>
    setFormState(prevState => ({ ...prevState, agencyLogo: file }));

  const onUploadListingPhoto = (file: File) => {
    setFormState(prevState => ({ ...prevState, listingPhoto: file }));
    setCropModalOpen(true);
  };

  const onCropFile = (file: File) => {
    setFormState(prevState => ({ ...prevState, listingPhotoCropped: file }));
    setCropModalOpen(false);
  };

  return (
    <FlexBox flexDirection="column" gap="12px" UNSAFE_className="creative-generator-form">
      <span className="images-info">Please upload the required images</span>
      <FlexBox flexDirection="column" UNSAFE_className="fields-container" gap="1rem">
        <FlexBox justifyContent="space-between" alignItems="center">
          <CreativesGeneratorUploader
            title={ECreativeUploadTitles.AGENT_PHOTO}
            file={formState.agentPhoto}
            onUpload={onUploadAgentPhoto}
          >
            <FlexBox alignItems="center" justifyContent="center">
              <img src={agentPhotoLogo} alt="creative-logo" className="creative-uploader-logo" />
            </FlexBox>
          </CreativesGeneratorUploader>
          <CreativesGeneratorUploader
            title={ECreativeUploadTitles.LISTING_PHOTO}
            file={formState.listingPhoto}
            onUpload={onUploadListingPhoto}
            hasCropper
            openCropModal={() => setCropModalOpen(true)}
          >
            <FlexBox alignItems="center" justifyContent="center">
              <img src={listingPhotoLogo} alt="creative-logo" className="creative-uploader-logo" />
            </FlexBox>
          </CreativesGeneratorUploader>
          {cropModalOpen && formState.listingPhoto && (
            <CropImageModal file={formState.listingPhoto} onCropFile={onCropFile} />
          )}
          <CreativesGeneratorUploader
            title={ECreativeUploadTitles.AGENCY_LOGO}
            file={formState.agencyLogo}
            onUpload={onUploadAgencyLogo}
          >
            <FlexBox alignItems="center" justifyContent="center">
              <img src={agencyPhotoLogo} alt="creative-logo" className="creative-uploader-logo" />
            </FlexBox>
          </CreativesGeneratorUploader>
        </FlexBox>
        <FlexBox flexDirection="column" gap="8px">
          <TextInput
            label="Agent Name"
            placeholder="Enter Agent Name"
            name="agent-name"
            value={formState.agent_name}
            onChange={onChangeAgentName}
            error={agentNameError}
            required
          />
          <TextInput
            label="Agent ID"
            placeholder="Enter Agent ID"
            name="agent-id"
            value={formState.agent_id}
            onChange={onChangeAgentId}
            error={agentIdError}
            optional
          />
          <TextInput
            label="Agent Phone Number"
            placeholder="Enter Agent Phone Number"
            name="agent-phone-number"
            value={formState.agent_phone}
            onChange={onChangeAgentPhoneNumber}
            error={agentPhoneNumberError}
            required
          />
          <TextInput
            label="Real Estate Agency"
            placeholder="Enter Real Estate Agency"
            name="real-estate-agency"
            value={formState.real_estate_agency}
            onChange={onChangeRealEstateAgency}
            error={realEstateAgencyError}
            required
          />
          {agentFormGoals.includes(selectedGoal.name) && formState.type === 'agent' ? (
            <>
              <TextInput
                label="Agent words"
                placeholder="eg. Contact me for a free market evaluation!"
                name="agent-words"
                value={formState.agent_word}
                onChange={onChangeAgentWords}
                error={agentWordsError}
                required
              />
              <TextInput
                label="Team slogan"
                placeholder="eg. Sell with the #1 team in Miami!"
                name="team-slogan"
                value={formState.team_slogan}
                onChange={onChangeTeamSlogan}
                error={teamSloganError}
                required
              />
            </>
          ) : formState.type === 'listing' ? (
            <>
              <TextInput
                label="Listing Address"
                placeholder="Enter Listing Address"
                name="listing-address"
                value={formState.listing_address}
                onChange={onChangeListingAddress}
                error={listingAddressError}
                required
              />
              <FlexBox
                alignItems="center"
                justifyContent="space-between"
                UNSAFE_style={{ width: '100%' }}
              >
                <TextInput
                  label="Number of Bed(s)"
                  placeholder="Enter Number of Bed(s)"
                  name="bed"
                  value={formState.bed}
                  onChange={onChangeBeds}
                  error={bedsError}
                  required
                />
                <TextInput
                  label="Number of Bath(s)"
                  placeholder="Enter Number of Bath(s)"
                  name="bath"
                  value={formState.bath}
                  onChange={onChangeBaths}
                  error={bathsError}
                  required
                />
                <TextInput
                  label="Square foot"
                  placeholder="Enter Square foot"
                  name="sqft"
                  value={formState.sqft}
                  onChange={onChangeSquareFoot}
                  error={squareFootError}
                  required
                />
              </FlexBox>
              <TextInput
                label="Call to action"
                placeholder="Take a Tour"
                name="call_to_action"
                value={formState.call_to_action}
                onChange={onChangeCallAction}
                error={callActionError}
                optional
              />
            </>
          ) : null}
          <BannerBackgroundColorPicker
            selectedColor={formState.background_color}
            onSelectColor={onSelectBackgroundColor}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

import React, { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import classNames from 'classnames';

import { Button, Modal } from '@eltoro-ui/components';
import { LabelInputField, TextInputType } from 'Components/LabelInputField';

import { renameOrderline } from 'Requests/Request_Methods/orderlineMethods';

import type { TOrderline } from 'types';

import './RenameOrderlineModal.scss';

interface RenameOrderlineModalProps {
  orderline_uuid: TOrderline['orderline_uuid'];
  name: string;
  onCancel: VoidFunction;
  onSuccess?: (name: string) => void;
}

export default function RenameOrderlineModal({
  orderline_uuid,
  name,
  onSuccess,
  onCancel,
}: RenameOrderlineModalProps) {
  const [orderlineName, setOrderlineName] = useState<string>(name);
  const [editOrderlineNameLoading, setEditOrderlineLoading] = useState<boolean>(false);

  const isValidName = useMemo(() => !!orderlineName.trim(), [orderlineName]);

  const onChangeOrderlineName: TextInputType['onChange'] = ({ target: { value } }) =>
    setOrderlineName(value);

  const onSaveOrderlineName = async () => {
    try {
      setEditOrderlineLoading(true);

      await renameOrderline(orderline_uuid, orderlineName);
      toast.success('Orderline updated successfully!');

      onSuccess?.(orderlineName);
      onCancel();
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
    } finally {
      setEditOrderlineLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setEditOrderlineLoading(false);
    };
  }, []);

  return (
    <Modal className="confirm-modal rename-creative-modal">
      <div className="confirm-modal-content">
        <span className="confirm-modal-title">Rename orderline</span>
        <div className="confirm-modal-body">
          <LabelInputField
            title="Orderline Name"
            placeholder="Enter Orderline Name"
            value={orderlineName}
            type="text"
            style={{ paddingLeft: '22px' }}
            lableStyle={{ left: '14px' }}
            onChange={onChangeOrderlineName}
            UNSAFE_ClassName={classNames('creative-name-input')}
          />
        </div>
      </div>
      <div className="confirm-modal-footer">
        <Button
          size="l"
          onClick={!editOrderlineNameLoading ? onCancel : () => undefined}
          weight="bold"
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          onClick={onSaveOrderlineName}
          loading={editOrderlineNameLoading}
          disabled={editOrderlineNameLoading || !isValidName || orderlineName === name}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import Result from 'Pages/OrderlineDetails/components/AttachedAudiences/components/EditAudiencesModal/Result';
import AttachedAudiencesContent from 'Pages/OrderlineDetails/components/AttachedAudiences/components/EditAudiencesModal/AttachedAudiencesContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { TOrderlineDetails, TOrderlineTarget } from 'types';
import { reduceProspects } from 'Utils/helpers';
import { editAudiencesFromOrderline } from 'Requests/Request_Methods/audienceMethods';

import './EditAudiencesModal.scss';

interface EditAudiencesModalProps
  extends Pick<
    TOrderlineDetails,
    | 'targets'
    | 'type'
    | 'total_impressions'
    | 'total_days'
    | 'served_days'
    | 'budget'
    | 'served_impressions'
  > {
  orderlineId: string;
  onCancel: () => void;
  updateOrderlineData: () => void;
}
const EditAudiencesModal = ({
  orderlineId,
  targets,
  onCancel,
  type,
  total_impressions,
  total_days,
  served_days,
  budget,
  served_impressions,
  updateOrderlineData,
}: EditAudiencesModalProps) => {
  const [newBudget, setNewBudget] = useState(0);
  const [targetAudiences, setTargetAudiences] = useState(targets);
  const [attachedAudience, setAttachedAudiences] = useState<TOrderlineTarget[]>([]);
  const [detachedAudience, setDetachedAudiences] = useState<TOrderlineTarget[]>([]);
  const [totalProspectCount, setTotalProspectCount] = useState(reduceProspects(targets));
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleNewBudget = (cost: number) => {
    setNewBudget(cost);
  };

  const updateTargetAudiencesAfterError = () => {
    setTargetAudiences(targets);
    setTotalProspectCount(reduceProspects(targets));
  };
  useEffect(() => {
    const deletedList = targets.filter(
      target => !targetAudiences.some(audience => audience.id === target.id)
    );

    const attachedList = targetAudiences.filter(
      audience => !targets.some(target => target.id === audience.id)
    );

    setDetachedAudiences(deletedList);
    setAttachedAudiences(attachedList);
  }, [targets, targetAudiences]);

  const postAudienceEditedBudget = (seletedCardId: any) => {
    setSubmitLoading(true);
    editAudiencesFromOrderline(orderlineId, {
      added_audience_ids: attachedAudience.map(aud => aud.id),
      removed_audience_ids: detachedAudience.map(aud => aud.id),
      payment_source_id: seletedCardId,
      showen_budget: newBudget,
    })
      .then(() => {
        onCancel();
        updateOrderlineData();
      })
      .catch(err => {
        if (err.detail) {
          toast.error(err.detail);
        } else {
          toast.error('Something went wrong!');
        }
        if (updateTargetAudiencesAfterError) updateTargetAudiencesAfterError();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <Modal closable={false} className="budget_adv_control_modal">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        gap="46px"
        UNSAFE_style={{ width: '825px' }}
        UNSAFE_className="budget_wrapper"
      >
        <Button onClick={onCancel} UNSAFE_className="budget_cross">
          <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer', fontSize: '24px' }} />
        </Button>
        <FlexBox
          flexDirection="column"
          UNSAFE_style={{ width: '100%', position: 'relative' }}
          gap="20px"
        >
          <Text
            on="white"
            weight="bold"
            textAlign="center"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 24, margin: '1.5rem 0 1rem' }}
          >
            Edit Audiences
          </Text>
          <AttachedAudiencesContent
            targets={targets}
            targetAudiences={targetAudiences}
            type={type}
            total_impressions={total_impressions}
            total_days={total_days}
            served_days={served_days}
            budget={budget}
            handleNewBudget={handleNewBudget}
            setTargetAudiences={setTargetAudiences}
            served_impressions={served_impressions}
            totalProspectCount={totalProspectCount}
            setTotalProspectCount={setTotalProspectCount}
          />
          <Result
            cost={newBudget}
            closeModal={onCancel}
            confirmEdit={postAudienceEditedBudget}
            submitLoading={submitLoading}
            canConfirmEditAudience={attachedAudience?.length > 0 || detachedAudience?.length > 0}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default EditAudiencesModal;
